import { useEffect, useRef } from "react";
import _ from "lodash";

/**
 * Calls callback function on set interval
 * @param callback function to call
 * @param delayMs set interval in ms to call function, set to null to stop calling function
 * @param immediate if true immediately calls function otherwise waits for delayMs before first function call
 */
export default function useInterval(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: () => any,
  delayMs: number | null,
  immediate = true
) {
  const callbackRef = useRef(_.noop);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      callbackRef.current();
    }
    if (delayMs && immediate) {
      callback();
    }
    if (delayMs !== null) {
      const id = setInterval(tick, delayMs);
      return () => clearInterval(id);
    }
  }, [delayMs]);
}
