/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useEffect,
  useRef,
  useReducer,
  useContext,
  createContext
} from "react";
import _ from "lodash";
import createReducer, { State } from "../reducer";
import { CommonAction } from "../reducer/actions";
import { handlers } from "../reducer/handlers";
import { AppContext, AppContextValue } from "../context";

export interface TempWorkflowParams {
  workflowId?: string;
  workflow?: any;
}

type UpdateFunc = (path: string, value: any) => any;
type DeleteFunc = (path: string) => void;
type ResetWorkflowFunc = (state: any) => void;
type HasChangesFunc = () => boolean;

export interface TempWorkflowProvider {
  state: WorkflowState;
  updateProp: UpdateFunc;
  deleteProp: DeleteFunc;
  hasChanges: HasChangesFunc;
  resetWorkflow: ResetWorkflowFunc;
  validateTempWorkflow: () => Promise<void>;
}

interface WorkflowState {
  originalWorkflow: any;
  tempWorkflow: any;
  isFetchingWorkflow: boolean;
  validationError: any;
  isValidating: boolean;
}

export type Reducer = (state: WorkflowState, action: CommonAction) => State;

export const TempWorkflowContext = createContext<TempWorkflowProvider>(null);

/**
 * Hook to manage the temp workflow state. Options params are workflowId and workflow object.
 *
 * If the workflow object is passed as params, then it will be used
 * If the workflowId and workflow object are passed as params, workflow object will be used
 * If only the workflowId is passed as params, then we fetch the workflow by id
 */
export default function useTempWorkflow(
  params: TempWorkflowParams
): TempWorkflowProvider {
  const { maestroClient } = useContext(AppContext) as AppContextValue;

  const initialState = {
    originalWorkflow: params.workflow,
    tempWorkflow: params.workflow,
    isFetchingWorkflow: false,
    validationError: null,
    isValidating: false
  };

  const reducer = createReducer<WorkflowState, CommonAction>(
    initialState,
    handlers
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Need to capture latest state in a ref to prevent reading stale state due to closures
   */
  const _state = useRef(null);
  _state.current = state;

  /**
   * fetch the workflow from the reporting api if not passed as state in the location object
   */
  function _setIsFetchingWorkflow(value: boolean) {
    dispatch({
      type: "updateProp",
      path: "isFetchingWorkflow",
      value
    });
  }
  function _setOriginalWorkflow(value: any) {
    dispatch({
      type: "updateProp",
      path: "originalWorkflow",
      value
    });
  }
  function _setTempWorkflow(value: any) {
    dispatch({
      type: "updateProp",
      path: "tempWorkflow",
      value
    });
  }
  function _setIsValidating(value: boolean) {
    dispatch({
      type: "updateProp",
      path: "isValidating",
      value
    });
  }
  function _setError(value: any) {
    dispatch({
      type: "updateProp",
      path: "validationError",
      value
    });
  }

  async function validateTempWorkflow() {
    try {
      _setIsValidating(true);
      await maestroClient.validateWorkflow(
        _.pick(_state.current.tempWorkflow, [
          "name",
          "contact",
          "start",
          "callbackUrl",
          "timeout",
          "stepTimeout",
          "onError",
          "steps"
        ])
      );
      _setError(null);
    } catch (error) {
      let message = null;
      if (error.response) {
        message = error.response.data ? error.response.data : error.response;
      }
      _setError(message);
    } finally {
      _setIsValidating(false);
    }
  }

  /**
   * Fetch workflow by workflowId if workflow was not passed as params
   */
  useEffect(() => {
    if (params.workflow) {
      _setOriginalWorkflow(params.workflow);
      _setTempWorkflow(params.workflow);
    } else if (params.workflowId) {
      _setIsFetchingWorkflow(true);
      maestroClient
        .getWorkflow(params.workflowId)
        .then(workflowData => {
          _setOriginalWorkflow(workflowData);
          _setTempWorkflow(workflowData);
        })
        .catch(console.error)
        .finally(() => {
          _setIsFetchingWorkflow(false);
        });
    }
  }, [params.workflow, params.workflowId]);

  /**
   * Helper function to dispatch updateProp actions
   * @param path path on state object to modify
   * @param value value to set on state object
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateProp(path: string, value: any) {
    dispatch({
      type: "updateProp",
      path: `tempWorkflow.${path}`,
      value
    });
  }

  function deleteProp(path: string) {
    dispatch({
      type: "deleteProp",
      path: `tempWorkflow.${path}`
    });
  }

  function resetWorkflow(state: any) {
    dispatch({
      type: "reset",
      value: {
        originalWorkflow: state,
        tempWorkflow: state
      }
    });
  }

  /**
   * Checks if there are changes between the latest and draft version
   */
  function hasChanges() {
    const originalWorkflow = _state.current.originalWorkflow;
    const tempWorkflow = _state.current.tempWorkflow;
    return !_.isEqual(originalWorkflow, tempWorkflow);
  }

  return {
    state,
    updateProp,
    deleteProp,
    hasChanges,
    resetWorkflow,
    validateTempWorkflow
  };
}
