import React from "react";
import WorkflowSummary from "../../common/components/workflowSummary";

export interface WorkflowSummaryListProps {
  currentPageWorkflows: any[];
}

/**
 * WorkflowSummaryList Component, renders the workflow summary list of each page
 */
export default function WorkflowSummaryList(props: WorkflowSummaryListProps) {
  const workflows = props.currentPageWorkflows;
  const items = workflows.map((workflow, index) => {
    return <WorkflowSummary workflow={workflow} key={index} />;
  });

  return (
    <div>
      <div className="card-deck">{items}</div>
    </div>
  );
}
