import React from "react";
import PageHeader from "./pageHeader";

export interface ShellProps {
  children: JSX.Element;
}
/**
 * Shell component, used to render different pages
 * @param props
 */
export default function Shell(props: ShellProps) {
  return (
    <div>
      <PageHeader />
      <div className="container-fluid">
        <div className="content-wrapper" style={{ padding: "1.875em" }}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
