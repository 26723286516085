import React from "react";
import WorkflowHomePage from "../pages/workflowHomePage";
import WorkflowOverview from "../pages/workflowOverview";
import ExecutionDetail from "../pages/executionDetail";
import EventDetail from "../pages/eventDetail";
import StepDetail from "../pages/stepDetail";
import ManageWorkflow from "../pages/manageWorkflow";
import ViewWorkflowData from "../pages/viewWorkflowData";
import { ManageWorkflowPageProvider } from "../pages/manageWorkflow/context";
import WorkflowBuilder from "../pages/workflowBuilder";
import AnonymousWorkflow from "../pages/anonymousWorkflow";
import FourOhFourNotFound from "../pages/fourOhFourNotFoundPage";
const Routes = [
  {
    path: "/",
    exact: true,
    children: <WorkflowHomePage />
  },
  {
    path: "/workflows",
    exact: true,
    children: <WorkflowHomePage />
  },
  {
    path: "/workflows/anonymous",
    exact: true,
    children: (
      <div className="container">
        <AnonymousWorkflow />
      </div>
    )
  },
  {
    path: "/workflows/build",
    exact: true,
    children: <WorkflowBuilder />
  },
  {
    path: "/workflows/:workflowId",
    exact: true,
    children: <WorkflowOverview />
  },
  {
    path: "/workflows/:workflowId/edit",
    exact: true,
    children: (
      <ManageWorkflowPageProvider>
        <ManageWorkflow />
      </ManageWorkflowPageProvider>
    )
  },
  {
    path: "/workflows/:workflowId/executions/:executionId",
    exact: true,
    children: (
      <div className="container">
        <ExecutionDetail />
      </div>
    )
  },
  {
    path: "/executions/:executionId",
    exact: true,
    children: (
      <div className="container">
        <ExecutionDetail />
      </div>
    )
  },
  {
    path: "/workflows/:workflowId/viewWorkflowData",
    exact: true,
    children: (
      <div className="container">
        <ViewWorkflowData />
      </div>
    )
  },
  {
    path: "/workflows/:workflowId/executions/:executionId/steps/:stepId",
    exact: true,
    children: (
      <div className="container">
        <StepDetail />
      </div>
    )
  },
  {
    path: "/executions/:executionId/steps/:stepId",
    exact: true,
    children: (
      <div className="container">
        <StepDetail />
      </div>
    )
  },
  {
    path: "/workflows/:workflowId/executions/:executionId/events/:eventId",
    exact: true,
    children: (
      <div className="container">
        <EventDetail />
      </div>
    )
  },
  {
    path: "/executions/:executionId/events/:eventId",
    exact: true,
    children: (
      <div className="container">
        <EventDetail />
      </div>
    )
  },
  {
    path:
      "/workflows/:workflowId/executions/:executionId/steps/:stepId/events/:eventId",
    exact: true,
    children: (
      <div className="container">
        <EventDetail />
      </div>
    )
  },
  {
    path: "/executions/:executionId/steps/:stepId/events/:eventId",
    exact: true,
    children: (
      <div className="container">
        <EventDetail />
      </div>
    )
  }
];

export default Routes;
