import { useReducer } from "react";
import createReducer from "../reducer";
import { handlers } from "../reducer/handlers";
import { CommonAction } from "../reducer/actions";

export enum TimeFilterMode {
  createdTime = "createdMs",
  modifiedTime = "modifiedMs"
}

export interface TimeFilterState {
  startDate: Date;
  endDate: Date;
  timeFilterMode: TimeFilterMode;
}

interface UseTimeFilterParams {
  initialStartDate?: Date;
  initialEndDate?: Date;
  initialTimeFilterMode?: TimeFilterMode;
}
export type SetDateFunc = (date: Date) => void;

type SetTimeFilterFunc = (timeFilterMode: TimeFilterMode) => void;

type useTimeFilterTuple = [
  TimeFilterState,
  SetDateFunc,
  SetDateFunc,
  SetTimeFilterFunc,
  () => void
];

const START_DATE_PATH = "startDate";
const END_DATE_PATH = "endDate";

/**
 * Custom hook for managing timeFilter state
 * @returns tuple containing state, setStartDate, setEndDate, setTimeFilterMode, resetRange
 */
export default function useTimeFilter(
  params?: UseTimeFilterParams
): useTimeFilterTuple {
  const initialState: TimeFilterState = {
    startDate: params?.initialStartDate || null,
    endDate: params?.initialEndDate || null,
    timeFilterMode: params?.initialTimeFilterMode || TimeFilterMode.createdTime
  };
  const reducer = createReducer<TimeFilterState, CommonAction>(
    initialState,
    handlers
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Helper function to dispatch updateProp actions
   * @param path path on state object to modify
   * @param value value to set on state object
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function updateProp(path: string, value: any) {
    dispatch({
      type: "updateProp",
      path,
      value
    });
  }

  /**
   * set start date state
   * @param date
   */
  function setStartDate(date: Date) {
    updateProp(START_DATE_PATH, date);
  }

  /**
   * set end date state
   * @param date
   */
  function setEndDate(date: Date) {
    updateProp(END_DATE_PATH, date);
  }

  /**
   * set end date state
   * @param date
   */
  function setTimeFilterMode(mode: TimeFilterMode) {
    updateProp("timeFilterMode", mode);
  }

  /**
   * reset date range
   */
  function resetRange() {
    updateProp(START_DATE_PATH, null);
    updateProp(END_DATE_PATH, null);
  }
  return [state, setStartDate, setEndDate, setTimeFilterMode, resetRange];
}
