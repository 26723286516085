/* eslint-disable @typescript-eslint/no-explicit-any */
import { Draft } from "immer";
import _ from "lodash";
import {
  UpdatePropAction,
  DeletePropAction,
  ResetAction,
  CommonAction
} from "../actions";
import { State } from "..";

export interface Handlers<S, A> {
  [handler: string]: (draft: Draft<S>, action: A, state: S) => void | any;
}

/**
 * Handlers for common actions. Currently:
 * UpdatePropAction
 * DeletePropAction
 * ResetAction
 */
export const handlers: Handlers<State, CommonAction> = {
  /**
   * Updates a property in the state object
   */
  updateProp(draft, action: UpdatePropAction) {
    _.set(draft, action.path, action.value);
  },
  /**
   * Removes a property in the state object
   */
  deleteProp(draft, action: DeletePropAction) {
    _.unset(draft, action.path);
  },
  /**
   * Resets the state object back to some value. This will only update
   * properties that exist in the original state object.
   */
  reset(draft, action: ResetAction) {
    const fields = Object.keys(draft);
    _.assign(draft, _.pick(action.value, fields));
  }
};
