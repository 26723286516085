import React, { Dispatch, useReducer, useState } from "react";
import PropTypes from "prop-types";
import {
  DiagramModel,
  NodeModelListener,
  NodeModel,
  LinkModel
} from "@projectstorm/react-diagrams";
import { distributeModel } from "../../util/dagre-util";
import createReducer from "../../../../common/reducer";
import { Handlers } from "../../../../common/reducer/handlers";
import { Draft } from "immer";

export const GraphContext = React.createContext(null);

export interface Graph {
  model: DiagramModel;
  node: [NodeModel, Dispatch<NodeModel>];
  dispatch: (action: GraphAction) => void;
  refresh: number;
}

interface GraphState {
  model: DiagramModel;
  refresh;
}

export type GraphAction =
  | AddNodesAction
  | AddLinksAction
  | DistributeAction
  | AddNodesAction
  | AddNodeListenerAction
  | RefreshAction
  | ResetAction;

type AddNodesAction = {
  type: "addNodes";
  payload: NodeModel[];
  listener?: NodeModelListener;
};
type AddLinksAction = {
  type: "addLinks";
  payload: LinkModel[];
};
type DistributeAction = { type: "distribute" };
type AddNodeListenerAction = {
  type: "addNodeListener";
  payload: NodeModel[];
  listener: NodeModelListener;
};
type RefreshAction = { type: "refresh" };
type ResetAction = { type: "reset"; listener: NodeModelListener };

const initialState: GraphState = {
  model: new DiagramModel(),
  refresh: 0
};

const handlers: Handlers<GraphState, GraphAction> = {
  addNodes: (draft: Draft<GraphState>, action: AddNodesAction) => {
    if (action.listener) {
      action.payload.forEach(node => {
        node.registerListener(action.listener);
      });
      draft.model.addAll(...action.payload);
    }
  },
  addLinks: (draft: Draft<GraphState>, action: AddLinksAction) => {
    draft.model.addAll(...action.payload);
  },
  distribute: (
    draft: Draft<GraphState>,
    action: DistributeAction,
    state: GraphState
  ) => {
    distributeModel(state.model);
    draft.model = state.model;
  },
  addNodeListener: (
    draft: Draft<GraphState>,
    action: AddNodeListenerAction
  ) => {
    draft.model.getNodes().forEach(item => {
      item.registerListener(action.listener);
    });
  },
  refresh: (draft: Draft<GraphState>) => {
    draft.refresh += 1;
  },
  reset: (draft: Draft<GraphState>) => {
    draft.model = new DiagramModel();
  }
};

/**
 * Context provider for graph node
 */
export function StepsGraphProvider({ children }) {
  const [selectedNode, setSelectedNode] = useState(null);
  const reducer = createReducer<GraphState, GraphAction>(
    initialState,
    handlers
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: Graph = {
    model: state.model,
    node: [selectedNode, setSelectedNode],
    dispatch,
    refresh: state.refresh
  };

  return (
    <GraphContext.Provider value={value}>{children}</GraphContext.Provider>
  );
}

StepsGraphProvider.propTypes = {
  children: PropTypes.node.isRequired
};
