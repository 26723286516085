/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import _ from "lodash";

export interface StorageEvent {
  action: string;
  item?: StorageItem;
}

export interface StorageItem {
  key: string;
  value?: any;
}

export interface Storage {
  clear: () => void;
  getItem: (key: string) => Promise<StorageItem>;
  setItem: (key: string, value: any) => Promise<StorageItem>;
  removeItem: (key: string) => Promise<void>;
  length: () => Promise<number>;
  keys: () => Promise<Array<string>>;
  getAllItems: () => Promise<Array<StorageItem>>;
  pushItem: (key: string, value: any) => Promise<StorageItem>;
  popItem: (key: string, value: any) => Promise<StorageItem>;
  getKeysByValue: (value: any, path?: string) => Promise<Array<string>>;
}

/**
 * Provides access to local storage and returns latest state changes to local storage
 * @param storageInstance storage instance to use, use separate instances to store different data
 * @returns [storageState, storageHandler]
 */
export default function useLocalStorage(
  storageInstance: LocalForage
): [StorageEvent, Storage] {
  const [lastStorageEvent, setLastStorageEvent] = useState<StorageEvent>(null);

  /**
   * Clears all data in the storage
   */
  async function clear() {
    await storageInstance.clear();
    setLastStorageEvent({
      action: "clear"
    });
  }
  /**
   * Gets an entry from storage instance
   * @param key
   * @returns null value if entry does not exist
   */
  async function getItem(key: string) {
    const value = await storageInstance.getItem(key);
    return {
      key,
      value
    };
  }

  /**
   * Creates a new entry in the storage instance
   * @param key
   * @param value
   */
  async function setItem(key: string, value: any) {
    const item = await storageInstance.setItem(key, value);
    setLastStorageEvent({
      action: "setItem",
      item: {
        key,
        value: item
      }
    });
    return {
      key,
      value
    };
  }
  /**
   * Removes entry from storage instance
   * @param key
   */
  async function removeItem(key: string) {
    await storageInstance.removeItem(key);
    setLastStorageEvent({
      action: "removeItem",
      item: {
        key
      }
    });
  }
  /**
   * Returns number of entries in the storage instance
   */
  async function length() {
    return await storageInstance.length();
  }

  /**
   * Returns all keys in the storage instance
   */
  async function keys() {
    return await storageInstance.keys();
  }

  /**
   * Returns all entries in the storage instance
   */
  async function getAllItems(): Promise<Array<StorageItem>> {
    const items: Array<StorageItem> = [];
    const keys = await storageInstance.keys();
    for (const key of keys) {
      const value = await getItem(key);
      items.push(value);
    }
    return items;
  }

  /**
   * Appends an item to an entry of type Array
   * @param key
   * @param value item to append
   * @returns modifed entry
   */
  async function pushItem(key: string, value: any): Promise<StorageItem> {
    const item = await getItem(key);
    const entryValue = item.value as Array<any>;
    if (_.isNil(entryValue)) {
      throw new Error(`No entry found for ${key}, cannot append item`);
    }
    if (!_.isArray(entryValue)) {
      throw new Error(`Entry ${key}is not of type array, cannot append item`);
    }
    entryValue.push(value);
    return await setItem(key, entryValue);
  }

  /**
   * Removes an item from an entry of type Array
   * @param key
   * @param value item to append
   * @returns modifed entry
   */
  async function popItem(key: string, value: any): Promise<StorageItem> {
    // Convert to isoString since localforage stores Dates in this format
    if (value instanceof Date) {
      value = value.toISOString();
    }
    const item = await getItem(key);
    const entryValue = item.value as Array<any>;
    if (_.isNil(entryValue)) {
      throw new Error(`No entry found for ${key}, cannot pop item`);
    }
    if (!_.isArray(entryValue)) {
      throw new Error(`Entry ${key}is not of type array, cannot pop item`);
    }
    _.remove(entryValue, listItem => _.isEqual(listItem, value));
    return await setItem(key, entryValue);
  }

  /**
   * Returns the keys that a value is an entry of. If the type of the entry value is an array it will check if the value is in the array
   * otherwise it compares the value of the entry
   * @param value value to find associated keys for
   * @param path if provided will compare value against value at path
   */
  async function getKeysByValue(
    value: any,
    path?: string
  ): Promise<Array<string>> {
    // Convert to isoString since localforage stores Dates in this format
    if (value instanceof Date) {
      value = value.toISOString();
    }
    const keys = [];
    const entries = await getAllItems();
    for (const entry of entries) {
      if (_.isArray(entry.value)) {
        {
          const entryValues = path
            ? entry.value.map(x => _.get(x, path))
            : entry.value;
          if (_.includes(entryValues, value)) {
            keys.push(entry.key);
          }
        }
      } else if (
        _.isEqual(value, path ? _.get(entry.value, path) : entry.value)
      ) {
        keys.push(entry.key);
      }
    }
    return keys;
  }

  const storageHandler: Storage = {
    clear,
    setItem,
    getItem,
    getAllItems,
    keys,
    length,
    removeItem,
    pushItem,
    popItem,
    getKeysByValue
  };

  return [lastStorageEvent, storageHandler];
}
