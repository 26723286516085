import React, { Dispatch } from "react";
import { Button } from "@cimpress/react-components";
import { Views, MODAL_NAME } from "../../";
import kitchenSinkWorkflow from "../../../../../../pub/kitchensinkworkflow.json";
import assetCreationWorkflow from "../../../../../../pub/assetcreation.json";
import PropTypes from "prop-types";
import { ModalStore } from "../../../../../../common/hooks/useModals";
import { useHistory } from "react-router";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("@cimpress/react-components/lib/colors");

export const DEFAULT_WORKFLOW = {
  workflow: {
    name: "changeme",
    start: "pass",
    contact: "changeme@cimpress.com",
    callbackUrl: "",
    onError: "fail",
    timeout: "PT1M",
    steps: {
      pass: {
        type: "Success"
      },
      fail: {
        type: "Fail"
      }
    }
  }
};

/**
 * Body component in the workflow builder modal
 * displayed when the user clicks the 'create new workflow' button
 */
export function CreateNewWorkflowView({ setSelectedWorkflow }) {
  return (
    <div className="workflow-builder-wizard-modal-create-new-workflow-view-container">
      <Button onClick={() => setSelectedWorkflow(DEFAULT_WORKFLOW)}>
        Blank Workflow
      </Button>
      <Button onClick={() => setSelectedWorkflow(kitchenSinkWorkflow)}>
        Kitchen Sink Workflow
      </Button>
      <Button onClick={() => setSelectedWorkflow(assetCreationWorkflow)}>
        Asset Creation Workflow
      </Button>
    </div>
  );
}

CreateNewWorkflowView.propTypes = {
  setSelectedWorkflow: PropTypes.func
};

interface CreateNewWorkflowViewFooterProps {
  store: ModalStore;
  setView: Dispatch<string>;
  selectedWorkflow: { workflow: any }; // eslint-disable-line @typescript-eslint/no-explicit-any
}

/**
 * Footer component in the workflow builder modal
 * displayed when the user clicks the 'create new workflow' button
 */
export function CreateNewWorkflowViewFooter(
  props: CreateNewWorkflowViewFooterProps
) {
  const { store, setView, selectedWorkflow } = props;
  const history = useHistory();
  function handleCreateWorkflow() {
    store.toggleVisibility(MODAL_NAME);
    setView(Views.HOME_VIEW);
    history.push({
      pathname: "/workflows/build",
      state: {
        WorkFlowState: selectedWorkflow.workflow
      }
    });
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        borderTop: `1px solid ${colors.shale}`,
        paddingTop: "1em"
      }}
    >
      <Button onClick={() => setView(Views.HOME_VIEW)}>Cancel</Button>
      <Button type="primary" onClick={handleCreateWorkflow}>
        Create
      </Button>
    </div>
  );
}

CreateNewWorkflowViewFooter.propTypes = {
  store: PropTypes.object,
  setView: PropTypes.func,
  selectedWorkflow: PropTypes.object
};
