import React, { useState, useContext, useEffect } from "react";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import {
  NavTab,
  NavTabItem,
  FlexBox,
  Table,
  Dropdown,
  Alert
} from "@cimpress/react-components";
import IconCog from "@cimpress-technology/react-streamline-icons/lib/IconCog";
import Metadata from "../../common/components/metadata";
import TabCollection from "../../common/components/tabCollection";
import ReactJson from "react-json-view";
import _ from "lodash";
import styles from "./index.module.css";
/* eslint-disable */
const colors = require("@cimpress/react-components/lib/colors");
import { AppContext, AppContextValue } from "../../common/context";
import { Link, useLocation, useParams } from "react-router-dom";
import Breadcrumb, {
  Props as BreadcrumbProps
} from "../../common/components/breadcrumb";
import useInterval from "../../common/hooks/useInterval";
import config from "../../config";
import {
  HeaderActionResult,
  getHomeBreadcrumb,
  getWorkflowIdBreadcrumb,
  getExecutionIdBreadcrumb,
  getStepIdBreadcrumb
} from "../../common/utility";
import TimeStamp from "../../common/components/timeStamp";
import { FORBIDDEN_CODE, FORBIDDEN_MESSAGE } from "../constants";
import FourOhFourNotFound from "../fourOhFourNotFoundPage";
/**
 * Custom cell render component for eventId column for react table
 * @param param0 cell props
 */
function EventIdCell({ cellProps, breadcrumbItems }) {
  let original = cellProps.original;
  const location = useLocation();
  return (
    <Link
      to={{
        pathname: `${location.pathname}/events/${original.eventId}`,
        state: { breadcrumbItems }
      }}
    >
      {original.eventId}
    </Link>
  );
}

interface HeaderProps {
  label: string;
  onTerminate: Function;
  onRetry: Function;
  disableTerminate: boolean;
  disableRetry: boolean;
}

/**
 * page header
 * @param label Label
 */
function Header(props: HeaderProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        borderBottom: `1px solid ${colors.shale}`,
        paddingBottom: "0.3125em",
        marginBottom: "1em",
        fontSize: "1.625em"
      }}
    >
      {props.label}
      <Dropdown
        style={{ padding: "0px" }}
        title={<IconCog style={{ height: "1.5em" }} />}
        type="simple"
      >
        <button
          disabled={props.disableRetry}
          onClick={() => {
            props.onRetry();
          }}
        >
          Retry
        </button>
        <button
          disabled={props.disableTerminate}
          onClick={() => {
            props.onTerminate();
          }}
        >
          Terminate
        </button>
      </Dropdown>
    </div>
  );
}

/**
 * vertical divider element
 */
function VerticalLine() {
  return (
    <div
      style={{
        borderLeft: `1px solid ${colors.shale}`,
        borderRight: `1px solid ${colors.alloy}`
      }}
    ></div>
  );
}

/**
 * step detail page
 */
function StepDetail() {
  const { maestroClient, sandboxClient } = useContext(
    AppContext
  ) as AppContextValue;
  const [step, setStep] = useState(null);
  const [events, setEvents] = useState([]);
  const [isFetchingEvents, setIsFetchingEvents] = useState(true);
  const [isFetchingStep, setIsFetchingStep] = useState(true);
  const [pausePolling, setPausePolling] = useState(false);
  const [headerActionResult, setHeaderActionResult]: [
    HeaderActionResult,
    Function
  ] = useState(null);
  const [isForbidden, setIsForbidden] = useState(false);
  const { workflowId, executionId, stepId } = useParams();
  const breadcrumbs: BreadcrumbProps = {
    items: []
  };

  const client =
    workflowId && workflowId != "anonymous" ? maestroClient : sandboxClient;

  let workflowFromState, breadcrumbItems, executionFromState;

  // check if the parameters are passed as state from the previous page
  const location = useLocation();
  if (location.state) {
    breadcrumbItems = _.get(location.state, "breadcrumbItems");
    executionFromState = _.get(location.state, "execution", null);
    workflowFromState = _.get(
      location.state,
      "workflow",
      executionFromState?.workflow
    );
  }
  const [workflow, setWorkflow] = useState(workflowFromState);
  const [execution, setExecution] = useState(executionFromState);

  /**
   * fetch the workflow from the reporting api if not passed as state in the location object
   */
  useEffect(() => {
    if (_.isNil(workflow)) {
      maestroClient
        .getWorkflow(workflowId)
        .then(workflowData => {
          setWorkflow(workflowData);
        })
        .catch(e => console.error(e));
    }
    if (_.isNil(execution)) {
      client
        .getExecution(executionId)
        .then(executionDate => {
          setExecution(executionDate);
        })
        .catch(e => console.error(e));
    }
  }, []);

  /**
   * fetch step
   */
  function fetchStep() {
    client
      .getActivityByExecutionId(executionId, stepId)
      .then(stepData => {
        setStep(stepData);
      })
      .catch(e => {
        console.error(e);
        if (e.response.status == FORBIDDEN_CODE) {
          setIsForbidden(true);
        }
      })
      .finally(() => {
        setIsFetchingStep(false);
      });
  }
  useInterval(fetchStep, pausePolling ? null : config.api.pollIntervalMs);
  /**
   * fetch events for step
   */
  function fetchEvents() {
    client
      .getEventsByStepId(executionId, stepId)
      .then(eventsData => {
        setEvents(eventsData);
      })
      .catch(console.error)
      .finally(() => {
        setIsFetchingEvents(false);
      });
  }

  /**
   * Fetch events on interval
   */
  useInterval(fetchEvents, pausePolling ? null : config.api.pollIntervalMs);

  useEffect(() => {
    if (
      step?.status == "Terminated" ||
      step?.status == "Completed" ||
      execution?.status == "Terminated" ||
      execution?.status == "Completed"
    ) {
      setPausePolling(true);
    } else {
      setPausePolling(false);
    }
  }, [step?.status]);
  /**
   * Handler when terminate button is pressed
   */
  async function onClickTerminate() {
    try {
      await client.terminateActivity(executionId, stepId);
      setPausePolling(false);
      setHeaderActionResult(
        new HeaderActionResult(true, "Terminate Step request submitted")
      );
      fetchStep();
      fetchEvents();
    } catch (e) {
      setHeaderActionResult(
        new HeaderActionResult(
          false,
          JSON.stringify({
            response: e.response?.data,
            status: e.response?.status
          }),
          "Terminate Step request Failed"
        )
      );
      console.error(e.response?.data);
    }
  }
  /**
   * Handler when retry button is pressed
   */
  async function onClickRetry() {
    try {
      await client.retryActivity(executionId, stepId);
      setPausePolling(false);
      setHeaderActionResult(
        new HeaderActionResult(true, "Retry Step request submitted")
      );
      fetchStep();
      fetchEvents();
    } catch (e) {
      setHeaderActionResult(
        new HeaderActionResult(
          false,
          JSON.stringify({
            response: e.response?.data,
            status: e.response?.status
          }),
          "Retry Step request Failed"
        )
      );
      console.error(e.response?.data);
    }
  }
  // populate breadcrumb item collection
  if (breadcrumbItems) {
    breadcrumbs.items = _.concat(breadcrumbItems, [
      getStepIdBreadcrumb(workflowId, executionId, stepId)
    ]);
  } else {
    // default breadcrumb item collection
    breadcrumbs.items = [
      getHomeBreadcrumb(),
      getWorkflowIdBreadcrumb(workflowId, _.get(workflow, "name", "?")),
      getExecutionIdBreadcrumb(workflowId, executionId),
      getStepIdBreadcrumb(workflowId, executionId, stepId)
    ];
  }

  const eventTableColumns = [
    {
      Header: "Event ID",
      accessor: "eventId",
      Cell: props => (
        <EventIdCell cellProps={props} breadcrumbItems={breadcrumbs.items} />
      )
    },
    { Header: "Event Type", accessor: "eventType" },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: props => <TimeStamp date={props.original.createdAt} />,
      filterable: false
    },
    {
      Header: "Process Date",
      accessor: "processOn",
      Cell: props => <TimeStamp date={props.original.processOn} />,
      filterable: false
    }
  ];

  const metadata = {
    Status: _.get(step, "status", null),
    "Step Name": _.get(step, "name", null),
    Type: _.get(step, "typeId", null),
    "Created At": _.get(step, "createdAt", null),
    "Modified At": _.get(step, "modifiedAt", null),
    Attempts: _.get(step, "attempts", null)
  };

  const stepInput = _.get(step, "input", {});
  const requestData = _.get(step, "params", {});
  const transformedOutput = _.get(step, "output", {});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabCollectionHeaderData = [
    {
      header: "Step Input",
      value: stepInput
    },
    {
      header: "Request Details",
      value: requestData
    },
    {
      header: "Transformed Output",
      value: transformedOutput
    }
  ];

  const tabs = [
    {
      id: 0,
      name: "Events",
      block: isFetchingEvents ? (
        <Spinner size="large" />
      ) : (
        <Table
          columns={eventTableColumns}
          data={events}
          filterable={true}
          loading={false}
        />
      )
    },
    {
      id: 1,
      name: "Raw Json",
      block: (
        <div style={{ overflow: "auto", height: "50vh" }}>
          <ReactJson
            src={step}
            name={false}
            displayDataTypes={false}
            collapsed={1}
          />
        </div>
      )
    }
  ];
  if (isForbidden) {
    return <FourOhFourNotFound message={FORBIDDEN_MESSAGE} />;
  } else {
    return (
      /** vertical layout */
      <FlexBox isVertical>
        {/** Breadcrumb */}
        <Breadcrumb {...breadcrumbs} />
        {/** header */}
        <Header
          label="Step"
          onTerminate={onClickTerminate}
          onRetry={onClickRetry}
          disableTerminate={
            step?.status == "Completed" || step?.status == "Terminated"
          }
          disableRetry={false}
        />
        {/** body */}
        <Alert
          title={headerActionResult?.title}
          type={headerActionResult?.succeeded ? "info" : "danger"}
          message={headerActionResult?.message || _.stubString()}
          dismissible={true}
          dismissed={headerActionResult === null}
          onDismiss={() => {
            setHeaderActionResult(null);
          }}
        />
        <FlexBox>
          <div className={styles.metadataContainer}>
            {isFetchingStep ? (
              <Spinner size="large" duration={3} />
            ) : (
              <Metadata
                title="Detail"
                data={metadata}
                headerStyle={{
                  fontSize: "1.375em",
                  marginTop: "0.3125em"
                }}
              />
            )}
          </div>
          <VerticalLine />
          <div className={styles.inputOutputContainer}>
            <TabCollection data={tabCollectionHeaderData} />
          </div>
        </FlexBox>
        {/** tables are inside tabs */}
        <div style={{ marginTop: "2em" }}>
          <NavTab>
            {tabs.map(tab => {
              return (
                <NavTabItem key={tab.id} active={selectedTabIndex == tab.id}>
                  <button
                    style={{ fontSize: "large" }}
                    onClick={() => setSelectedTabIndex(tab.id)}
                  >
                    {tab.name}
                  </button>
                </NavTabItem>
              );
            })}
          </NavTab>
          <div>{tabs[selectedTabIndex].block}</div>
        </div>
      </FlexBox>
    );
  }
}

export default StepDetail;
