import React from "react";
import { LineWiseDataProps } from "./index";

/**
 * Creates a span element for each entry with the background coloe
 * @param props - has a object having details like linecolor, line data, etc
 */
export default function JsonContent(props: { entry: LineWiseDataProps }) {
  const entry = props.entry;
  return (
    <span style={{ backgroundColor: entry.lineColor }}>
      {entry.lineData}
      <br></br>
    </span>
  );
}
