import React, { useState, useEffect, useContext, useCallback } from "react";
import { Pagination, FlexBox, TextField } from "@cimpress/react-components";
import WorkflowSummaryList from "./workflowSummaryList";
import IconSearch from "@cimpress-technology/react-streamline-icons/lib/IconSearch";
import { AppContext, AppContextValue } from "../../common/context";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import _ from "lodash";

/**
 * Component for the search icon
 */
function SearchIcon() {
  return (
    <div
      style={{
        backgroundColor: "lightgrey",
        minWidth: "40px",
        maxWidth: "45px",
        minHeight: "45px",
        maxHeight: "48px"
      }}
    >
      <IconSearch style={{ height: "50%", margin: "20%" }} />
    </div>
  );
}

/**
 * WorkflowHomePage Component, this function renders the workflows homepage.
 */
export default function WorkflowHomePage() {
  const [isFetching, setIsFetching] = useState(true);
  const [workflows, setWorkflows] = useState([]);
  const [filteredWorkflows, setFilteredWorkflows] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [workflowsPerPage] = useState(5); //setWorkflowsPerPage can be useful when customizing it
  const [pageCount, setPageCount] = useState(0);
  const [currentPageWorkflows, setCurrentPageWorkflows] = useState([]);
  const [firstPageValue, setFirstPageValue] = useState(0);
  const appCtx: AppContextValue = useContext(AppContext);
  const filterKeys = ["workflowId", "createdBy", "name"];
  const [textFieldValue, setTextFieldValue] = useState("");

  /**
   * Filters the workflows based on entered value
   */
  function filterWorkflows() {
    const filteredWorkflows = workflows.filter(workflow => {
      return _.some(filterKeys, key => {
        return workflow[key]
          .toLowerCase()
          .includes(textFieldValue.toLowerCase());
      });
    });
    return filteredWorkflows;
  }

  //debounces the user input and calls the filterworkflows 750 seconds after the user stops typing
  const debouncedFilterCall = useCallback(
    _.debounce(() => {
      const filteredWorkflows = filterWorkflows();
      setFilteredWorkflows(filteredWorkflows);
    }, 750),
    [textFieldValue, workflows]
  );

  /**
   * this function is used to get the workflow data when mounted
   */
  useEffect(() => {
    appCtx.maestroClient
      .getWorkflows()
      .then(workflows => {
        setWorkflows(workflows);
        setFilteredWorkflows(workflows);
        setPageCount(Math.ceil(workflows.length / workflowsPerPage));
        setPageNum(1);
      })
      .catch(e => console.error(e))
      .finally(() => {
        setIsFetching(false);
      });
  }, []);

  /**
   * when the filtered workflows change, set the page count and initial page number
   */
  useEffect(() => {
    setPageCount(Math.ceil(filteredWorkflows.length / workflowsPerPage));
    setPageNum(1);
  }, [filteredWorkflows]);

  /**
   * this function is used to set the workflows of the current page whenever this is a change in page , page count or when filtered workflows change
   */
  useEffect(() => {
    if (pageNum === 1) {
      setFirstPageValue(0);
    }
    const indexOfLastWorkflowInPage = pageNum * workflowsPerPage;
    const indexOfFirstWorkflowInPage =
      indexOfLastWorkflowInPage - workflowsPerPage;
    const currentPage = filteredWorkflows.slice(
      indexOfFirstWorkflowInPage,
      indexOfLastWorkflowInPage
    );
    setCurrentPageWorkflows(currentPage);
  }, [pageCount, pageNum, filteredWorkflows]);

  //whenever the textfield or debouncedFilterCall changes, the debounce function is called
  useEffect(() => {
    debouncedFilterCall();
    return debouncedFilterCall.cancel;
  }, [textFieldValue, debouncedFilterCall]);

  return (
    <div style={{ marginLeft: "15%" }}>
      <div style={{ paddingLeft: "15px" }}>
        <FlexBox paddingX="s" paddingY="s">
          <TextField
            name="textFieldValue"
            label="Filter by workflow name, workflow id or owner"
            value={textFieldValue}
            required
            autoFocus={true}
            autoComplete="off"
            onChange={e => setTextFieldValue(e.target.value)}
            style={{ width: "40%", margin: "inherit" }}
          />
          <SearchIcon />
        </FlexBox>
      </div>
      {isFetching ? (
        <Spinner size="large" fullPage={true} />
      ) : (
        <div>
          <WorkflowSummaryList currentPageWorkflows={currentPageWorkflows} />
          <div style={{ float: "right", marginRight: "30%" }}>
            <Pagination
              initialPage={0}
              pageCount={pageCount}
              onPageChange={({ selected }) => {
                setPageNum(selected + 1);
                setFirstPageValue(selected);
              }}
              forcePage={firstPageValue}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
            />
          </div>
        </div>
      )}
    </div>
  );
}
