import React, { useRef } from "react";
import { FlexBox } from "@cimpress/react-components";
import sortKeys from "sort-keys";
import diff from "deep-diff";
import jsonMap from "json-source-map";
import styles from "./diffViewer.module.css";
import JsonContent from "./JsonContent";
import _ from "lodash";
import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";

const GREEN = "#9FEDD4";
const RED = "#EDAF9F";
const WHITE = "white";

const InvalidJsonErrorMessage = (
  <div>
    <p style={{ backgroundColor: RED }}>Invalid JSON Object!!</p>
    <br></br>
  </div>
);

const emptyLine: LineWiseDataProps = {
  lineData: "",
  lineColor: WHITE,
  editDetail: "none"
};

export interface LineWiseDataProps {
  lineData: string;
  lineColor: string;
  editDetail: string;
}

/**
 * function to split the json into lines
 * @param json - json to split into lines
 */
function splitIntoLines(json): Array<LineWiseDataProps> {
  const splitJson = JSON.stringify(json, null, 3)
    .split("\n")
    .map(value => {
      return { lineData: value, lineColor: WHITE, editDetail: "none" };
    });
  return splitJson;
}

/**
 * DiffViewer component - it enders a UI component which shows the diff between two jsons
 * @param props - the jsons for which the diff should be viewed
 */
function DiffViewerInternal(props) {
  const leftJsonFromProps = props.leftJson;
  const rightJsonFromProps = props.rightJson;

  let isValidLeftJson = true;
  let isValidRightJson = true;

  if (!_.isObject(leftJsonFromProps)) {
    isValidLeftJson = false;
  }

  if (!_.isObject(rightJsonFromProps)) {
    isValidRightJson = false;
  }

  //this function handles the syncing the leftjson changes with the rightjson changes and to display them
  /**
   * renders the diff view and aligns it
   * @param leftJsonLines - the line-wise data for the left json
   * @param rightJsonLines -the line-wise data for the right json
   */
  function printTheDiffedResult(
    leftJsonLines,
    rightJsonLines
  ): {
    leftJsonDisplayContents: Array<JSX.Element>;
    rightJsonDisplayContents: Array<JSX.Element>;
  } {
    let leftIndex = 0;
    let rightIndex = 0;

    let spanIndex = 0; // index just for JsonContent component key

    const leftJsonContents: Array<JSX.Element> = [];
    const rightJsonContents: Array<JSX.Element> = [];

    while (
      leftIndex < leftJsonLines.length &&
      rightIndex < rightJsonLines.length
    ) {
      if (
        leftJsonLines[leftIndex].editDetail === "none" &&
        rightJsonLines[rightIndex].editDetail === "none"
      ) {
        while (
          leftIndex < leftJsonLines.length &&
          rightIndex <= rightJsonLines.length &&
          leftJsonLines[leftIndex].editDetail === "none" &&
          rightJsonLines[rightIndex].editDetail === "none"
        ) {
          leftJsonContents.push(
            <JsonContent entry={leftJsonLines[leftIndex]} key={spanIndex} />
          );
          leftIndex++;

          rightJsonContents.push(
            <JsonContent entry={rightJsonLines[rightIndex]} key={spanIndex} />
          );
          rightIndex++;

          spanIndex++;
        }
      } else {
        if (rightJsonLines[rightIndex].editDetail === "added") {
          while (rightJsonLines[rightIndex].editDetail === "added") {
            leftJsonContents.push(
              <JsonContent entry={emptyLine} key={spanIndex} />
            );

            rightJsonContents.push(
              <JsonContent entry={rightJsonLines[rightIndex]} key={spanIndex} />
            );
            rightIndex++;

            spanIndex++;
          }
        } else if (leftJsonLines[leftIndex].editDetail === "deleted") {
          while (leftJsonLines[leftIndex].editDetail === "deleted") {
            rightJsonContents.push(
              <JsonContent entry={emptyLine} key={spanIndex} />
            );

            leftJsonContents.push(
              <JsonContent entry={leftJsonLines[leftIndex]} key={spanIndex} />
            );
            leftIndex++;

            spanIndex++;
          }
        } else {
          while (
            leftIndex < leftJsonLines.length &&
            rightIndex <= rightJsonLines.length &&
            leftJsonLines[leftIndex].editDetail === "edited" &&
            rightJsonLines[rightIndex].editDetail === "edited"
          ) {
            leftJsonContents.push(
              <JsonContent entry={leftJsonLines[leftIndex]} key={spanIndex} />
            );
            leftIndex++;

            rightJsonContents.push(
              <JsonContent entry={rightJsonLines[rightIndex]} key={spanIndex} />
            );
            rightIndex++;

            spanIndex++;
          }

          while (
            leftIndex !== leftJsonLines.length &&
            leftJsonLines[leftIndex].editDetail === "edited"
          ) {
            rightJsonContents.push(
              <JsonContent entry={emptyLine} key={spanIndex} />
            );

            leftJsonContents.push(
              <JsonContent entry={leftJsonLines[leftIndex]} key={spanIndex} />
            );
            leftIndex++;

            spanIndex++;
          }
          while (
            rightIndex !== rightJsonLines.length &&
            rightJsonLines[rightIndex].editDetail === "edited"
          ) {
            leftJsonContents.push(
              <JsonContent entry={emptyLine} key={spanIndex} />
            );

            rightJsonContents.push(
              <JsonContent entry={rightJsonLines[rightIndex]} key={spanIndex} />
            );
            rightIndex++;

            spanIndex++;
          }
        }
      }
    }

    return {
      leftJsonDisplayContents: leftJsonContents,
      rightJsonDisplayContents: rightJsonContents
    };
  }

  /**
   * splits the jsons into lines and assigned the type (edited,added,deleted), line color and linedata for each line of the json
   */
  function findDiffsForLeftRightJson(): {
    finalLeftJsonLines: Array<LineWiseDataProps>;
    finalRightJsonLines: Array<LineWiseDataProps>;
  } {
    //Sorting the  object keys here, so that even if the keys are in different positions, the diff would compare the corresponding key
    const leftJson = sortKeys(leftJsonFromProps, { deep: true });
    const rightJson = sortKeys(rightJsonFromProps, { deep: true });

    const leftPointers = jsonMap.stringify(leftJson, null, 1).pointers;
    const rightPointers = jsonMap.stringify(rightJson, null, 1).pointers;

    let leftJsonLines: Array<LineWiseDataProps> = splitIntoLines(leftJson);
    let rightJsonLines: Array<LineWiseDataProps> = splitIntoLines(rightJson);

    const diffs = diff(leftJson, rightJson);

    if (_.isEmpty(diffs))
      return {
        finalLeftJsonLines: leftJsonLines,
        finalRightJsonLines: rightJsonLines
      };

    for (const entry of diffs) {
      let path: string = "/" + entry.path.join("/");

      //if the entry is edited, changes done in both leftJson and rightJson
      if (entry.kind === "E") {
        const leftLineStart = leftPointers[path].value.line;
        const leftLineEnd = leftPointers[path].valueEnd.line;

        const rightLineStart = rightPointers[path].value.line;
        const rightLineEnd = rightPointers[path].valueEnd.line;

        //setting the line color and it is edited/deleted/added for each line
        leftJsonLines = leftJsonLines.map((entry, key) => {
          if (key >= leftLineStart && key <= leftLineEnd) {
            entry.lineColor = RED;
            entry.editDetail = "edited";
          }
          return entry;
        });

        rightJsonLines = rightJsonLines.map((entry, key) => {
          if (key >= rightLineStart && key <= rightLineEnd) {
            entry.lineColor = GREEN;
            entry.editDetail = "edited";
          }
          return entry;
        });
      }
      //if change occurred within an array
      else if (entry.kind === "A") {
        const addedIndex = entry.index;
        path = path + "/" + addedIndex;

        //added within array, changes only in the rightJson
        if (entry.item.kind === "N") {
          const rightLineStart = rightPointers[path].value.line;
          const rightLineEnd = rightPointers[path].valueEnd.line;

          rightJsonLines = rightJsonLines.map((entry, key) => {
            if (key >= rightLineStart && key <= rightLineEnd) {
              entry.lineColor = GREEN;
              entry.editDetail = "added";
            }
            return entry;
          });
        }
        //deleted within array, changes only in the leftJson
        else if (entry.item.kind === "D") {
          const leftLineStart = leftPointers[path].value.line;
          const leftLineEnd = leftPointers[path].valueEnd.line;

          leftJsonLines = leftJsonLines.map((entry, key) => {
            if (key >= leftLineStart && key <= leftLineEnd) {
              entry.lineColor = RED;
              entry.editDetail = "deleted";
            }
            return entry;
          });
        }
      }
      //a property/element was deleted, changes only in the leftJson
      else if (entry.kind === "D") {
        const leftLineStart = leftPointers[path].key.line;
        const leftLineEnd = leftPointers[path].valueEnd.line;

        leftJsonLines = leftJsonLines.map((entry, key) => {
          if (key >= leftLineStart && key <= leftLineEnd) {
            entry.lineColor = RED;
            entry.editDetail = "deleted";
          }
          return entry;
        });
      }
      //a property/element was added, changes only in the rightJson
      else if (entry.kind === "N") {
        const rightLineStart = rightPointers[path].key.line;
        const rightLineEnd = rightPointers[path].valueEnd.line;

        rightJsonLines = rightJsonLines.map((entry, key) => {
          if (key >= rightLineStart && key <= rightLineEnd) {
            entry.lineColor = GREEN;
            entry.editDetail = "added";
          }
          return entry;
        });
      }
    }

    return {
      finalLeftJsonLines: leftJsonLines,
      finalRightJsonLines: rightJsonLines
    };
  }

  const leftJsonRef = useRef(null);
  const rightJsonRef = useRef(null);
  let leftJsonDisplayContents;
  let rightJsonDisplayContents;
  let leftRightJsonDisplayContents;

  if (isValidLeftJson && isValidRightJson) {
    const diffedLeftRightJson = findDiffsForLeftRightJson();
    leftRightJsonDisplayContents = printTheDiffedResult(
      diffedLeftRightJson.finalLeftJsonLines,
      diffedLeftRightJson.finalRightJsonLines
    );
    leftJsonDisplayContents =
      leftRightJsonDisplayContents.leftJsonDisplayContents;
    rightJsonDisplayContents =
      leftRightJsonDisplayContents.rightJsonDisplayContents;
  } else {
    leftJsonDisplayContents = splitIntoLines(leftJsonFromProps).map(
      (entry, key) => {
        return <JsonContent key={key} entry={entry} />;
      }
    );
    rightJsonDisplayContents = splitIntoLines(rightJsonFromProps).map(
      (entry, key) => {
        return <JsonContent key={key} entry={entry} />;
      }
    );
  }

  return (
    <FlexBox center>
      <pre
        ref={leftJsonRef}
        onScroll={e => {
          const scrollTop = e.currentTarget.scrollTop;
          const scrollLeft = e.currentTarget.scrollLeft;
          return rightJsonRef.current.scrollTo({
            top: scrollTop,
            left: scrollLeft
          });
        }}
        className={`${styles["diffViewerStyle"]}`}
      >
        {!isValidLeftJson && InvalidJsonErrorMessage}
        {leftJsonDisplayContents}
      </pre>
      <pre
        ref={rightJsonRef}
        onScroll={e => {
          const scrollTop = e.currentTarget.scrollTop;
          const scrollLeft = e.currentTarget.scrollLeft;
          return leftJsonRef.current.scrollTo({
            top: scrollTop,
            left: scrollLeft
          });
        }}
        className={`${styles["diffViewerStyle"]}`}
      >
        {!isValidRightJson && InvalidJsonErrorMessage}
        {rightJsonDisplayContents}
      </pre>
    </FlexBox>
  );
}
/**
 * DiffViewer component - it enders a UI component which shows the diff between two jsons
 * Wrapped in Error Boundary
 * @param props - the jsons for which the diff should be viewed
 */
export default function DiffViewer(props) {
  return (
    <ErrorBoundary fallback={<h1>Error unable to render diff</h1>}>
      <DiffViewerInternal
        leftJson={props?.leftJson}
        rightJson={props?.rightJson}
      />
    </ErrorBoundary>
  );
}

const DiffViewPropTypes = {
  leftJson: PropTypes.object.isRequired,
  rightJson: PropTypes.object.isRequired
};

DiffViewerInternal.propTypes = DiffViewPropTypes;

DiffViewer.propTypes = DiffViewPropTypes;
