import React, { useState, useEffect, useContext } from "react";
import "./LatestExecutionsTable.css";
import { WorkflowContext } from "../../common/context";
import { Table, Card } from "@cimpress/react-components";
import {
  AppContext,
  AppContextValue,
  TimeZoneContext
} from "../../common/context";
import { NULLVALUE, convertDateRangeToTimeStamp } from "../../common/utility";
import DateRangeFilter from "../../common/components/dateRangeFilter";
import NoDataComponent from "../../common/components/noDataComponent";
import { ExecutionStatuses } from "../../common/utility/index";
import {
  ExecutionIdCell,
  StatusCell
} from "../../common/components/table/cells";
import useTimeFilter from "../../common/hooks/useTimeFilter";
import useStatusFilter from "../../common/hooks/useStatusFilter";
import StatusFilter from "../../common/components/statusFilter";
import TimeStamp from "../../common/components/timeStamp";
/* eslint-disable */

/**
 * Renders LatestExecutionsTable
 * @param props
 */
export default function LatestExecutionsTable() {
  const { maestroClient } = useContext(AppContext) as AppContextValue;
  const { workflowId, breadcrumbItems } = useContext(WorkflowContext);

  const [executions, setExecutions] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [initialPage, setInitialPage] = useState(0);
  const [
    timeFilterState,
    setStartDate,
    setEndDate,
    setTimeFilterMode
  ] = useTimeFilter();
  const { startDate, endDate, timeFilterMode } = timeFilterState;
  const [statusFilterState, setStatus] = useStatusFilter();
  const { status } = statusFilterState;
  const [offset, setOffset] = useState(null);
  const [executionIdFilterText, setExecutionIdFilterText] = useState(null);
  const { timeZone } = useContext(TimeZoneContext);

  /**
   * Fetches executions and sets states
   * @param resetInitialPage if true, initial page will be set after executions are fetched, executions are fetched without offset
   * @param concatExecutions if true fetched executions will be concatenated to current executions otherwise executions will be overwritten
   */
  async function fetchExecutions(
    resetInitialPage: boolean,
    concatExecutions: boolean
  ) {
    const { beginTimeStamp, endTimeStamp } = convertDateRangeToTimeStamp(
      startDate,
      endDate,
      timeZone
    );
    const limit = 1000;
    setIsFetching(true);
    maestroClient
      .getExecutionsByWorkflowId(
        workflowId,
        limit,
        resetInitialPage ? NULLVALUE : offset,
        status ? status : NULLVALUE,
        beginTimeStamp,
        endTimeStamp,
        timeFilterMode
      )
      .then(result => {
        setExecutions(
          concatExecutions
            ? executions.concat(result.executions)
            : result.executions
        );
        setOffset(result.offset);
      })
      .catch(console.error)
      .finally(() => {
        setIsFetching(false);
        if (resetInitialPage) {
          setInitialPage(0);
        }
      });
  }

  /**
   * Get executions when created date filter or status filter changes
   */
  useEffect(() => {
    fetchExecutions(true, false);
  }, [startDate, endDate, status, workflowId, timeFilterMode, timeZone]);

  const tableColumns: Array<any> = [
    {
      Header: "ExecutionId",
      accessor: "executionId",
      filterable: true,
      Cell: props => (
        <ExecutionIdCell
          cellProps={props}
          breadcrumbItems={breadcrumbItems}
          pathName={`/workflows/${props.original.workflowId}`}
        />
      )
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: props => <TimeStamp date={props.original.createdAt} />
    },
    {
      Header: "Modified At",
      accessor: "modifiedAt",
      Cell: props => <TimeStamp date={props.original.modifiedAt} />
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: true,
      Filter: (
        <StatusFilter
          statuses={ExecutionStatuses}
          statusFilterState={statusFilterState}
          setStatus={setStatus}
        />
      ),
      Cell: cellProps => <StatusCell original={cellProps.original} />
    }
  ];

  const pageSize = Math.min(executions.length, 10);
  const lastPage = Math.ceil(executions.length / pageSize);

  return (
    <div id="recentExecutions">
      <Card
        header={
          <div>
            <h2>Executions</h2>
            <DateRangeFilter
              timeFilterState={timeFilterState}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimeFilterMode={setTimeFilterMode}
            />
          </div>
        }
        className="tableContainer"
      >
        <Table
          columns={tableColumns}
          data={executions}
          NoDataComponent={NoDataComponent}
          loading={isFetching}
          showPagination={true}
          pageSize={pageSize}
          defaultPageSize={10}
          page={initialPage}
          showPageSizeOptions={false}
          showPageJump={false}
          sortable={false}
          onPageChange={page => setInitialPage(page)}
          onFetchData={({ page }) => {
            if (page + 1 === lastPage && offset !== null) {
              fetchExecutions(false, true);
            }
          }}
        />
      </Card>
    </div>
  );
}
