import { AxiosInstance } from "axios";

export default class EventAPI {
  protected readonly reportingClient: AxiosInstance;
  protected readonly coreClient: AxiosInstance;
  constructor(coreClient: AxiosInstance, reportingClient: AxiosInstance) {
    this.coreClient = coreClient;
    this.reportingClient = reportingClient;
  }
  async getEventByExecutionId(
    executionId: string,
    eventId: string
  ): Promise<object> {
    const response = await this.reportingClient.get(
      `/executions/${executionId}/events/${eventId}`
    );
    return response.data;
  }
  async getEventsByExecutionId(executionId: string): Promise<Array<object>> {
    const response = await this.reportingClient.get(
      `/executions/${executionId}/events`
    );
    return response.data?._embedded?.item;
  }

  async getEventsByStepId(
    executionId: string,
    stepId: string
  ): Promise<Array<object>> {
    const response = await this.reportingClient.get(
      `/executions/${executionId}/steps/${stepId}/events`
    );
    return response.data?._embedded?.item;
  }
}
