import { useContext, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  AppContext,
  AppContextValue,
  WorkflowContext,
  WorkflowContextValue,
  TimeZoneContext
} from "../../../common/context";
import {
  NULLVALUE,
  convertDateRangeToTimeStamp,
  Status
} from "../../../common/utility";
import DateRangeFilter from "../../../common/components/dateRangeFilter";
import React from "react";
import {
  Table,
  Checkbox,
  Button,
  FlexBox,
  Tooltip
} from "@cimpress/react-components";
import NoDataComponent from "../../../common/components/noDataComponent";
import {
  ExecutionIdCell,
  StatusCell,
  StepIdCell
} from "../../../common/components/table/cells";

import StatusFilter from "../../../common/components/statusFilter";
import {
  ActivityStatuses,
  BatchRetryableStatuses
} from "../../../common/utility";
import StepNameFilter from "./filter";
import _ from "lodash";
import useTimeFilter, {
  TimeFilterMode
} from "../../../common/hooks/useTimeFilter";
import useStatusFilter from "../../../common/hooks/useStatusFilter";
import config from "../../../config";
import TimeStamp from "../../../common/components/timeStamp";

const DEFAULT_PAGE_SIZE = 50;

//interface for the message recieved from worker
//NOTE: changes for this interface should be made in batchRetryWorker.ts file also
interface WorkerMessage {
  status: string;
  title: string;
  message?: string;
}

interface Props {
  original: { workflowId: string; executionId: string };
}

/**
 * Component to render
 * @param cellProps - the props passed by the cell
 * @param selectedStepsToRetry - the stepId, executionsId of steps to be retried
 * @param setSelectedStepsToRetry - function to set selectedStepsToRetry
 */
function CheckboxCell({
  cellProps,
  selectedStepsToRetry,
  setSelectedStepsToRetry
}: {
  cellProps: {
    original: { stepId: string; executionId: string; status: string };
  };
  selectedStepsToRetry: Array<{ stepId: string; executionId: string }>;
  setSelectedStepsToRetry: Function;
}) {
  const original = cellProps.original;
  const stepId = original.stepId;
  const executionId = original.executionId;
  const status = original.status;

  /**
   * function to be executed when the checkbox is selected
   * @param event - event object
   * @param payload - stepId, executionId of the selected step
   */
  const onChange = (event, payload) => {
    const index = selectedStepsToRetry
      .map(value => value.stepId)
      .indexOf(payload.stepId);
    if (index < 0) {
      selectedStepsToRetry.push(payload);
    } else {
      selectedStepsToRetry.splice(index, 1);
    }
    setSelectedStepsToRetry([...selectedStepsToRetry]);
  };

  return (
    <Checkbox
      checked={selectedStepsToRetry.map(value => value.stepId).includes(stepId)}
      payload={{ stepId, executionId }}
      onChange={onChange}
      id={stepId}
      disabled={!_.includes(BatchRetryableStatuses, status)}
    />
  );
}

/**
 * The header component for the checkBox columns
 * @param selectedStepsToRetry - the stepId, executionsId of steps to be retried
 * @param setSelectedStepsToRetry - function to set selectedStepsToRetry
 * @param steps - list of all steps
 */
function SelectAllComponent({
  isAllSelected,
  setSelectedStepsToRetry,
  steps
}: {
  isAllSelected: boolean;
  setSelectedStepsToRetry: Function;
  steps: Array<{ status: string }>;
}) {
  const stepsApplicableForSelect = steps.filter(step =>
    BatchRetryableStatuses.includes(step.status)
  );

  return (
    <FlexBox middle center marginX="m" paddingX="s" paddingY="s">
      <div> Select All</div>
      <Checkbox
        checked={isAllSelected}
        payload={"selectAll"}
        onChange={() => {
          if (!isAllSelected) {
            const valuesSelected = steps
              .filter(step => _.includes(BatchRetryableStatuses, step.status))
              .map(step => _.pick(step, ["stepId", "executionId"]));
            setSelectedStepsToRetry(valuesSelected);
          } else {
            setSelectedStepsToRetry([]);
          }
        }}
        id={"selectAll"}
        disabled={stepsApplicableForSelect.length <= 0}
      ></Checkbox>
    </FlexBox>
  );
}

/**
 * Renders StepsByWorkflowTable
 * @param props
 */
export default function StepsByWorkflowTable() {
  const history = useHistory();
  const { state: locationState } = history.location;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { workflowId } = useParams() as any;
  const { maestroClient, auth, batchRetryWorker } = useContext(
    AppContext
  ) as AppContextValue;
  const [steps, setSteps] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [initialPage, setInitialPage] = useState(0);
  const [offset, setOffset] = useState(null);
  const [
    timeFilterState,
    setStartDate,
    setEndDate,
    setTimeFilterMode
  ] = useTimeFilter(
    // Set initial filter settings
    {
      initialTimeFilterMode: TimeFilterMode.modifiedTime,
      initialStartDate: _.get(locationState, "beginTimeDate", null),
      initialEndDate: _.get(locationState, "beginTimeDate") ? new Date() : null
    }
  );
  const [selectedStepsToRetry, setSelectedStepsToRetry] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const { startDate, endDate, timeFilterMode } = timeFilterState;

  const [statusFilterState, setStatus] = useStatusFilter(
    _.get(locationState, "status", null) as Status
  );
  const { status } = statusFilterState;
  const { workflow } = useContext(WorkflowContext) as WorkflowContextValue;
  // initialize stepName if provide in location state
  const [stepNameFilter, setStepNameFilter] = useState(
    _.get(locationState, "stepName", null)
  );

  const { timeZone } = useContext(TimeZoneContext);

  /**
   * Fetches steps and sets states
   * @param resetInitialPage if true,  steps are fetched without offset and initial page will be set after steps are fetched
   * @param concatSteps if true fetched steps will be concatenated to current steps otherwise executions will be overwritten
   */
  async function fetchSteps(resetInitialPage: boolean, concatSteps: boolean) {
    const { beginTimeStamp, endTimeStamp } = convertDateRangeToTimeStamp(
      startDate,
      endDate,
      timeZone
    );
    const limit = 1000;
    setIsFetching(true);
    maestroClient
      .getStepsByWorkflowId(
        workflowId,
        stepNameFilter,
        limit,
        resetInitialPage ? NULLVALUE : offset,
        status ? status : NULLVALUE,
        beginTimeStamp,
        endTimeStamp,
        timeFilterMode
      )
      .then(result => {
        setSteps(
          concatSteps ? steps.concat(result.activities) : result.activities
        );
        setOffset(result.offset);
      })
      .catch(console.error)
      .finally(() => {
        setIsFetching(false);
        if (resetInitialPage) {
          setInitialPage(0);
        }
      });
  }

  /**
   * function to check if notification is supported by the browser and display notification
   * @param title - title for the notification
   * @param message - other details to be displayed
   */
  function sendNotification(title: string, message?: string) {
    if ("Notification" in window) {
      new Notification(title, { body: message });
    }
  }

  /**
   * function to be called when retry activity is called
   */
  function retryActivities() {
    if ("Notification" in window) {
      Notification.requestPermission();
    }
    /* eslint-disable no-restricted-globals */
    const confirmation = confirm(
      "The retry will be applied to selected steps across all pages. \nDo you want to proceed?"
    );
    if (confirmation) {
      sendNotification("Batch Retry Initiated!!");
      const token = auth.getAccessToken();
      batchRetryWorker.postMessage({
        token,
        selectedStepIds: selectedStepsToRetry,
        type: "batchRetry",
        coreBaseUrl: config.api.coreBaseUrl,
        reportingBaseUrl: config.api.reportingBaseUrl,
        status,
        timeFilterState,
        offset,
        isAllSelected,
        workflowId
      });
      batchRetryWorker.addEventListener("message", event => {
        const data: WorkerMessage = event.data;
        sendNotification(data.title, data.message);
      });
      setSelectedStepsToRetry([]);
    }
  }

  /**
   * Get steps when created date filter or status filter changes
   */
  useEffect(() => {
    fetchSteps(true, false);
    return () => {
      history.replace(history.location.pathname, {
        beginTimeDate: null,
        status: null,
        stepName: null
      });
    };
  }, [startDate, endDate, status, timeFilterMode, stepNameFilter, timeZone]);

  /**
   * when steps change, reset the selected steps to none
   */
  useEffect(() => {
    setSelectedStepsToRetry([]);
  }, [steps]);

  //seeing if everything is selected when the selected steps changes
  useEffect(() => {
    const stepsApplicableForSelect = steps.filter(step =>
      BatchRetryableStatuses.includes(step.status)
    );

    const isAllClicked =
      stepsApplicableForSelect.length > 0 &&
      selectedStepsToRetry.length === stepsApplicableForSelect.length;

    setIsAllSelected(isAllClicked);
  }, [selectedStepsToRetry]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tableColumns: Array<any> = [
    {
      Header: "Retry Steps",
      filterable: true,
      Filter: (
        <SelectAllComponent
          isAllSelected={isAllSelected}
          setSelectedStepsToRetry={setSelectedStepsToRetry}
          steps={steps}
        />
      ),
      // eslint-disable-next-line react/display-name
      Cell: props => (
        <CheckboxCell
          cellProps={props}
          selectedStepsToRetry={selectedStepsToRetry}
          setSelectedStepsToRetry={setSelectedStepsToRetry}
        />
      )
    },
    {
      Header: "StepId",
      accessor: "stepId",
      filterable: true,
      // eslint-disable-next-line react/display-name
      Cell: (props: Props) => (
        <StepIdCell
          cellProps={props}
          workflow={workflow}
          pathName={`/workflows/${props.original.workflowId}/executions/${props.original.executionId}`}
        />
      )
    },
    {
      Header: "Step Name",
      accessor: "name",
      filterable: true,
      Filter: (
        <StepNameFilter
          stepName={stepNameFilter}
          setStepName={setStepNameFilter}
          stepNames={
            workflow?.steps
              ? Object.entries(workflow.steps)
                  .filter(
                    // Only get steps that are type Activity
                    // TODO: Once core api is refactored to unify Steps and Activities this can be refactored
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ([, value]: [string, any]) => value.type == "Activity"
                  )
                  .map(([stepName]) => stepName)
              : []
          }
        />
      )
    },
    {
      Header: "ExecutionId",
      accessor: "executionId",
      filterable: true,
      // eslint-disable-next-line react/display-name
      Cell: (props: Props) => (
        <ExecutionIdCell
          cellProps={props}
          breadcrumbItems={null}
          pathName={`/workflows/${props.original.workflowId}`}
        />
      )
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      // eslint-disable-next-line react/display-name
      Cell: (props: { original: { createdAt: string } }) => (
        <TimeStamp date={props.original.createdAt} />
      )
    },
    {
      Header: "Modified At",
      accessor: "modifiedAt",
      // eslint-disable-next-line react/display-name
      Cell: (props: { original: { modifiedAt: string } }) => (
        <TimeStamp date={props.original.modifiedAt} />
      )
    },
    {
      Header: "Status",
      accessor: "status",
      filterable: true,
      Filter: (
        <StatusFilter
          statuses={ActivityStatuses}
          statusFilterState={statusFilterState}
          setStatus={setStatus}
        />
      ),
      Cell: StatusCell
    }
  ];

  const pageSize = Math.min(steps.length, DEFAULT_PAGE_SIZE);
  const lastPage = Math.ceil(steps.length / pageSize);

  return (
    <div id="stepByWorkflowIdTable">
      <FlexBox middle center marginX="m" paddingX="s" paddingY="s">
        <DateRangeFilter
          timeFilterState={timeFilterState}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setTimeFilterMode={setTimeFilterMode}
        />

        <div>
          <Tooltip
            direction={"top"}
            contents={
              "Retry will be applied to selected steps across all pages"
            }
          >
            <Button
              type="primary"
              onClick={retryActivities}
              disabled={selectedStepsToRetry.length > 0 ? false : true}
            >
              Retry ({isAllSelected ? " All " : selectedStepsToRetry.length})
            </Button>
          </Tooltip>{" "}
        </div>
      </FlexBox>
      <Table
        columns={tableColumns}
        data={steps}
        NoDataComponent={NoDataComponent}
        loading={isFetching}
        showPagination={true}
        pageSize={pageSize}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        page={initialPage}
        showPageSizeOptions={false}
        showPageJump={false}
        sortable={false}
        onPageChange={page => setInitialPage(page)}
        onFetchData={({ page }) => {
          if (page + 1 === lastPage && offset !== null) {
            fetchSteps(false, true);
          }
        }}
      />
    </div>
  );
}
