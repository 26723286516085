import React, { useContext, Dispatch } from "react";
import { AppContext, AppContextValue } from "../../../../../../common/context";
import { Button } from "@cimpress/react-components";
import { Views, MODAL_NAME } from "../../";
import PropTypes from "prop-types";
import { ModalStore } from "../../../../../../common/hooks/useModals";
import { WorkflowDraft } from "../../../..";
import { useHistory } from "react-router";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("@cimpress/react-components/lib/colors");

/**
 * Footer component in the workflow builder modal
 * initially displayed to the user when they land on the page.
 */
export function HomeViewFooter({ setView }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        borderTop: `1px solid ${colors.shale}`,
        paddingTop: "1em"
      }}
    >
      <a
        style={{ cursor: "pointer" }}
        onClick={() => setView(Views.STORAGE_VIEW)}
      >
        Change Storage
      </a>
    </div>
  );
}

HomeViewFooter.propTypes = {
  setView: PropTypes.func
};

interface HomeViewProps {
  store: ModalStore;
  setView: Dispatch<string>;
}

/**
 * Footer component in the workflow builder modal
 * initially displayed to the user when they land on the page.
 */
export function HomeView(props: HomeViewProps) {
  const { maestroClient } = useContext(AppContext) as AppContextValue;
  const { store, setView } = props;
  const history = useHistory();
  return (
    <div className="workflow-builder-wizard-modal-home-view">
      <Button
        onClick={() => {
          setView(Views.CREATE_NEW_WORKFLOW_VIEW);
        }}
      >
        Create New Workflow
      </Button>
      <label className="btn btn-default">
        <input
          type="file"
          accept="application/JSON"
          onChange={event => {
            const reader = new FileReader();
            reader.onload = function(event) {
              const deserializedWorkflowDraft: WorkflowDraft = JSON.parse(
                event.target.result as string
              );
              maestroClient
                .validateWorkflow(deserializedWorkflowDraft.workflow)
                .then(() => {
                  store.toggleVisibility(MODAL_NAME);
                  history.push({
                    pathname: "/workflows/build",
                    state: {
                      WorkFlowState: deserializedWorkflowDraft.workflow, // eslint-disable-next-line @typescript-eslint/no-use-before-define
                      FileName: fileName
                    }
                  });
                })
                .catch(err => {
                  alert(err);
                  console.error(err);
                });
            };
            const fileName = event.target.files[0].name;
            reader.readAsText(event.target.files[0]);
            event.target.value = "";
          }}
        />
        Open Existing Workflow
      </label>
    </div>
  );
}

HomeView.propTypes = {
  store: PropTypes.object,
  setView: PropTypes.func
};
