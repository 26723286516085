import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { JSONEditorMode } from "jsoneditor";
import createReducer, { State } from "../../../../common/reducer";
import { handlers } from "../../../../common/reducer/handlers";
import { UpdatePropAction } from "../../../../common/reducer/actions";

export const JSONEditorContext = React.createContext(null);

export interface EditorProvider {
  state: JSONEditorProviderState;
  dispatch: (action: UpdatePropAction) => void;
}

interface JSONEditorProviderState extends State {
  mode: JSONEditorMode;
  isValid: boolean;
  refresh: number;
}

const initialState: JSONEditorProviderState = {
  mode: "tree",
  isValid: true,
  refresh: 0
};

/**
 * Context provider for json editor
 */
export function JSONEditorProvider(props) {
  const reducer = createReducer<JSONEditorProviderState, UpdatePropAction>(
    initialState,
    handlers
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: EditorProvider = {
    state,
    dispatch
  };

  return (
    <JSONEditorContext.Provider value={value}>
      {props.children}
    </JSONEditorContext.Provider>
  );
}

JSONEditorProvider.propTypes = {
  children: PropTypes.node.isRequired
};
