import { useReducer } from "react";
import createReducer from "../reducer";
import { handlers } from "../reducer/handlers";
import { CommonAction } from "../reducer/actions";
import { Status } from "../../common/utility";
export interface StatusFilterState {
  status: Status;
}

const STATUS_PATH = "status";

type SetStatusFunc = (status: Status) => void;

/**
 * Provides state for status filters
 * @param initialStatus initializes status state
 * @returns tuple containing [statusFilterState, setStatus]
 */
export default function useStatusFilter(
  initialStatus?: Status
): [StatusFilterState, SetStatusFunc] {
  const initialState: StatusFilterState = {
    status: initialStatus || null
  };
  const reducer = createReducer<StatusFilterState, CommonAction>(
    initialState,
    handlers
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * set status state
   * @param status
   */
  function setStatus(status: string) {
    dispatch({
      type: "updateProp",
      path: STATUS_PATH,
      value: status
    });
  }

  return [state, setStatus];
}
