import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext, AppContextValue } from "../../../common/context";
import { FlexBox } from "@cimpress/react-components";
import IconBin from "@cimpress-technology/react-streamline-icons/lib/IconBin";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarContent
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
// override react-pro-sidebar styles
import "./index.css";
import useLocalStorage, {
  StorageItem
} from "../../../common/hooks/useLocalStorage";
import _ from "lodash";
import { WorkflowTagEntry } from "../../../common/utility";

interface TaggedWorkflowMenuProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflows: Array<any>;
  isVisible: boolean;
  closeDrawer: () => void;
}

/**
 * Renders TaggedWorkflowMenu
 * @param props.workflows workflows that are visible to the user
 * @param props.isVisible visibility state of the containing drawer
 * @param props.closeDrawer function that closes containing drawer
 */
export default function TaggedWorkflowMenu(props: TaggedWorkflowMenuProps) {
  const { workflowTagsStorage } = useContext(AppContext) as AppContextValue;
  const [storageState, storageHandler] = useLocalStorage(workflowTagsStorage);
  const [workflowsByTag, setWorkflowsByTag] = useState([]);
  const { workflows } = props;

  /**
   * Fetches all tags from local storage whenever a change is made, filters out any tagged workflows that may have been deleted
   * setsWorkflowsByTag
   */
  useEffect(() => {
    storageHandler
      .getAllItems()
      .then(workflowsGroupedByTag => {
        // TODO implement a way to clean up local storage of deleted workflows
        // Or apply css class to show that workflow is deleted or inaccessible, user can manually delete
        const workflowsGroupedByTagFiltered = workflowsGroupedByTag.map(
          ({ key: tag, value: taggedWorkflows }) => {
            const filteredTaggedWorkflows = _.intersectionBy(
              taggedWorkflows,
              workflows,
              "workflowId"
            );
            return {
              key: tag,
              value: filteredTaggedWorkflows
            };
          }
        );

        setWorkflowsByTag(workflowsGroupedByTagFiltered);
      })
      .catch(console.error);
  }, [storageState, props.workflows, props.isVisible]);

  /**
   * removes a tag from local storage
   * @param tag
   */
  async function deleteTag(tag: string) {
    try {
      await storageHandler.removeItem(tag);
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Removes the tag from a workflow
   * @param tag
   * @param workflowEntry
   */
  async function removeWorkflowFromTag(
    tag: string,
    workflowEntry: WorkflowTagEntry
  ) {
    try {
      await storageHandler.popItem(tag, workflowEntry);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <ProSidebar>
        <SidebarHeader>
          <FlexBox spaceAround middle>
            <h4>Tagged Workflows</h4>
          </FlexBox>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape="square" popperArrow>
            {workflowsByTag.map((entry: StorageItem, index) => {
              return (
                <SubMenu
                  title={entry.key}
                  prefix={
                    <IconBin
                      onClick={() => {
                        deleteTag(entry.key);
                      }}
                      style={{ marginRight: "1em" }}
                    ></IconBin>
                  }
                  key={entry.key + index}
                >
                  {entry?.value.map(
                    (workflowEntry: WorkflowTagEntry, tagsIndex) => {
                      return (
                        <MenuItem
                          key={entry.key + tagsIndex}
                          prefix={
                            <IconBin
                              style={{ marginRight: "1em" }}
                              onClick={() => {
                                removeWorkflowFromTag(entry.key, workflowEntry);
                              }}
                            />
                          }
                        >
                          <Link
                            onClick={props.closeDrawer}
                            to={`/workflows/${workflowEntry.workflowId}`}
                          >
                            {workflowEntry.workflowName}
                          </Link>
                        </MenuItem>
                      );
                    }
                  )}
                </SubMenu>
              );
            })}
          </Menu>
        </SidebarContent>
      </ProSidebar>
    </div>
  );
}
