import React, { useContext, useEffect, useState } from "react";
import "./WorkflowOverview.css";
import WorkflowOverviewStepTable from "./workflowOverviewStepTable";
import LatestExecutionsTable from "./latestExecutionsTable";
import { WorkflowContext, WorkflowContextValue } from "../../common/context";
import WorkflowSummary from "../../common/components/workflowSummary";
import { FlexBox, Button } from "@cimpress/react-components";
import { useParams, Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { AppContext, AppContextValue } from "../../common/context";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import Breadcrumb, {
  Props as BreadcrumbProps
} from "../../common/components/breadcrumb";
import {
  getHomeBreadcrumb,
  getWorkflowIdBreadcrumb
} from "../../common/utility";
import FourOhFourNotFound from "../fourOhFourNotFoundPage";
import { FORBIDDEN_CODE, FORBIDDEN_MESSAGE } from "../constants";

/* eslint-disable */
const colors = require("@cimpress/react-components/lib/colors");
/**
 * page header
 * @param label Label
 */
function Header({ label, workflow, workflowId, breadcrumbs }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${colors.shale}`,
        paddingBottom: "0.3125em",
        marginBottom: "1em",
        fontSize: "1.625em"
      }}
    >
      {label}
      <div>
        <FlexBox right marginX="m">
          <Link
            to={{
              pathname: `/workflows/${workflowId}/viewWorkflowData`,
              state: {
                breadcrumbItems: breadcrumbs.items,
                workflow
              }
            }}
          >
            <Button type="default">View Data</Button>
          </Link>
          <Link to={`/workflows/${workflowId}/edit`}>
            <Button type="default">Edit</Button>
          </Link>
        </FlexBox>
      </div>
    </div>
  );
}

export default function WorkflowOverviewPage(props: any) {
  const { workflowId } = useParams();
  const appCtx: AppContextValue = useContext(AppContext);
  const [workflow, setWorkflow] = useState(null);
  const [isForbidden, setIsForbidden] = useState(false);

  useEffect(() => {
    appCtx.maestroClient
      .getWorkflow(workflowId)
      .then(data => {
        setWorkflow(data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status == FORBIDDEN_CODE) {
          setIsForbidden(true);
        }
      });
  }, [workflowId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let breadcrumbItems: Array<any>;

  const location = useLocation();

  if (location.state) {
    breadcrumbItems = _.get(location.state, "breadcrumbItems");
  }

  if (breadcrumbItems) {
    breadcrumbItems = _.concat(breadcrumbItems, [
      getWorkflowIdBreadcrumb(workflowId, _.get(workflow, "name", "?"))
    ]);
  } else {
    // build breadcrumbs
    breadcrumbItems = [
      getHomeBreadcrumb(),
      getWorkflowIdBreadcrumb(workflowId, _.get(workflow, "name", "?"))
    ];
  }

  const breadcrumbs: BreadcrumbProps = {
    items: breadcrumbItems
  };

  const value: WorkflowContextValue = {
    workflowId,
    breadcrumbItems: breadcrumbs.items
  };

  if (isForbidden) {
    return <FourOhFourNotFound message={FORBIDDEN_MESSAGE} />;
  } else {
    return (
      <WorkflowContext.Provider value={value}>
        <Breadcrumb {...breadcrumbs} />
        <Header
          label="Workflow Summary"
          workflow={workflow}
          workflowId={workflowId}
          breadcrumbs={breadcrumbs}
        />
        {workflow ? <WorkflowSummary workflow={workflow} /> : <Spinner />}
        <WorkflowOverviewStepTable></WorkflowOverviewStepTable>
        <LatestExecutionsTable />
      </WorkflowContext.Provider>
    );
  }
}
