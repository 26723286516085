import React, { useRef, useEffect } from "react";
import Chart, {
  ChartLegendLabelOptions,
  ChartLegendLabelItem,
  ChartConfiguration
} from "chart.js";
import { GetExecutionsSummaryResult } from "../../api/workflow";

const chartColors = {
  Completed: "rgba(140, 215, 206, 1)",
  Running: "rgba(178, 172, 250, 1)",
  Failed: "rgba(233, 162, 173, 1)",
  Aborted: "rgba(243, 193, 156, 1)",
  Terminated: "rgba(195, 207, 217, 1)"
};

export interface ExecutionsSummaryChartProps {
  summaryData: GetExecutionsSummaryResult;
}

/**
 * ExecutionsSummaryChart component, function renders the chart for the workflow summary component
 * @param props
 */
const ExecutionsSummaryChart = (props: ExecutionsSummaryChartProps) => {
  const summaryData = props.summaryData;
  const total = summaryData?._total;
  const completedData = summaryData?.status.Completed;
  const runningData = summaryData?.status.Running;
  const failedData = summaryData?.status.Failed;
  const abortedData = summaryData?.status.Aborted;
  const terminatedData = summaryData?.status.Terminated;

  const chartContainer = useRef(null);
  let chartInstance = null;

  const labelOptions: ChartLegendLabelOptions = {
    generateLabels: chart => {
      // call global generate labels function for pie chart
      const originalLabels: ChartLegendLabelItem[] = Chart.defaults.pie.legend.labels.generateLabels(
        chart
      );

      // hide labels where the status count is 0
      const filteredLabels = originalLabels.filter(label => {
        const status = label.text;
        return summaryData.status[status] > 0;
      });

      // add the status count next to the label to display in the legends
      filteredLabels.forEach(label => {
        label.text = `${summaryData.status[label.text]} ${label.text}`;
      });
      // add total count as a label to display in legend
      filteredLabels.push({
        text: `Total : ${summaryData._total}`
      });

      return filteredLabels;
    }
  };

  const chartConfig: ChartConfiguration = {
    type: "doughnut",
    data: {
      labels: ["Completed", "Running", "Failed", "Aborted", "Terminated"],
      datasets: [
        {
          data: [
            completedData,
            runningData,
            failedData,
            abortedData,
            terminatedData
          ],
          backgroundColor: [
            chartColors.Completed,
            chartColors.Running,
            chartColors.Failed,
            chartColors.Aborted,
            chartColors.Terminated
          ]
        }
      ]
    },
    options: {
      legend: {
        display: true,
        position: "right",
        labels: labelOptions
      },
      responsive: true,
      maintainAspectRatio: false
    }
  };

  /**
   * creating a new chart instance whenever the summary data changes
   */
  useEffect(() => {
    if (chartContainer && chartContainer.current) {
      if (chartInstance) chartInstance.destroy();
      chartInstance = new Chart(chartContainer.current, chartConfig);
    }
  }, [summaryData, chartContainer.current]);

  return (
    <div>
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%"
        }}
      >
        {total > 0 ? <canvas ref={chartContainer} /> : <b>No Data</b>}
      </div>
    </div>
  );
};

export default ExecutionsSummaryChart;
