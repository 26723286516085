import React from "react";
import { StatusFilterState } from "../../hooks/useStatusFilter";
/**
 * props types for the statusFilterComponent
 */
interface StatusFilterProps {
  statuses: Array<string>;
  statusFilterState: StatusFilterState;
  setStatus: (string) => void;
}

/**
 * the component for status filter
 * @param props
 */
export default function StatusFilter(props: StatusFilterProps) {
  const { statuses, statusFilterState, setStatus } = props;
  const { status } = statusFilterState;

  return (
    <div>
      <select
        style={{ border: "0px" }}
        name="status"
        value={status == null ? "all" : status}
        onChange={e => {
          const value = e.target.value;
          setStatus(value === "all" ? null : value);
        }}
      >
        <option value={"all"} key={"all"}>
          Show All
        </option>
        {statuses.map(value => {
          return (
            <option value={value} key={value}>
              {value}
            </option>
          );
        })}
      </select>
    </div>
  );
}
