import React, { useState, useContext, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { NavTab, NavTabItem } from "@cimpress/react-components";
import _ from "lodash";
import Breadcrumb, {
  Props as BreadcrumbProps
} from "../../common/components/breadcrumb";
/* eslint-disable */
import { AppContext, AppContextValue } from "../../common/context";
import StepsByWorkflowTable from "./stepsByWorkflowTable";
import {
  getHomeBreadcrumb,
  getWorkflowIdBreadcrumb,
  getViewWorkflowDataBreadcrumb
} from "../../common/utility";
import { WorkflowContext } from "../../common/context";
import LatestExecutionsTable from "../workflowOverview/latestExecutionsTable";

/**
 * Renders ViewWorkflowData Component
 * allow user to filter steps and executions for a specific workflow
 */
export default function ViewWorkflowData() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { workflowId } = useParams();
  let workflowFromState = null;
  const { maestroClient } = useContext(AppContext) as AppContextValue;
  const location = useLocation();
  let breadcrumbItems = null;
  let breadcrumbs: BreadcrumbProps = {
    items: []
  };
  if (location.state) {
    breadcrumbItems = _.get(location.state, "breadcrumbItems");
    workflowFromState = _.get(location.state, "workflow", null);
  }
  const [workflow, setWorkflow] = useState(workflowFromState);

  if (breadcrumbItems) {
    breadcrumbs.items = _.concat(breadcrumbItems, [
      getViewWorkflowDataBreadcrumb(workflowId)
    ]);
  } else {
    // build breadcrumbs
    breadcrumbs = {
      items: [
        getHomeBreadcrumb(),
        getWorkflowIdBreadcrumb(workflowId, _.get(workflow, "name", "?")),
        getViewWorkflowDataBreadcrumb(workflowId)
      ]
    };
  }
  /**
   * Fetch workflow if not provided by location state
   */
  useEffect(() => {
    if (workflow == null) {
      maestroClient
        .getWorkflow(workflowId)
        .then(data => {
          setWorkflow(data);
        })
        .catch(error => console.log(error));
    }
  }, []);

  const tabs = [
    {
      id: 0,
      name: "Steps",
      block: <StepsByWorkflowTable />
    },
    {
      id: 1,
      name: "Executions",
      block: <LatestExecutionsTable />
    }
  ];
  return (
    <div>
      <Breadcrumb {...breadcrumbs} />
      <NavTab>
        {tabs.map(tab => {
          return (
            <NavTabItem key={tab.id} active={selectedTabIndex == tab.id}>
              <button
                style={{ fontSize: "large" }}
                onClick={() => setSelectedTabIndex(tab.id)}
              >
                {tab.name}
              </button>
            </NavTabItem>
          );
        })}
      </NavTab>
      <WorkflowContext.Provider
        value={{ workflowId, workflow, breadcrumbItems: breadcrumbs.items }}
      >
        <div>{tabs[selectedTabIndex].block}</div>
      </WorkflowContext.Provider>
    </div>
  );
}
