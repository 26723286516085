import React, { useContext, useEffect, useState } from "react";
import "./WorkflowOverview.css";
import {
  AppContext,
  AppContextValue,
  TimeZoneContext
} from "../../common/context";
import Breadcrumb, {
  Props as BreadcrumbProps
} from "../../common/components/breadcrumb";
import {
  getHomeBreadcrumb,
  getAnonymousWorkflowBreadcrumb
} from "../../common/utility";
import { Table, Card } from "@cimpress/react-components";
import NoDataComponent from "../../common/components/noDataComponent";
import TimeStamp from "../../common/components/timeStamp";
import { ExecutionIdCell } from "../../common/components/table/cells";
import useLocalStorage from "../../common/hooks/useLocalStorage";

/**
 * Renders AnonymousExecutionsTable
 * @param props
 * @param breadcrumbItems - breadcrumbItems
 */
function AnonymousExecutionsTable({
  breadcrumbItems
}: {
  breadcrumbItems: Array<any>;
}) {
  const { anonymousWorkflowStorage } = useContext(
    AppContext
  ) as AppContextValue;
  const [storageState, storage] = useLocalStorage(anonymousWorkflowStorage);
  const [executions, setExecutions] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const { timeZone } = useContext(TimeZoneContext);
  useEffect(() => {
    async function fetchExecutions() {
      setIsFetching(true);
      storage
        .getAllItems()
        .then(result => {
          const executionDetails = result[0].value;
          setExecutions(executionDetails);
        })
        .catch(console.error)
        .finally(() => {
          setIsFetching(false);
        });
    }
    fetchExecutions();
  }, [timeZone]);

  const tableColumns: Array<any> = [
    {
      Header: "ExecutionId",
      accessor: "executionId",
      filterable: true,
      // eslint-disable-next-line react/display-name
      Cell: (props: any) => (
        <ExecutionIdCell
          cellProps={props}
          breadcrumbItems={breadcrumbItems}
          pathName={"/workflows/anonymous"}
        />
      )
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      // eslint-disable-next-line react/display-name
      Cell: (props: any) => <TimeStamp date={props.original.createdAt} />
    },
    {
      Header: "Modified At",
      accessor: "modifiedAt",
      // eslint-disable-next-line react/display-name
      Cell: (props: any) => <TimeStamp date={props.original.modifiedAt} />
    }
  ];

  const sortKey = [
    {
      id: "createdAt",
      desc: true
    }
  ];

  const pageSize = Math.min(executions.length, 10);
  return (
    <div id="recentExecutions">
      <Card header={<h2>Executions</h2>} className="tableContainer">
        <Table
          columns={tableColumns}
          data={executions}
          NoDataComponent={NoDataComponent}
          loading={isFetching}
          sorted={sortKey}
          defaultPageSize={10}
          pageSize={pageSize}
          showPagination={true}
          showPageSizeOptions={false}
          showPageJump={false}
          sortable={false}
        />
      </Card>
    </div>
  );
}

export default function AnonymousWorkflow() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const breadcrumbItems: Array<any> = [
    getHomeBreadcrumb(),
    getAnonymousWorkflowBreadcrumb()
  ];
  const breadcrumbs: BreadcrumbProps = {
    items: breadcrumbItems
  };
  return (
    <div>
      <Breadcrumb {...breadcrumbs} />
      <AnonymousExecutionsTable breadcrumbItems={breadcrumbs.items} />
    </div>
  );
}
