import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Button,
  FlexBox,
  Label,
  Toggle
} from "@cimpress/react-components";
import PropTypes from "prop-types";
import "./dateRangeFilter.css";
import {
  TimeFilterState,
  SetDateFunc,
  TimeFilterMode
} from "../../hooks/useTimeFilter";
import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment-timezone";
import { TimeZoneContext } from "../../context";
import { LOCAL_TIMEZONE, UTC_TIMEZONE } from "../../utility";
const START_TIME = "00:00:00";
const END_TIME = "23:59:59";

/**
 * base component for the date filter
 * @param props
 */
function DateRangeComponent(props) {
  const selectionRange = {
    startDate: props.startDate,
    endDate: props.endDate,
    key: "selection"
  };

  return (
    <DateRangePicker
      ranges={[selectionRange]}
      onChange={props.onChange}
      maxDate={new Date()}
    />
  );
}

DateRangeComponent.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func
};

interface DateRangeFilterProps {
  disableTimeToggle?: boolean;
  timeFilterState: TimeFilterState;
  setStartDate: SetDateFunc;
  setEndDate: SetDateFunc;
  setTimeFilterMode: (TimeFilterMode) => void;
}

/**
 * function to create a date from date and time string
 * @param date - date string
 * @param time - time string
 */
function formatDateTime(date: string, time: string): Date {
  return new Date(`${date} ${time}`);
}

/**
 * function to create a date time string from date
 * @param date - date object
 */
function displayDateTime(date: Date): string {
  return date.toLocaleDateString() + "  " + date.toLocaleTimeString();
}

/**
 * component for the modal date filter component
 */
export default function DateRangeFilter(props: DateRangeFilterProps) {
  const {
    disableTimeToggle,
    timeFilterState,
    setStartDate,
    setEndDate,
    setTimeFilterMode
  } = props;

  const { startDate, endDate, timeFilterMode } = timeFilterState;
  const [isFilterSet, setIsFilterSet] = useState(false);
  const { timeZone } = useContext(TimeZoneContext);

  /**
   * Check if a date range has been set, set flag if so
   */
  useEffect(() => {
    setIsFilterSet(startDate && endDate ? true : false);
  }, [startDate, endDate]);

  const [startDateInternal, setStartDateInternal] = useState(
    startDate || formatDateTime(new Date().toDateString(), START_TIME)
  );

  const [endDateInternal, setEndDateInternal] = useState(
    endDate || formatDateTime(new Date().toDateString(), END_TIME)
  );
  const [open, setOpen] = useState(false);

  const modalButtons = (
    <FlexBox right middle spaceBetween marginX="l">
      <Button
        className="btn btn-default"
        onClick={() => {
          setOpen(false);
          setStartDate(startDateInternal);
          setEndDate(endDateInternal);
        }}
      >
        Apply
      </Button>
      <Button
        className="btn btn-default"
        onClick={() => {
          setOpen(false);
        }}
      >
        Close
      </Button>
    </FlexBox>
  );

  /**
   * function to be executed when the range changes
   * @param ranges
   */
  function onChange(ranges) {
    const selection = ranges.selection;

    setStartDateInternal(
      formatDateTime(
        selection.startDate.toDateString(),
        startDateInternal.toTimeString()
      )
    );
    setEndDateInternal(
      formatDateTime(
        selection.endDate.toDateString(),
        endDateInternal.toTimeString()
      )
    );
  }

  /**
   * function to be executed when start time is selected
   * @param time
   * @param timestring
   */
  function onStartTimeChange(time, timestring) {
    setStartDateInternal(
      formatDateTime(startDateInternal.toDateString(), timestring)
    );
  }

  /**
   * function to be executed when end time is selected
   * @param time
   * @param timestring
   */
  function onEndTimeChange(time, timestring) {
    setEndDateInternal(
      formatDateTime(endDateInternal.toDateString(), timestring)
    );
  }

  /**
   * function to open the date filter component
   */
  function openModal() {
    return (
      <Modal
        bsStyle="info"
        bsSize="lg"
        show={open}
        onRequestHide={() => setOpen(false)}
        closeOnOutsideClick={true}
        title="Select a date range"
        footer={modalButtons}
        style={{ padding: "90px", width: "40%" }}
        className="filter-container"
      >
        <FlexBox isVertical marginY="l" spaceBetween>
          <DateRangeComponent
            startDate={startDateInternal}
            endDate={endDateInternal}
            onChange={onChange}
          />

          <div
            style={{
              marginLeft: "40%",
              backgroundColor: "rgb(239, 242, 247)",
              width: "60%",
              height: "50px",
              padding: "1%"
            }}
          >
            <FlexBox
              right
              middle
              spaceBetween
              marginX="l"
              className="time-picker-container"
            >
              <TimePicker
                value={moment(startDateInternal, "HH-mm-ss")}
                onChange={onStartTimeChange}
              />{" "}
              <b>~</b>
              <TimePicker
                value={moment(endDateInternal, "HH-mm-ss")}
                onChange={onEndTimeChange}
              />
              <b>
                {timeZone === LOCAL_TIMEZONE
                  ? moment.tz(moment.tz.guess()).zoneAbbr()
                  : UTC_TIMEZONE.toUpperCase()}
              </b>
            </FlexBox>
          </div>
        </FlexBox>
      </Modal>
    );
  }

  return (
    <FlexBox middle left marginX="xl" spaceBetween>
      <div>
        <Toggle
          disabled={disableTimeToggle}
          on={timeFilterMode == TimeFilterMode.createdTime}
          onText=""
          offText=""
          onClick={() => {
            if (timeFilterMode == TimeFilterMode.createdTime) {
              setTimeFilterMode(TimeFilterMode.modifiedTime);
            } else {
              setTimeFilterMode(TimeFilterMode.createdTime);
            }
          }}
        />
      </div>
      <h6>
        {timeFilterMode == TimeFilterMode.createdTime
          ? "Created Time"
          : "Modified Time"}
      </h6>
      <Button size="sm" className="filterButton" onClick={() => setOpen(true)}>
        Set Date Range
      </Button>
      {openModal()}
      <Button
        size="sm"
        className="filterButton"
        disabled={!isFilterSet}
        onClick={() => {
          const startDate = formatDateTime(
            new Date().toDateString(),
            START_TIME
          );
          const endDate = formatDateTime(new Date().toDateString(), END_TIME);
          setEndDateInternal(endDate);
          setStartDateInternal(startDate);
          setEndDate(null);
          setStartDate(null);
          setIsFilterSet(false);
        }}
      >
        Reset
      </Button>
      {isFilterSet ? (
        <div style={{ marginLeft: "15px" }}>
          <Label
            className="labelCss"
            text={displayDateTime(startDate)}
            type="info"
          ></Label>
          ~
          <Label
            className="labelCss"
            text={displayDateTime(endDate)}
            type="info"
          ></Label>
        </div>
      ) : (
        <></>
      )}
    </FlexBox>
  );
}
