import React, { useEffect, useState, useRef, useContext } from "react";
import _ from "lodash";

import JSONEditor, {
  SchemaValidationError,
  ParseError,
  JSONEditorMode,
  MenuItem,
  MenuItemNode
} from "jsoneditor";

import "./index.css";
import "jsoneditor/dist/jsoneditor.css";
import { EditorProvider, JSONEditorContext } from "./context";

import { GraphContext, Graph } from "../graph/context";
import { TempWorkflowContext } from "../../../../common/hooks/useTempWorkflow";
import { AppContext, AppContextValue } from "../../../../common/context";
import { NodeModel } from "@projectstorm/react-diagrams";

// const darktheme = require("./darktheme.css");

function ReactJSONEditor(props) {
  const [instance, setInstance] = useState<JSONEditor>(null);
  const jsonEditorRef = useRef<HTMLDivElement>(null);

  const graph: Graph = useContext(GraphContext);
  const {
    node: [selectedNode]
  } = graph;

  // need to capture latest selected node to prevent stale values for being read
  // in the onChangeText callback
  const selectedNodeRef = useRef(null);
  selectedNodeRef.current = selectedNode;

  const tempWorkflowContext = useContext(TempWorkflowContext);
  const {
    updateProp: updateWorkflowProp,
    validateTempWorkflow
  } = tempWorkflowContext;
  const { tempWorkflow } = tempWorkflowContext.state;
  const store: EditorProvider = useContext(JSONEditorContext);
  const { state } = store;

  /**
   * Gets the name of the selected node, which is the step name. Null if no node is selected
   * @param node NodeModel from react-diagrams
   */
  function getNodeName(node: NodeModel) {
    return node ? _.get(node.getOptions(), "name") : null;
  }

  function onCreateMenu(menuItems: MenuItem[], node: MenuItemNode) {
    // disable context menu for step properties
    if (node.path.length > 1) return [];

    // remove all context menu items except for duplicate and remove
    const filteredItems = menuItems.filter(
      item => item.text === "Duplicate" || item.text === "Remove"
    );

    return filteredItems;
  }

  function onModeChange(
    newMode: JSONEditorMode,
    oldMode: JSONEditorMode /* eslint-disable-line */
  ) {
    store.dispatch({
      type: "updateProp",
      path: "mode",
      value: newMode
    });
  }

  /**
   * Updates the workflow state on editor changes. Changes are validated by via
   * calls to maestro api. Errors will be rendered in the steps graph view
   * @param jsonStr edits made in the json editor
   */
  async function onChangeText(jsonStr: string) {
    try {
      const newState = JSON.parse(jsonStr);
      const stepName = getNodeName(selectedNodeRef.current);

      // if a node is selected on the steps graph, update that step object in the workflow
      // else, update the entire steps object in the workflow
      stepName
        ? updateWorkflowProp(`steps.${stepName}`, newState)
        : updateWorkflowProp("steps", newState);

      await validateTempWorkflow();
    } catch (error) {
      console.error(error);
    }
  }

  function onValidationError(
    errors: ReadonlyArray<SchemaValidationError | ParseError>
  ) {
    const valid = _.isEmpty(errors);
    store.dispatch({
      type: "updateProp",
      path: "isValid",
      value: valid
    });
  }

  /**
   * Initializes the json editor
   */
  useEffect(() => {
    const options = _.get(props, "options", {
      modes: ["tree", "code", "preview"],
      enableTransform: false,
      enableSort: false,
      // onChange: bug on editor.get() function so we need to parse the jsonStr manually via onChangeText callback
      onChangeText: _.debounce(onChangeText, 1000),
      onValidationError: onValidationError,
      onModeChange: onModeChange,
      onCreateMenu: onCreateMenu
    });

    const editor = new JSONEditor(jsonEditorRef.current, options);
    editor.set(tempWorkflow.steps);
    editor.setMode(state.mode);
    setInstance(editor);
    return () => editor.destroy();
  }, []);

  /**
   * Updates the json editor when the mode changes
   */
  useEffect(() => {
    const stepName = getNodeName(selectedNode);
    const json = stepName ? tempWorkflow.steps[stepName] : tempWorkflow.steps;
    instance?.set(json);
    instance?.setMode(state.mode);
  }, [state.mode]);

  /**
   * Refreshes the editor content
   */
  useEffect(() => {
    const aceEditor = _.get(instance, "aceEditor", null);
    aceEditor?.resize();
  }, [state.refresh]);

  /**
   * Hide unused buttons
   */
  useEffect(() => {
    // default repair isn't working for some reason, but we could add our own repair callback and override the default one
    // for now, hide it
    jsonEditorRef.current
      .getElementsByClassName("jsoneditor-repair")[0]
      ?.setAttribute("hidden", "true");
  }, [tempWorkflow.steps, state.mode]);

  /**
   * Updates the json editor when the selected node in the graph changes
   */
  useEffect(() => {
    const stepName = getNodeName(selectedNode);
    const json = stepName ? tempWorkflow.steps[stepName] : tempWorkflow.steps;
    instance?.set(json);
  }, [selectedNode]);

  return <div className="jsoneditor-react-container" ref={jsonEditorRef}></div>;
}

export default ReactJSONEditor;
