import React, { useReducer } from "react";
import PropTypes from "prop-types";
import createReducer, { State } from "../../../../common/reducer";
import { UpdatePropAction } from "../../../../common/reducer/actions";
import { handlers } from "../../../../common/reducer/handlers";

export const WorkflowMetadataContext = React.createContext(null);

export interface MetadataProvider {
  state: MetadataState;
  dispatch: (action: UpdatePropAction) => void;
}

interface MetadataState extends State {
  isTimeoutValid;
  isValid;
}

const initialState: MetadataState = {
  isTimeoutValid: true,
  isValid: true
};

/**
 * Context provider for json editor
 */
export function WorkflowMetadataProvider(props) {
  const reducer = createReducer<MetadataState, UpdatePropAction>(
    initialState,
    handlers
  );
  const [state, dispatch] = useReducer(reducer, initialState);

  const value: MetadataProvider = {
    state,
    dispatch
  };

  return (
    <WorkflowMetadataContext.Provider value={value}>
      {props.children}
    </WorkflowMetadataContext.Provider>
  );
}

WorkflowMetadataProvider.propTypes = {
  children: PropTypes.node.isRequired
};
