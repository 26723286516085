import { omitBy, isNil } from "lodash";
import { AxiosInstance } from "axios";
import querystring from "query-string";

export interface GetExecutionsResult {
  executions: Array<object>;
  offset?: string;
}

export default class ExecutionAPI {
  protected readonly reportingClient: AxiosInstance;
  protected readonly coreClient: AxiosInstance;
  constructor(coreClient: AxiosInstance, reportingClient: AxiosInstance) {
    this.coreClient = coreClient;
    this.reportingClient = reportingClient;
  }
  async getExecution(executionId: string): Promise<object> {
    const response = await this.reportingClient.get(
      `/executions/${executionId}`
    );
    return response.data;
  }
  async getExecutionsByWorkflowId(
    workflowId: string,
    limit?: number,
    offset?: string,
    status?: string,
    beginTimeStamp?: number,
    endTimeStamp?: number,
    timeFilterProperty?: string
  ): Promise<GetExecutionsResult> {
    const response = await this.reportingClient.get(
      `/workflows/${workflowId}/executions`,
      {
        params: omitBy(
          {
            offset,
            status,
            limit: limit || 1000,
            beginTimeStamp,
            endTimeStamp,
            timeFilterProperty
          },
          isNil
        ),
        paramsSerializer: function(params) {
          return querystring.stringify(params, { encode: false });
        }
      }
    );
    return {
      executions: response.data?._embedded?.item,
      offset: response.data?.offset
    };
  }
  async continueExecution(executionId: string): Promise<void> {
    await this.coreClient.post(`/executions/${executionId}:continue`);
  }
  async terminateExecution(executionId: string): Promise<object> {
    const response = await this.coreClient.post(
      `/executions/${executionId}:terminate`
    );
    return response.data;
  }
}
