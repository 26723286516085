/* eslint-disable react/prop-types */

import { Label } from "@cimpress/react-components";
import { LabelStyleEnum } from "../../../utility";
import { Link, useLocation } from "react-router-dom";
import React from "react";

/**
 * Custom cell render for status column for react table
 * @param param0
 */
export function StatusCell({ original }: { original: { status: string } }) {
  return (
    <Label text={original.status} type={LabelStyleEnum[original.status]} />
  );
}

/**
 * Custom cell render component for executionId column for react table
 * @param param0 cell props
 */
export function ExecutionIdCell({ cellProps, breadcrumbItems, pathName }) {
  const original = cellProps.original;
  return (
    <Link
      to={{
        pathname: `${pathName}/executions/${original.executionId}`,
        state: { breadcrumbItems }
      }}
    >
      {original?.executionId}
    </Link>
  );
}

/**
 * Custom cell render for stepId column for react table
 * @param param0 cell props
 */
export function StepIdCell({
  cellProps,
  breadcrumbItems = null,
  execution = null,
  workflow = null,
  pathName
}) {
  const { original } = cellProps;
  return (
    <Link
      to={{
        pathname: `${pathName}/steps/${original?.stepId}`,
        state: {
          execution,
          breadcrumbItems,
          workflow
        }
      }}
    >
      {original?.stepId}
    </Link>
  );
}
