// styles for coloring  cells
export default {
  error: {
    // Ligt Red
    backgroundColor: "rgba(255, 0, 0, 0.1)"
  },

  warning: {
    /* Light Yellow */
    backgroundColor: "#ffff0047"
  }
};
