import ExecutionAPI from "./execution";
import WorkflowAPI from "./workflow";
import ActivityAPI from "./activity";
import EventAPI from "./event";
import MiscAPI from "./misc";
import { applyMixins } from "../utility";
import { AxiosInstance } from "axios";
import ClientFactory from "./HTTPClientFactory";

class MaestroAPI {
  protected readonly reportingClient: AxiosInstance;
  protected readonly coreClient: AxiosInstance;
  constructor(token: string, coreBaseUrl: string, reportingBaseUrl: string) {
    this.reportingClient = ClientFactory(reportingBaseUrl, token);
    this.coreClient = ClientFactory(coreBaseUrl, token);
  }
}

interface MaestroAPI
  extends ExecutionAPI,
    EventAPI,
    WorkflowAPI,
    ActivityAPI,
    MiscAPI {}

applyMixins(MaestroAPI, [
  ExecutionAPI,
  EventAPI,
  WorkflowAPI,
  ActivityAPI,
  MiscAPI
]);

export default MaestroAPI;
