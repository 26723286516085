import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { UpdatePropAction } from "../../common/reducer/actions";
import createReducer from "../../common/reducer";
import { handlers } from "../../common/reducer/handlers";

export const ManageWorkflowPageContext = React.createContext(null);

export interface ManageWorkflowProvider {
  state: ManageWorkflowPageState;
  dispatch: (action: UpdatePropAction) => void;
}

interface ManageWorkflowPageState {
  isMetadataValid;
  isPageValid;
}

const initialState: ManageWorkflowPageState = {
  isMetadataValid: true,
  isPageValid: true
};
/**
 * Context provider for json editor
 */
export function ManageWorkflowPageProvider(props) {
  const reducer = createReducer<ManageWorkflowPageState, UpdatePropAction>(
    initialState,
    handlers
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const value: ManageWorkflowProvider = {
    state,
    dispatch
  };

  return (
    <ManageWorkflowPageContext.Provider value={value}>
      {props.children}
    </ManageWorkflowPageContext.Provider>
  );
}

ManageWorkflowPageProvider.propTypes = {
  children: PropTypes.node.isRequired
};
