import React, { useState, useEffect, useContext, Dispatch } from "react";
import { FlexBox, Button, Alert } from "@cimpress/react-components";
import _ from "lodash";
import { useLocation, useParams, Link, Redirect } from "react-router-dom";
import Breadcrumb, {
  Props as BreadcrumbProps
} from "../../common/components/breadcrumb";
import WorkflowEditor from "./components/workflowEditor";
import { AppContext, AppContextValue } from "../../common/context";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import { StepsGraphProvider } from "./components/graph/context";
import { JSONEditorProvider } from "./components/jsoneditor/context";
import {
  HeaderActionResult,
  NULLVALUE,
  getWorkflowBuilderBreadcrumb,
  getEditWorkflowBreadcrumb
} from "../../common/utility";
import {
  getHomeBreadcrumb,
  getWorkflowIdBreadcrumb
} from "../../common/utility";
import WorkflowMetadata from "./components/metadata";
import { ManageWorkflowPageContext, ManageWorkflowProvider } from "./context";
import { WorkflowMetadataProvider } from "./components/metadata/context";
import useModals from "../../common/hooks/useModals";
import ViewDiffModal, {
  MODAL_NAME as VIEW_DIFF_MODAL_NAME
} from "./components/viewDiffModal";
import ShareCoamModal, {
  MODAL_NAME as SHARE_COAM_MODAL_NAME
} from "./components/shareCoamModal";
import PublishWorkflowModal, {
  MODAL_NAME as PUBLISH_WORKFLOW_MODAL_NAME
} from "./components/publishWorkflowModal";
import InvokeWorkflowModal, {
  MODAL_NAME as INVOKE_WORKFLOW_MODAL_NAME
} from "./components/invokeWorkflowModal";
import SaveWorkflowDraftModal, {
  MODAL_NAME as SAVE_WORKFLOW_DRAFT_MODAL_NAME
} from "./components/saveDraftModal";
import useTempWorkflow, {
  TempWorkflowContext
} from "../../common/hooks/useTempWorkflow";
import useLocalStorage from "../../common/hooks/useLocalStorage";
/* eslint-disable */
const colors = require("@cimpress/react-components/lib/colors");

/**
 * page header
 * @param label Label
 */
function Header({
  label,
  store,
  onStartExecution,
  onStartSandboxExecution,
  onShareUnshareWorkflow,
  setHeaderActionResult,
  setRedirectToWorkflowSummaryPage,
  isEditingWorkflow,
  fileName
}) {
  const { state } = useContext(
    ManageWorkflowPageContext
  ) as ManageWorkflowProvider;
  const tempWorkflowContext = useContext(TempWorkflowContext);
  const { originalWorkflow } = tempWorkflowContext.state;
  const { resetWorkflow } = tempWorkflowContext;
  const { hasChanges } = tempWorkflowContext;
  if (!isEditingWorkflow || hasChanges()) {
    onStartExecution = onStartSandboxExecution;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${colors.shale}`,
        paddingBottom: "0.3125em",
        marginBottom: "1em",
        fontSize: "1.625em"
      }}
    >
      {label}
      <div>
        <FlexBox right marginX="m">
          {/** Invoke Workflow */}
          <RunWorkflowButton
            store={store}
            isEditingWorkflow={isEditingWorkflow}
          />
          <InvokeWorkflowModal
            store={store}
            onStartExecution={onStartExecution}
          />
          {/** Version Diff */}
          {isEditingWorkflow
            ? [
                <Button
                  type="default"
                  onClick={() => {
                    store.toggleVisibility(VIEW_DIFF_MODAL_NAME);
                  }}
                >
                  Version Diff
                </Button>,
                <ViewDiffModal
                  store={store}
                  publishedWorkflow={originalWorkflow}
                />
              ]
            : null}
          {/** COAM Access */}
          {isEditingWorkflow
            ? [
                <Button
                  disabled={!state.isPageValid}
                  type="default"
                  onClick={() => store.toggleVisibility(SHARE_COAM_MODAL_NAME)}
                >
                  COAM Access
                </Button>,
                <ShareCoamModal
                  store={store}
                  onShareUnshareWorkflow={onShareUnshareWorkflow}
                />
              ]
            : null}
          {/** Save Draft */}
          {!isEditingWorkflow ? (
            <SaveWorkflowDraftButton store={store} />
          ) : null}
          {!isEditingWorkflow ? (
            <SaveWorkflowDraftModal store={store} fileName={fileName} />
          ) : null}
          <div
            style={{
              borderLeft: `1px solid ${colors.shale}`,
              borderRight: `1px solid ${colors.alloy}`
            }}
          />
          {/** Publish */}
          <PublishButton isEditingWorkflow={isEditingWorkflow} store={store} />
          <PublishWorkflowModal
            setRedirectToWorkflowSummaryPage={setRedirectToWorkflowSummaryPage}
            alert={setHeaderActionResult}
            originalWorkflow={originalWorkflow}
            resetWorkflow={resetWorkflow}
            isEditingWorkflow={isEditingWorkflow}
            store={store}
          />
        </FlexBox>
      </div>
    </div>
  );
}

function RunWorkflowButton({ store, isEditingWorkflow }) {
  const { hasChanges } = useContext(TempWorkflowContext);
  const runInSandbox = !isEditingWorkflow || hasChanges();
  const { state }: ManageWorkflowProvider = useContext(
    ManageWorkflowPageContext
  );
  return (
    <Button
      disabled={!state.isPageValid}
      type="default"
      onClick={() => {
        store.toggleVisibility(INVOKE_WORKFLOW_MODAL_NAME);
      }}
    >
      {runInSandbox ? "Run in Sandbox" : "Run"}
    </Button>
  );
}

function PublishButton({ store, isEditingWorkflow }) {
  const { state }: ManageWorkflowProvider = useContext(
    ManageWorkflowPageContext
  );

  const { hasChanges } = useContext(TempWorkflowContext);
  const disabled = isEditingWorkflow
    ? !state.isPageValid || !hasChanges()
    : false;
  return (
    <Button
      disabled={disabled}
      type="primary"
      onClick={() => {
        store.toggleVisibility(PUBLISH_WORKFLOW_MODAL_NAME);
      }}
    >
      Publish
    </Button>
  );
}

function SaveWorkflowDraftButton({ store }) {
  const { state }: ManageWorkflowProvider = useContext(
    ManageWorkflowPageContext
  );
  const disabled = !state.isPageValid;
  return (
    <Button
      disabled={disabled}
      type="primary"
      onClick={() => {
        store.toggleVisibility(SAVE_WORKFLOW_DRAFT_MODAL_NAME);
      }}
    >
      Save Draft
    </Button>
  );
}

function AlertMessage({ workflowId, execution }) {
  return (
    <div>
      Click{" "}
      <Link
        to={{
          pathname: `/workflows/${workflowId}/executions/${execution.executionId}`,
          state: { execution }
        }}
      >
        here
      </Link>{" "}
      to go to the execution.
    </div>
  );
}

function SandboxAlertMessage({ execution }) {
  return (
    <div>
      Click{" "}
      <Link
        target="_blank"
        to={{
          pathname: `/executions/${execution.executionId}`,
          state: { execution }
        }}
      >
        here
      </Link>{" "}
      to go to the execution, or view a{" "}
      <Link target="_blank" to="/workflows/anonymous">
        List
      </Link>{" "}
      of recent executions in the Anonymous Workflow page.
    </div>
  );
}

function AlertContent({
  isSharingWorkflow,
  headerActionResult,
  execution,
  isEditingWorkflow,
  workflowId,
  setHeaderActionResult
}) {
  const { hasChanges } = useContext(TempWorkflowContext);
  const redirectToSandboxPage = !isEditingWorkflow || hasChanges();
  //removes alert if there is a change
  useEffect(() => {
    if (headerActionResult) {
      setHeaderActionResult(null);
    }
  }, [redirectToSandboxPage]);
  return (
    <div>
      {isSharingWorkflow ? (
        <Spinner />
      ) : (
        <Alert
          title={headerActionResult?.title}
          type={headerActionResult?.succeeded ? "info" : "danger"}
          message={
            headerActionResult?.message ||
            (execution ? (
              redirectToSandboxPage ? (
                <SandboxAlertMessage execution={execution} />
              ) : (
                <AlertMessage workflowId={workflowId} execution={execution} />
              )
            ) : (
              <Spinner size="small" />
            ))
          }
          dismissible={true}
          dismissed={headerActionResult === null}
          onDismiss={() => {
            setHeaderActionResult(null);
          }}
        />
      )}
    </div>
  );
}
/**
 * step detail page
 */
function ManageWorkflow(props) {
  const { maestroClient, sandboxClient, anonymousWorkflowStorage } = useContext(
    AppContext
  ) as AppContextValue;
  const { workflowId } = useParams();
  const { state, dispatch } = useContext(
    ManageWorkflowPageContext
  ) as ManageWorkflowProvider;

  const [storageState, storage] = useLocalStorage(anonymousWorkflowStorage);
  let workflowFromState;

  // check if the parameters are passed as state from the previous page
  const location = useLocation();
  if (location.state) {
    workflowFromState = _.get(location.state, "workflow", null);
  }

  const provider = useTempWorkflow({
    workflow: workflowFromState || props.workflow,
    workflowId
  });

  const [isEditingWorkflow] = useState(workflowId ? true : false);
  const [isSharingWorkflow, setIsSharingWorkflow] = useState(false);
  const [breadcrumbs, setBreadcrumbs]: [
    BreadcrumbProps,
    Dispatch<BreadcrumbProps>
  ] = useState({
    items: []
  });

  const [headerActionResult, setHeaderActionResult] = useState<
    HeaderActionResult
  >(null);
  const [execution, setExecution] = useState(null);

  // redirect to edit page is set when the user successfully published their workflow draft
  const [redirect, setRedirect] = useState({
    shouldRedirect: false,
    to: null
  });

  const modals = useModals();
  /**
   * function executed when Start execution button is clicked
   */
  function onStartExecution(executionInput) {
    try {
      if (executionInput) {
        setExecution(null);
        maestroClient
          .startWorkflow(workflowId, JSON.parse(executionInput))
          .then(data => setExecution(data))
          .catch(error => console.log(error));
        setHeaderActionResult(
          new HeaderActionResult(
            true,
            NULLVALUE,
            "New execution request submitted. "
          )
        );
      } else {
        alert("Please insert an input for the execution to run.");
      }
    } catch (error) {
      setHeaderActionResult(
        new HeaderActionResult(false, (<b>Error in starting Execution.</b>))
      );
    }
  }

  async function setExecutionOnLocalStorage(data) {
    await storage.getAllItems().then(async res => {
      if (res.length !== 0) {
        const length = res[0].value.length;
        if (length == 10) {
          await storage.popItem("executionDetail", res[0].value[0]);
        }
      }
    });
    const storedExecution = await storage.getItem("executionDetail");
    if (storedExecution.value !== null) {
      await storage.pushItem("executionDetail", data);
    } else {
      await storage.setItem("executionDetail", [data]);
    }
  }
  function onStartSandboxExecution(executionInput) {
    try {
      const workflowDraft = _.pick(
        provider.state.tempWorkflow,
        "contact",
        "name",
        "steps",
        "onError",
        "start",
        "timeout",
        "callbackUrl"
      );
      setExecution(null);
      sandboxClient
        .startWorkflowAnonymous(workflowDraft, JSON.parse(executionInput))
        .then(async data => {
          setExecution(data);
          setExecutionOnLocalStorage(data);
        })
        .catch(error => console.log(error));
      setHeaderActionResult(
        new HeaderActionResult(
          true,
          NULLVALUE,
          "New execution request submitted. The execution will run in a sandbox environment. "
        )
      );
    } catch (error) {
      setHeaderActionResult(
        new HeaderActionResult(false, (<b>Error in starting Execution.</b>))
      );
    }
  }

  /**
   * function to be called when share/unshare workflow is done
   * @param groupId - group Id
   * @param option - share/ unshare option
   */
  async function onShareUnshareWorkflow(groupId: string, option: string) {
    setIsSharingWorkflow(true);
    try {
      if (option === "share") {
        await maestroClient.shareWorkflow(workflowId, groupId);
      } else {
        await maestroClient.unshareWorkflow(workflowId, groupId);
      }
      setHeaderActionResult(
        new HeaderActionResult(
          true,
          (
            <>
              Workflow <b>{provider.state.originalWorkflow.name}</b> has been{" "}
              {`${option}d`} with{" "}
              <b>{groupId === "public" ? "all" : `group ${groupId}`}</b>
            </>
          )
        )
      );
    } catch (error) {
      setHeaderActionResult(
        new HeaderActionResult(false, "Error in sharing/ unsharing workflow.")
      );
    } finally {
      setIsSharingWorkflow(false);
    }
  }

  /**
   * todo: add validation for the workflow editor (json editor + steps graph)
   */
  useEffect(() => {
    const isValid = state.isMetadataValid;

    dispatch({ type: "updateProp", path: "isPageValid", value: isValid });
  }, [state.isMetadataValid]);

  /**
   * populate breadcrumbs
   */
  useEffect(() => {
    if (isEditingWorkflow) {
      setBreadcrumbs({
        items: [
          getHomeBreadcrumb(),
          getWorkflowIdBreadcrumb(
            workflowId,
            _.get(provider.state.originalWorkflow, "name", "?")
          ),
          getEditWorkflowBreadcrumb(workflowId)
        ]
      });
    } else {
      setBreadcrumbs({
        items: [getHomeBreadcrumb(), getWorkflowBuilderBreadcrumb()]
      });
    }
  }, [provider.state.originalWorkflow]);

  if (provider.state.isFetchingWorkflow || !provider.state.tempWorkflow) {
    return <Spinner fullPage={true} />;
  }

  if (redirect.shouldRedirect) {
    return <Redirect to={redirect.to} />;
  }

  return (
    <TempWorkflowContext.Provider value={provider}>
      {/** vertical layout */}
      {/** Breadcrumb */}
      <Breadcrumb {...breadcrumbs} />
      {/** header */}
      <Header
        store={modals}
        label={isEditingWorkflow ? "Manage Workflow" : "Build Workflow"}
        onShareUnshareWorkflow={onShareUnshareWorkflow}
        onStartExecution={onStartExecution}
        onStartSandboxExecution={onStartSandboxExecution}
        setHeaderActionResult={setHeaderActionResult}
        setRedirectToWorkflowSummaryPage={setRedirect}
        isEditingWorkflow={isEditingWorkflow}
        fileName={props.fileName}
      />
      <AlertContent
        isSharingWorkflow={isSharingWorkflow}
        workflowId={workflowId}
        headerActionResult={headerActionResult}
        execution={execution}
        isEditingWorkflow={isEditingWorkflow}
        setHeaderActionResult={setHeaderActionResult}
      />
      {/** body */}
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/** metadata */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minWidth: "450px"
          }}
        >
          <WorkflowMetadataProvider>
            <WorkflowMetadata />
          </WorkflowMetadataProvider>
        </div>
        {/** workflow editor and graph */}
        <StepsGraphProvider>
          <JSONEditorProvider>
            <WorkflowEditor />
          </JSONEditorProvider>
        </StepsGraphProvider>
      </div>
    </TempWorkflowContext.Provider>
  );
}

export default ManageWorkflow;
