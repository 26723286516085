import React from "react";
import PropTypes from "prop-types";
// import styles from "./index.module.css";
import { LabelStyleEnum } from "../../utility";
import { Label } from "@cimpress/react-components";
import TimeStamp from "../timeStamp";

const Metadata = ({ title, data, headerStyle = {}, bodyStyle = {} }) => {
  return (
    <div>
      <div
        style={{
          fontSize: "large",
          ...headerStyle
        }}
      >
        {title}
      </div>
      <div
        style={{
          width: "18.75em",
          paddingTop: "1em",
          overflow: "auto",
          ...bodyStyle
        }}
      >
        <ul style={{ listStyle: "none" }}>
          {Object.keys(data).map((key, index) => {
            return (
              <li
                key={index}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                <h5>{key}</h5>
                {key === "Status" ? (
                  <Label text={data[key]} type={LabelStyleEnum[data[key]]} />
                ) : ["Created At", "Modified At", "Process On"].includes(
                    key
                  ) ? (
                  <TimeStamp date={data[key]} />
                ) : (
                  data[key]
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Metadata;

Metadata.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  headerStyle: PropTypes.object,
  bodyStyle: PropTypes.object
};
