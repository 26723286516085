import { get } from "lodash";
import localForage from "localforage";
const stage: string = process.env.REACT_APP_STAGE;
const scheme = stage === "local" ? "http" : "https";
const locationIdMap = {
  "100": "us-east-1",
  "200": "eu-west-1"
};
const config = {
  applicationName: "Maestro",
  stage,
  api: {
    pollIntervalMs: 15 * 1000,
    coreBaseUrl: `${scheme}://${stage}.core.api.genericworkflows.cimpress.io/v2`,
    reportingBaseUrl: `${scheme}://${stage}.reporting.api.genericworkflows.cimpress.io/v2`,
    getCoreBaseUrlWithRegion: (locationId: string) => {
      return `${scheme}://${get(
        locationIdMap,
        locationId,
        "local"
      )}.${stage}.core.api.genericworkflows.cimpress.io/v2`;
    }
  },
  sandboxApi: {
    pollIntervalMs: 15 * 1000,
    coreBaseUrl: `${scheme}://staging.core.api.genericworkflows.cimpress.io/v2`,
    reportingBaseUrl: `${scheme}://staging.reporting.api.genericworkflows.cimpress.io/v2`,
    getCoreBaseUrlWithRegion: (locationId: string) => {
      return `${scheme}://${get(
        locationIdMap,
        locationId,
        "local"
      )}.staging.core.api.genericworkflows.cimpress.io/v2`;
    }
  },

  localForage: {
    driver: localForage.LOCALSTORAGE,
    // TODO: Add database for user preferences
    databases: {
      workflowTags: {
        /**
         * returns name for workflowTags database
         */
        getName: (user: string) =>
          `${config.applicationName}-workflowTags-${user}`
      }
    }
  }
};

export default config;
