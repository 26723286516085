import React from "react";
import { Button } from "@cimpress/react-components";
import { Views } from "../../";
import IconLayers from "@cimpress-technology/react-streamline-icons/lib/IconLayers";
import PropTypes from "prop-types";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("@cimpress/react-components/lib/colors");

/**
 * body component in the workflow builder modal
 * displayed when the user clicks the 'change storage' button
 */
export function StorageView() {
  return (
    <Button
      style={{
        width: "8.5em",
        height: "8.5em"
      }}
    >
      <IconLayers style={{ width: "5em", height: "5em" }} />
      <div style={{ fontSize: "small", fontWeight: "bold" }}>Device</div>
    </Button>
  );
}

/**
 * Footer component in the workflow builder modal
 * displayed when the user clicks the 'change storage' button
 */
export function StorageViewFooter({ setView }) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        borderTop: `1px solid ${colors.shale}`,
        paddingTop: "1em"
      }}
    >
      <a style={{ cursor: "pointer" }} onClick={() => setView(Views.HOME_VIEW)}>
        Back
      </a>
    </div>
  );
}

StorageViewFooter.propTypes = {
  setView: PropTypes.func
};
