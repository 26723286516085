import React, { useContext, useState, useEffect } from "react";
import "./WorkflowOverviewStepTable.css";
import CardSelectHeader from "../cardSelectHeader";
import { WorkflowContext } from "../../../common/context";
import { Table, Card } from "@cimpress/react-components";
import { AppContext, AppContextValue } from "../../../common/context";
import {
  getTimeStamp,
  dropDownItems,
  TimeStampEnum
} from "../../../common/utility";
import NoDataComponent from "../../../common/components/noDataComponent";
import StepSummaryCell from "./cells/stepSummaryCell";
import { Status } from "../../../common/utility/index";
import styles from "./cells/index.css";
/**
 * Helper function for rendering a StepSummaryCell
 * @param param0
 * @param beginTimeStamp
 */
function SummaryCellWrapper(
  {
    value,
    original,
    column
  }: {
    value: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    original: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    column: any;
  },
  beginTimeStamp: number
) {
  return (
    <StepSummaryCell
      beginTimeDate={new Date(beginTimeStamp)}
      value={value}
      status={column?.Header}
      stepName={original.stepName}
    ></StepSummaryCell>
  );
}

/**
 * Renders WorkflowOverview step table
 * @param props
 */
export default function WorkflowOverviewStepTable(props) {
  const { workflowId } = useContext(WorkflowContext);
  const appCtx: AppContextValue = useContext(AppContext);
  const [stepSummary, setStepSummary] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [selectedOption, setSelectedOption] = useState(dropDownItems[3]);
  const timeStampMs = getTimeStamp(TimeStampEnum[selectedOption.value]);

  const cellRenderer = props => SummaryCellWrapper(props, timeStampMs);

  const tableColumns = [
    {
      Header: "Step",
      accessor: "stepName",
      filterable: true,
      style: { overflow: "hidden", textOverflow: "ellipsis" }
    },
    {
      Header: "Created",
      accessor: "status.Created",
      Cell: cellRenderer
    },
    {
      Header: "Running",
      accessor: "status.Running",
      Cell: cellRenderer
    },
    {
      Header: "Aborted",
      accessor: "status.Aborted",
      Cell: cellRenderer
    },
    {
      Header: "Failed",
      accessor: "status.Failed",
      Cell: cellRenderer
    },
    {
      Header: "Terminated",
      accessor: "status.Terminated",
      Cell: cellRenderer
    },
    {
      Header: "Completed",
      accessor: "status.Completed",
      Cell: cellRenderer
    }
  ];

  useEffect(() => {
    setIsFetching(true);
    appCtx.maestroClient
      .getStepsSummary(workflowId, timeStampMs)
      .then(data => {
        setStepSummary(data);
      })
      .catch(error => console.log(error))
      .finally(() => setIsFetching(false));
  }, [selectedOption, workflowId]);

  return (
    <div id="workflowStepTable">
      <Card
        className="tableContainer"
        header={CardSelectHeader(
          "Step Status",
          selectedOption,
          setSelectedOption,
          dropDownItems
        )}
      >
        <Table
          columns={tableColumns}
          data={stepSummary}
          loading={isFetching}
          showPagination={false}
          pageSize={stepSummary.length}
          NoDataComponent={NoDataComponent}
          getTdProps={
            /**
             * Applies styling to table cells based on status
             */
            (_state, rowInfo, column) => {
              const status = column.Header;
              const numberOfStepsInStatus = rowInfo?.original?.status[status];
              if (numberOfStepsInStatus <= 0) {
                return {};
              }
              if (Status.Failed == status || Status.Terminated == status) {
                return {
                  style: styles.error
                };
              } else if (Status.Aborted == status) {
                return {
                  style: styles.warning
                };
              } else return {};
            }
          }
        />
      </Card>
    </div>
  );
}
