/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, Dispatch } from "react";
import Modal from "../../../../common/components/modal";
import "jsoneditor/dist/jsoneditor.css";
import { Button } from "@cimpress/react-components";
import DiffViewer from "../../../../common/components/diffViewer";
import { AppContext, AppContextValue } from "../../../../common/context";
import { useParams } from "react-router";
import "./index.css";
import PropTypes from "prop-types";
import { ModalStore } from "../../../../common/hooks/useModals";
import { HeaderActionResult } from "../../../../common/utility";
import _ from "lodash";
import { TempWorkflowContext } from "../../../../common/hooks/useTempWorkflow";

export const MODAL_NAME = "publishWorkflowModal";

/**
 * component which returns the modal title
 */
function Title() {
  return (
    <div>
      <div>Preview Workflow Changes</div>
    </div>
  );
}

/**
 * Modal footer props
 */
interface FooterProps {
  store: ModalStore;
  onPublish: () => void;
}
/**
 * component which returns the modal footer
 */
function Footer({ store, onPublish }: FooterProps) {
  return (
    <div>
      <Button
        onClick={() => {
          store.toggleVisibility(MODAL_NAME);
        }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={() => onPublish()}>
        Publish
      </Button>
    </div>
  );
}

Footer.propTypes = {
  store: PropTypes.object,
  onPublish: PropTypes.func
};

/**
 * Publish workflow modal props
 */
interface PublishWorkflowModalProps {
  originalWorkflow: object;
  store: ModalStore;
  isEditingWorkflow: boolean;
  resetWorkflow: (state: any) => void;
  setRedirectToWorkflowSummaryPage: Dispatch<any>;
  alert: Dispatch<HeaderActionResult>;
}

/**
 * Component which renders the Publish Workflow Modal
 */
export default function PublishWorkflowModal({
  alert,
  originalWorkflow,
  resetWorkflow,
  isEditingWorkflow,
  setRedirectToWorkflowSummaryPage,
  store
}: PublishWorkflowModalProps) {
  const { workflowId } = useParams();
  const { tempWorkflow: draftWorkflow } = useContext(TempWorkflowContext).state;
  const { maestroClient } = useContext(AppContext) as AppContextValue;

  /**
   * Publishes a new workflow, then redirects to the workflow's summary page
   */
  async function publishNewWorkflow() {
    alert(new HeaderActionResult(true, "Validating workflow..."));
    try {
      await maestroClient.validateWorkflow(draftWorkflow);
      alert(
        new HeaderActionResult(
          true,
          "Workflow Validated. Publishing Workflow..."
        )
      );
      const newWorkflow = await maestroClient.createWorkflow(draftWorkflow);
      const workflowId = _.get(newWorkflow, "workflowId", null);
      alert(
        new HeaderActionResult(
          true,
          `New Workflow ${workflowId} published successfully`
        )
      );
      setRedirectToWorkflowSummaryPage({
        shouldRedirect: true,
        to: `/workflows/${workflowId}`
      });
    } catch (error) {
      console.error(error);
      alert(
        new HeaderActionResult(
          false,
          error.toString(),
          "Failed to publish workflow"
        )
      );
    }
  }

  /**
   * Publishes a new workflow version
   */
  async function publishNewWorkflowVersion() {
    try {
      const currentVersion: number = _.get(draftWorkflow, "version", null);
      alert(
        new HeaderActionResult(
          true,
          `Publishing workflow version ${currentVersion + 1}...`
        )
      );
      const newWorkflow = await maestroClient.updateWorkflow(
        workflowId,
        draftWorkflow
      );
      resetWorkflow(newWorkflow);
      const newVersion = _.get(newWorkflow, "version", null);
      alert(
        new HeaderActionResult(
          true,
          `Workflow version ${newVersion} published successfully`
        )
      );
    } catch (error) {
      console.error(error);
      alert(
        new HeaderActionResult(
          false,
          error.toString(),
          "Failed to publish new workflow version"
        )
      );
    }
  }

  /**
   * Handles the publish event
   */
  async function onPublish() {
    store.toggleVisibility(MODAL_NAME);
    if (isEditingWorkflow) {
      await publishNewWorkflowVersion();
    } else {
      await publishNewWorkflow();
    }
  }

  return (
    <Modal
      store={store}
      id={MODAL_NAME}
      options={{
        bsStyle: "info",
        title: <Title />,
        closeButton: true,
        className: "modal-container",
        footer: <Footer store={store} onPublish={onPublish} />,
        style: { width: "50%" }
      }}
    >
      <DiffViewer leftJson={originalWorkflow} rightJson={draftWorkflow} />
    </Modal>
  );
}

PublishWorkflowModal.propTypes = {
  store: PropTypes.object,
  originalWorkflow: PropTypes.object,
  setOriginalWorkflow: PropTypes.func,
  setRedirectToEditPage: PropTypes.func,
  alert: PropTypes.func
};
