import { omitBy, isNil } from "lodash";
import { AxiosInstance } from "axios";

export interface GetActivitiesResult {
  activities: Array<object>;
  offset?: string;
}

export default class ActivityAPI {
  protected readonly reportingClient: AxiosInstance;
  protected readonly coreClient: AxiosInstance;
  constructor(coreClient: AxiosInstance, reportingClient: AxiosInstance) {
    this.coreClient = coreClient;
    this.reportingClient = reportingClient;
  }
  async getActivitiesByExecutionId(
    executionId: string,
    limit?: number,
    offset?: string,
    status?: string,
    beginTimeStamp?: number,
    endTimeStamp?: number
  ): Promise<GetActivitiesResult> {
    const response = await this.reportingClient.get(
      `/executions/${executionId}/activities`,
      {
        params: omitBy(
          {
            limit: limit || 1000,
            offset,
            status,
            beginTimeStamp,
            endTimeStamp
          },
          isNil
        )
      }
    );
    return {
      activities: response.data?._embedded?.item,
      offset: response.data?.offset
    };
  }
  async getActivityByExecutionId(
    executionId: string,
    activityId: string
  ): Promise<object> {
    const response = await this.reportingClient.get(
      `/executions/${executionId}/activities/${activityId}`
    );
    return response.data;
  }
  /**
   * Get steps by workflowId
   * @param workflowId
   * @param stepName filter for specific step name
   * @param limit
   * @param offset
   * @param status
   * @param beginTimeStamp
   * @param endTimeStamp
   * @param timeFilterProperty filter timestamps on createdMs or modifiedMs
   */
  async getStepsByWorkflowId(
    workflowId: string,
    stepName?: string,
    limit?: number,
    offset?: string,
    status?: string,
    beginTimeStamp?: number,
    endTimeStamp?: number,
    timeFilterProperty?: string
  ): Promise<GetActivitiesResult> {
    const response = await this.reportingClient.get(
      `/workflows/${workflowId}/steps`,
      {
        params: omitBy(
          {
            stepName,
            limit: limit || 1000,
            offset,
            status,
            beginTimeStamp,
            endTimeStamp,
            timeFilterProperty
          },
          isNil
        )
      }
    );
    return {
      activities: response.data?._embedded?.item,
      offset: response.data?.offset
    };
  }

  async updateActivity(
    executionId: string,
    activityId: string,
    output = {}
  ): Promise<object> {
    const response = await this.coreClient.post(
      `/executions/${executionId}/activities/${activityId}:update`,
      output
    );
    return response.data;
  }
  async retryActivity(
    executionId: string,
    activityId: string
  ): Promise<object> {
    const response = await this.coreClient.post(
      `/executions/${executionId}/activities/${activityId}:retry`
    );
    return response.data;
  }
  async completeActivity(
    executionId: string,
    activityId: string,
    output = {}
  ): Promise<object> {
    const response = await this.coreClient.post(
      `/executions/${executionId}/activities/${activityId}:complete`,
      output
    );
    return response.data;
  }
  async failActivity(
    executionId: string,
    activityId: string,
    output = {}
  ): Promise<object> {
    const response = await this.coreClient.post(
      `/executions/${executionId}/activities/${activityId}:fail`,
      output
    );
    return response.data;
  }
  async terminateActivity(
    executionId: string,
    activityId: string,
    output = {}
  ): Promise<object> {
    const response = await this.coreClient.post(
      `/executions/${executionId}/activities/${activityId}:terminate`,
      output
    );
    return response.data;
  }
}
