/**
 * Configures and exports centralizedAuth module
 */
import { centralizedAuth } from "@cimpress/simple-auth-wrapper";

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  emitInitialTokenExpired: false
});

export default auth;
