import moment from "moment";
const ANONYMOUS_WORKFLOW_ID = "anonymous";
const ANONYMOUS_WORKFLOW_NAME = "Anonymous Workflow";
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Merge mixin classes into a composite class
 * @param derivedCtor
 * @param baseCtors
 */
export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      Object.defineProperty(
        derivedCtor.prototype,
        name,
        Object.getOwnPropertyDescriptor(baseCtor.prototype, name)
      );
    });
  });
}

/**
 * Extracts a locationId from an executionID
 * @param executionId
 */
export function extractLocationID(executionId: string) {
  return executionId.split("~")[1];
}

export enum TimeStampEnum {
  oneHour = "oneHour",
  sixHours = "sixHours",
  twelveHours = "twelveHours",
  oneDay = "oneDay",
  oneWeek = "oneWeek"
}

export function getTimeStamp(value: TimeStampEnum) {
  let timeStamp;
  switch (value) {
    case TimeStampEnum.oneHour: {
      timeStamp = moment()
        .subtract(1, "hour")
        .valueOf();
      break;
    }
    case TimeStampEnum.sixHours: {
      timeStamp = moment()
        .subtract(6, "hour")
        .valueOf();
      break;
    }
    case TimeStampEnum.twelveHours: {
      timeStamp = moment()
        .subtract(12, "hour")
        .valueOf();
      break;
    }
    case TimeStampEnum.oneDay: {
      timeStamp = moment()
        .subtract(1, "day")
        .valueOf();
      break;
    }
    case TimeStampEnum.oneWeek: {
      timeStamp = moment()
        .subtract(7, "day")
        .valueOf();
      break;
    }
  }
  return timeStamp;
}

export const dropDownItems = [
  { label: "1 hour", isDisabled: false, value: TimeStampEnum.oneHour },
  { label: "6 hours", isDisabled: false, value: TimeStampEnum.sixHours },
  { label: "12 hours", isDisabled: false, value: TimeStampEnum.twelveHours },
  { label: "1 day", isDisabled: false, value: TimeStampEnum.oneDay },
  { label: "1 week", isDisabled: false, value: TimeStampEnum.oneWeek }
];

export enum LabelStyleEnum {
  Completed = "success",
  Failed = "danger",
  Aborted = "warning",
  Running = "primary",
  Terminated = "danger",
  Created = "primary"
}

export class HeaderActionResult {
  public succeeded: boolean;
  public message: string | JSX.Element;
  public title: string;

  constructor(
    succeeded: boolean,
    message: string | JSX.Element,
    title?: string
  ) {
    this.succeeded = succeeded;
    this.message = message;
    this.title = title;
  }
}

export enum Status {
  Completed = "Completed",
  Failed = "Failed",
  Aborted = "Aborted",
  Running = "Running",
  Terminated = "Terminated",
  Created = "Created"
}

export const ActivityStatuses = [
  "Completed",
  "Failed",
  "Aborted",
  "Running",
  "Terminated",
  "Created"
];

export const ExecutionStatuses = [
  "Completed",
  "Failed",
  "Aborted",
  "Running",
  "Terminated"
];

export const NULLVALUE = null;

export const LOCAL_TIMEZONE = "local";

export const UTC_TIMEZONE = "utc";
/**
 * Converts dates into unix time stamps ms
 * @param startDate
 * @param endDate
 */
export function convertDateRangeToTimeStamp(
  startDate: Date,
  endDate: Date,
  timeZone: string
): { beginTimeStamp?: number; endTimeStamp?: number; timeZone?: string } {
  let timeStamp;

  //if the timeZone is UTC, manipulation must done to the timestamp to get the equivalent UTC timestamp, since Date is Local time
  if (timeZone === LOCAL_TIMEZONE) {
    timeStamp = {
      beginTimeStamp: startDate ? moment(startDate).valueOf() : NULLVALUE,
      endTimeStamp: endDate ? moment(endDate).valueOf() : NULLVALUE
    };
  } else {
    timeStamp = {
      beginTimeStamp: startDate
        ? moment(startDate).valueOf() - new Date().getTimezoneOffset() * 60000
        : NULLVALUE,
      endTimeStamp: endDate
        ? moment(endDate).valueOf() - new Date().getTimezoneOffset() * 60000
        : NULLVALUE
    };
  }
  return timeStamp;
}

export function getHomeBreadcrumb() {
  return { path: "/workflows", name: "Home" };
}

export function getWorkflowBuilderBreadcrumb() {
  return { path: "/workflows/build", name: "Build" };
}

export function getWorkflowIdBreadcrumb(workflowId, workflowName) {
  if (workflowId) {
    return {
      path: `/workflows/${workflowId}`,
      name:
        workflowId == ANONYMOUS_WORKFLOW_ID
          ? ANONYMOUS_WORKFLOW_NAME
          : workflowName
    };
  }
}

export function getAnonymousWorkflowBreadcrumb() {
  return {
    path: "/workflows/anonymous",
    name: ANONYMOUS_WORKFLOW_NAME
  };
}

export function getViewWorkflowDataBreadcrumb(workflowId) {
  return {
    path: `/workflows/${workflowId}/viewWorkflowData`,
    name: "View Data"
  };
}

export function getEditWorkflowBreadcrumb(workflowId) {
  return {
    path: `/workflows/${workflowId}/edit`,
    name: "Edit"
  };
}

export function getExecutionIdBreadcrumb(workflowId, executionId) {
  return {
    path: workflowId
      ? `/workflows/${workflowId}/executions/${executionId}`
      : `/executions/${executionId}`,
    name: `${executionId}`
  };
}

export function getStepIdBreadcrumb(workflowId, executionId, stepId) {
  return {
    path: workflowId
      ? `/workflows/${workflowId}/executions/${executionId}/steps/${stepId}`
      : `/executions/${executionId}/steps/${stepId}`,
    name: `${stepId}`
  };
}

export function getEventIdFromStepBreadcrumb(
  workflowId,
  executionId,
  stepId,
  eventId
) {
  return {
    path: workflowId
      ? `/workflows/${workflowId}/executions/${executionId}/steps/${stepId}/events/${eventId}`
      : `/executions/${executionId}/steps/${stepId}/events/${eventId}`,
    name: `${eventId}`
  };
}

export function getEventIdFromExecutionBreadcrumb(
  workflowId,
  executionId,
  eventId
) {
  return {
    path: workflowId
      ? `/workflows/${workflowId}/executions/${executionId}/events/${eventId}`
      : `/executions/${executionId}/events/${eventId}`,
    name: `${eventId}`
  };
}
/**
 * Object that is stored in local storage when tagging workflows
 */
export interface WorkflowTagEntry {
  workflowId: string;
  workflowName: string;
}

//NOTE : if changing this BatchRetryableStatuses value, please make sure to add the changes in batchRetryWorker.ts file
export const BatchRetryableStatuses = ["Aborted", "Failed", "Created"];

/**
 * Returns the path to a resource in the public folder
 * @param path path to append to public folder
 */
export function getPathFromPublic(path): string {
  return `${process.env.PUBLIC_URL}/${path}`;
}
