import React from "react";
import MaestroClient from "../api";
/**
 * This file contains React Context objects that can be used in components that provide or consume the context data
 */
export const AppContext = React.createContext(null);

export const WorkflowContext = React.createContext(null);

export const TimeZoneContext = React.createContext(null);

export interface AppContextValue {
  auth: any /* eslint-disable-line */;
  maestroClient: MaestroClient;
  sandboxClient: MaestroClient;
  workflowTagsStorage: LocalForage;
  anonymousWorkflowStorage: LocalForage;
  batchRetryWorker: Worker;
}

export interface WorkflowContextValue {
  workflowId: string;
  workflow?: any;
  breadcrumbItems?: Array<object>;
}
