/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { FlexBox, Button } from "@cimpress/react-components";
import { ModalStore } from "../../../hooks/useModals";
import { TAG_WORKFLOW_MODAL_ID } from "../tagWorkflowModal";
interface WorkflowSummaryHeaderProps {
  workflow: any;
  hideTagAction?: boolean;
  store: ModalStore;
  durationDropDown: JSX.Element;
}

// eslint-disable-next-line react/prop-types
export default function WorkflowSummaryHeader(
  props: WorkflowSummaryHeaderProps
) {
  return (
    <FlexBox middle spaceBetween left marginX="m">
      <div style={{ flex: 1 }}>
        <h2>{props.workflow.name}</h2>
      </div>
      <Button
        type="default"
        onClick={() => props.store.toggleVisibility(TAG_WORKFLOW_MODAL_ID)}
      >
        Tag
      </Button>
      {props.durationDropDown}
    </FlexBox>
  );
}
