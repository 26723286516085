import React, { useEffect, useContext, useReducer } from "react";
import { TextField, FlexBox } from "@cimpress/react-components";
import _ from "lodash";
import moment, { Duration } from "moment";
import IconPencilAlt from "@cimpress-technology/react-streamline-icons/lib/IconPencilAlt";
import IconCheckSquare from "@cimpress-technology/react-streamline-icons/lib/IconCheckSquare";
import { WorkflowMetadataContext, MetadataProvider } from "../metadata/context";
import PropTypes from "prop-types";
import createReducer from "../../../../common/reducer";
import { handlers } from "../../../../common/reducer/handlers";
import { TempWorkflowContext } from "../../../../common/hooks/useTempWorkflow";

interface State {
  duration: string;
  days: TextFieldState;
  hours: TextFieldState;
  minutes: TextFieldState;
  seconds: TextFieldState;
  isValid: boolean;
  isEditing: boolean;
}

interface TextFieldState {
  value: string;
  min: string;
  max: string;
  isValid: boolean;
}

/**
 * Utility to view and edit the timeout value in the manage workflow page
 *
 * Following contexts are needed:
 * TempWorkflowContext - to get and update the timeout value in the TempWorkflowProvider
 * WorkflowMetadataContext - context surrounding the workflow metadata. Used for validations
 */
export default function DurationPicker() {
  const { tempWorkflow } = useContext(TempWorkflowContext).state;

  const duration: Duration = moment.duration(tempWorkflow.timeout || null);
  const initialState: State = {
    duration: null,
    days: {
      value: _.get(duration, "_days", "0"),
      min: "0",
      max: "119",
      isValid: true
    },
    hours: {
      value: duration.hours().toString() || "0",
      min: "0",
      max: "23",
      isValid: true
    },
    minutes: {
      value: duration.minutes().toString() || "0",
      min: "0",
      max: "59",
      isValid: true
    },
    seconds: {
      value: duration.seconds().toString() || "0",
      min: "0",
      max: "59",
      isValid: true
    },
    isValid: true,
    isEditing: false
  };

  const reducer = createReducer(initialState, handlers);
  const [state, dispatch] = useReducer(reducer, initialState);
  const setState = (path, value) =>
    dispatch({ type: "updateProp", path, value });

  return (
    <div>
      {state.isEditing ? (
        <EditableDurationPicker state={state} setState={setState} />
      ) : (
        <ReadOnlyDurationPicker
          editButton={<EditButton setState={setState} />}
          duration={tempWorkflow.timeout}
        />
      )}
    </div>
  );
}

/**
 * Button to display the timeout field duration picker
 */
function EditButton({ setState }) {
  return (
    <button
      onClick={() => {
        setState("isEditing", true);
      }}
      className="btn btn-default"
    >
      <IconPencilAlt />
    </button>
  );
}
EditButton.propTypes = {
  setState: PropTypes.func
};

/**
 * Component for displaying the timeout duration in ISO format
 * @param param0
 */
function ReadOnlyDurationPicker({ editButton, duration }) {
  return (
    <TextField
      label="Timeout"
      readOnly
      value={duration}
      rightAddon={editButton}
      helpText="Denotes the duration before the execution is timed out. ISO8601 format."
    />
  );
}
ReadOnlyDurationPicker.propTypes = {
  editButton: PropTypes.element,
  duration: PropTypes.string
};

/**
 * Component for editing the timeout field
 * @param props
 */
function EditableDurationPicker({ state, setState }) {
  const { updateProp: updateWorkflowProp } = useContext(TempWorkflowContext);
  const metadata = useContext(WorkflowMetadataContext) as MetadataProvider;

  const { days, hours, minutes, seconds } = state;

  function updateTextFieldState(
    propName: "days" | "hours" | "minutes" | "seconds",
    inputField: any /* eslint-disable-line */
  ) {
    const min = inputField.min;
    const max = inputField.max;
    const value = inputField.value;
    const isValid =
      value && value >= parseInt(min, 10) && value <= parseInt(max, 10);

    // update duration picker state
    setState(propName, {
      min,
      max,
      value,
      isValid
    });
  }

  /**
   * Updates the metadata provider isTimeoutValid property
   * if the duration component valid state changes
   */
  useEffect(() => {
    metadata.dispatch({
      type: "updateProp",
      path: "isTimeoutValid",
      value: state.isValid
    });
  }, [state.isValid]);

  /**
   * Checks if all the text fields in the duration picker component are valid.
   * Update the duration ISO string if text fields are valid.
   */
  useEffect(() => {
    const valid =
      days.isValid && hours.isValid && minutes.isValid && seconds.isValid;

    setState("isValid", valid);

    // update the duration ISO string that is displayed in read only mode
    if (valid) {
      setState(
        "duration",
        moment
          .duration({
            days: days.value,
            hours: hours.value,
            minutes: minutes.value,
            seconds: seconds.value
          })
          .toISOString()
      );
    }
  }, [days, hours, minutes, seconds]);

  return (
    <div style={{ flexGrow: 1 }}>
      {/** duration picker text fields */}
      <FlexBox>
        <TextField
          style={{ flexGrow: 1 }}
          type="number"
          label="days"
          step="1"
          min={days.min}
          max={days.max}
          value={days.value}
          bsStyle={days.isValid ? null : "error"}
          onChange={event => updateTextFieldState("days", event.target)}
        ></TextField>
        <TextField
          style={{ flexGrow: 1 }}
          type="number"
          label="hours"
          step="1"
          min={hours.min}
          max={hours.max}
          value={hours.value}
          bsStyle={hours.isValid ? null : "error"}
          onChange={event => updateTextFieldState("hours", event.target)}
        ></TextField>
        <TextField
          style={{ flexGrow: 1 }}
          type="number"
          label="minutes"
          step="1"
          min={minutes.min}
          max={minutes.max}
          value={minutes.value}
          bsStyle={minutes.isValid ? null : "error"}
          onChange={event => updateTextFieldState("minutes", event.target)}
        ></TextField>
        <TextField
          style={{ flexGrow: 1 }}
          type="number"
          label="seconds"
          step="1"
          min={seconds.min}
          max={seconds.max}
          value={seconds.value}
          bsStyle={seconds.isValid ? null : "error"}
          onChange={event => updateTextFieldState("seconds", event.target)}
        ></TextField>
        {/** apply changes button */}
        <span>
          <button
            disabled={!state.isValid}
            onClick={() => {
              setState("isEditing", false);
              updateWorkflowProp("timeout", state.duration);
            }}
            className="btn btn-default"
            style={{ height: "48px", width: "64px", padding: "0px" }}
          >
            <IconCheckSquare />
          </button>
        </span>
      </FlexBox>
    </div>
  );
}
EditableDurationPicker.propTypes = {
  state: PropTypes.object,
  setState: PropTypes.func
};
