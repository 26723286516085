import React, { useContext, useEffect, useState } from "react";
import { TextField, SelectWrapper } from "@cimpress/react-components";
import Select from "react-select";
import "./index.css";

import DurationPicker from "../durationPicker";
import {
  ManageWorkflowPageContext,
  ManageWorkflowProvider
} from "../../context";
import { MetadataProvider, WorkflowMetadataContext } from "./context";
import { TempWorkflowContext } from "../../../../common/hooks/useTempWorkflow";
import _ from "lodash";

function WorkflowMetadata() {
  const page = useContext(ManageWorkflowPageContext) as ManageWorkflowProvider;
  const tempWorkflowContext = useContext(TempWorkflowContext);
  const { tempWorkflow: workflow } = tempWorkflowContext.state;
  const {
    updateProp: updateWorkflowProp,
    deleteProp: deleteWorkflowProp,
    validateTempWorkflow
  } = tempWorkflowContext;
  const { state } = useContext(WorkflowMetadataContext) as MetadataProvider;

  /**
   * todo: add validation for other metadata fields
   */
  useEffect(() => {
    const valid = state.isTimeoutValid;

    page.dispatch({
      type: "updateProp",
      path: "isMetadataValid",
      value: valid
    });
  }, [state.isTimeoutValid]);

  /**
   * Update the onError dropdown selection
   */
  const [onErrorList, setOnErrorList] = useState(null);
  useEffect(() => {
    const onErrorList = [
      {
        value: null,
        label: "None"
      },
      ...Object.entries(workflow.steps)
        .map(([stepName]) => {
          return { value: stepName, label: stepName };
        })
        .filter(option => option.label !== workflow.start)
    ];
    setOnErrorList(onErrorList);
  }, [workflow.start]);

  const debouncedValidate = _.debounce(validateTempWorkflow, 1000);
  useEffect(() => {
    debouncedValidate();
    return () => debouncedValidate.cancel();
  }, [workflow.contact, workflow.start]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: "450px",
        maxWidth: "650px"
      }}
    >
      <h2 style={{ marginBottom: "1em" }}>Workflow Metadata</h2>
      <TextField
        style={{ padding: "0em 1em" }}
        required
        label="Name"
        value={workflow.name}
        helpText="Name of the workflow"
        onChange={event => updateWorkflowProp("name", event.target.value)}
      />
      <TextField
        style={{ padding: "0em 1em" }}
        required
        label="Contact Email"
        value={workflow.contact}
        helpText="Contact email for workflow owner."
        onChange={event => updateWorkflowProp("contact", event.target.value)}
      />
      <TextField
        style={{ padding: "0em 1em" }}
        label="Callback URL"
        value={workflow.callbackUrl}
        helpText="Endpoint to which the workflow must post the final output of an execution."
        onChange={event =>
          updateWorkflowProp("callbackUrl", event.target.value)
        }
      />
      <div style={{ padding: "0em 1em" }}>
        <SelectWrapper
          selectedSelect={Select}
          label="start"
          value={workflow.start}
          options={Object.keys(workflow.steps).map(stepName => {
            return { value: stepName, label: stepName };
          })}
          onChange={selected => updateWorkflowProp("start", selected.value)}
          helpText="Name of the first step that is to be executed."
        />
      </div>
      <div style={{ padding: "0em 1em" }}>
        <SelectWrapper
          selectedSelect={Select}
          label="onError"
          value={workflow.onError}
          options={onErrorList}
          onChange={selected => {
            if (!selected.value) {
              deleteWorkflowProp("onError");
            } else {
              updateWorkflowProp("onError", selected.value);
            }
          }}
          helpText="Name of the step to move to on any error status, Failed or Aborted. Each step can override this property."
        />
      </div>
      <div style={{ padding: "1em" }}>
        <DurationPicker />
      </div>
    </div>
  );
}

export default WorkflowMetadata;
