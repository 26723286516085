import React, { useContext, useState } from "react";
import Modal from "../../../../common/components/modal";
import "jsoneditor/dist/jsoneditor.css";
import { Button, TextField } from "@cimpress/react-components";
import DiffViewer from "../../../../common/components/diffViewer";
import PropTypes from "prop-types";
import { ModalStore } from "../../../../common/hooks/useModals";
import { TempWorkflowContext } from "../../../../common/hooks/useTempWorkflow";
import { saveAs } from "file-saver";
import "./index.css";
import { WorkflowDraft } from "../../../workflowBuilder";

export const MODAL_NAME = "saveWorkflowDraftModal";
/**
 * component which returns the modal title
 */
function Title() {
  return (
    <div>
      <div>Save Workflow Draft</div>
    </div>
  );
}

/**
 * Modal footer props
 */
interface FooterProps {
  store: ModalStore;
  onSave: () => void;
}
/**
 * component which returns the modal footer
 */
function Footer({ store, onSave }: FooterProps) {
  return (
    <div>
      <Button
        onClick={() => {
          store.toggleVisibility(MODAL_NAME);
        }}
      >
        Cancel
      </Button>
      <Button type="primary" onClick={() => onSave()}>
        Save
      </Button>
    </div>
  );
}

Footer.propTypes = {
  store: PropTypes.object,
  onSave: PropTypes.func
};

/**
 * Save workflow draft modal props
 */
interface SaveWorkflowDraftModalProps {
  fileName: string;
  store: ModalStore;
}

/**
 * Component which renders the Save workflow draft modal
 */
export default function SaveWorkflowDraftModal(
  props: SaveWorkflowDraftModalProps
) {
  const { originalWorkflow, tempWorkflow } = useContext(
    TempWorkflowContext
  ).state;
  const [fileName, setFileName] = useState(props.fileName);

  /**
   * Handles save event
   */
  function onSave() {
    const workflowDraft: WorkflowDraft = {
      workflow: tempWorkflow
    };
    const serializedWorkflowDraft = JSON.stringify(workflowDraft);

    const blob = new Blob([serializedWorkflowDraft], {
      type: "application/json"
    });
    saveAs(blob, fileName);
    props.store.toggleVisibility(MODAL_NAME);
  }

  return (
    <Modal
      store={props.store}
      id={MODAL_NAME}
      options={{
        bsStyle: "info",
        title: <Title />,
        closeButton: true,
        className: "save-workflow-draft-modal-container",
        footer: <Footer store={props.store} onSave={onSave} />,
        style: { width: "50%" }
      }}
    >
      <TextField
        required
        label="Filename"
        value={fileName}
        helpText="Note: to change the default download location, see your browser/device settings."
        onChange={event => setFileName(event.target.value)}
      />
      <DiffViewer leftJson={originalWorkflow} rightJson={tempWorkflow} />
    </Modal>
  );
}

SaveWorkflowDraftModal.propTypes = {
  store: PropTypes.object,
  fileName: PropTypes.string
};
