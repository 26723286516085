import React, { useState, useContext, useEffect } from "react";
import "./viewDiffModal.css";
import { FlexBox, Select } from "@cimpress/react-components";
import DiffViewer from "../../../../common/components/diffViewer";
import _ from "lodash";
import { useParams } from "react-router";
import { AppContext, AppContextValue } from "../../../../common/context";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import Modal from "../../../../common/components/modal";
import { ModalStore } from "../../../../common/hooks/useModals";
import { TempWorkflowContext } from "../../../../common/hooks/useTempWorkflow";

//id of the viewDiffModal
export const MODAL_NAME = "viewDiffModal";

/**
 * This component renders the diff view modal
 * store - store where all modals are registered
 * publishedWorkflow - the last published workflow
 */
export default function ViewDiffModal({
  store,
  publishedWorkflow
}: {
  store: ModalStore;
  publishedWorkflow: object;
}) {
  const { workflowId } = useParams();
  const { tempWorkflow } = useContext(TempWorkflowContext).state;
  const { maestroClient } = useContext(AppContext) as AppContextValue;
  const [versionOptions, setVersionOptions] = useState(null);
  const [selectedLeftVersion, setSelectedLeftVersion] = useState(null);
  const [selectedRightVersion, setSelectedRightVersion] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [workflows, setWorkflows] = useState(null);
  const latestOptions = {
    label: "Draft",
    value: "draft",
    workflowJson: tempWorkflow
  };

  //whenever a workflow is published, it gets the workflow versions
  useEffect(() => {
    maestroClient
      .getWorkflowVersions(workflowId)
      .then(workflowVersionsData => {
        workflowVersionsData = _.orderBy(
          workflowVersionsData,
          "version",
          "asc"
        );
        setWorkflows(workflowVersionsData);
      })
      .catch(error => console.log(error));
  }, [publishedWorkflow]);

  //when the workflows changes, the versionOptions are chnages
  useEffect(() => {
    if (workflows) {
      const tempVersionOptions = workflows.map(workflow => {
        return {
          label: workflow.version,
          value: workflow.version,
          workflowJson: workflow
        };
      });
      setVersionOptions(tempVersionOptions);
    }
  }, [workflows]);

  //when there is change in edited workflow or versionOptions, the selected left and right version is set
  useEffect(() => {
    setSelectedRightVersion(latestOptions);
    setSelectedLeftVersion(_.last(versionOptions));
  }, [versionOptions, tempWorkflow]);

  //once everything is loading, the spinner is set to false
  useEffect(() => {
    if (selectedLeftVersion && selectedRightVersion) {
      setIsLoading(false);
    }
  }, [selectedLeftVersion, selectedRightVersion]);

  /**
   * change event to set the right json based on the version selected
   * @param event - onChange event of left dropdown
   */
  function onLeftVersionChange(event) {
    setSelectedLeftVersion(event);
  }

  /**
   * change event to set the right json based on the version selected
   * @param event - onChange event of right dropdown
   */
  function onRightVersionChange(event) {
    setSelectedRightVersion(event);
  }

  /**
   * resetting the left and right versions when closing the modal
   */
  function onCloseModal() {
    setSelectedRightVersion(latestOptions);
    setSelectedLeftVersion(_.last(versionOptions));
    store.toggleVisibility(MODAL_NAME);
  }
  return (
    <Modal
      store={store}
      id={"viewDiffModal"}
      options={{
        bsStyle: "info",
        onRequestHide: onCloseModal,
        closeOnOutsideClick: true,
        closeButton: true,
        title: "View Diff",
        style: { width: "50%" },
        className: "diffModal"
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <FlexBox center>
            <div className="diffViewerWidth">
              <Select
                label="Version"
                value={selectedLeftVersion}
                options={versionOptions}
                className="diffViewerWidth"
                onChange={onLeftVersionChange}
              />
            </div>
            <div className="diffViewerWidth">
              <Select
                label="Version"
                value={selectedRightVersion}
                options={versionOptions.concat(latestOptions)}
                className="diffViewerWidth"
                onChange={onRightVersionChange}
              />
            </div>
          </FlexBox>
          <div className="diffViewerHeight">
            <DiffViewer
              leftJson={selectedLeftVersion.workflowJson}
              rightJson={selectedRightVersion.workflowJson}
            />
          </div>
        </div>
      )}
    </Modal>
  );
}
