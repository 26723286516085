import React from "react";
import { Link, useParams } from "react-router-dom";

interface StepSummaryCellProps {
  stepName: string;
  status: string;
  beginTimeDate: Date;
  value: number;
}

/**
 * Renders StepSummaryCell
 * @param props
 */
export default function StepSummaryCell(props: StepSummaryCellProps) {
  const { stepName, status, beginTimeDate, value } = props;
  const { workflowId } = useParams();
  const linkToParams = {
    pathname: `/workflows/${workflowId}/viewWorkflowData`,
    state: {
      status,
      beginTimeDate,
      stepName
    }
  };
  return (
    <div>
      <Link to={linkToParams}>{value}</Link>
    </div>
  );
}
