import React from "react";
import styles from "./index.module.css";
import { FourOhFour } from "@cimpress/react-components";
import { Link } from "react-router-dom";

const DEFAULT_MESSAGE =
  "Oops! Something has gone wrong and the page you were looking for could not be displayed. ";
interface Props {
  message: string;
}
/**
 * page header
 * @param props
 */
export default function FourOhFourNotFound(props: Props) {
  return (
    <div className={styles.fourOhFourContainer}>
      <FourOhFour
        message={
          <div>
            {props.message ? props.message : DEFAULT_MESSAGE} Try the{" "}
            <Link to={"/"}>homepage</Link>.
          </div>
        }
      />
    </div>
  );
}
