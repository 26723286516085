import { AxiosInstance } from "axios";
import { omit, pick, omitBy, isNil } from "lodash";

export interface GetExecutionsSummaryResult {
  status: {
    Completed: number;
    Failed: number;
    Aborted: number;
    Terminated: number;
    Running: number;
  };
  _total: number;
}
export interface GetStepsSummaryResult {
  stepName: string;
  status: {
    Completed: number;
    Failed: number;
    Running: number;
    Aborted: number;
    Terminated: number;
  };
  _total: 1;
}

export default class WorkflowAPI {
  protected readonly reportingClient: AxiosInstance;
  protected readonly coreClient: AxiosInstance;
  constructor(coreClient: AxiosInstance, reportingClient: AxiosInstance) {
    this.coreClient = coreClient;
    this.reportingClient = reportingClient;
  }
  async getWorkflow(workflowId: string, version = 0) {
    const response = await this.coreClient.get(`/workflows/${workflowId}`, {
      params: {
        version
      }
    });
    return omit(response.data, "_links");
  }

  async deleteWorkflow(workflowId: string) {
    const response = await this.coreClient.delete(`/workflows/${workflowId}`);
    return response.data;
  }

  async createWorkflow(workflowDefinition: object): Promise<object> {
    const response = await this.coreClient.post("/workflows", {
      workflow: workflowDefinition
    });
    return response.data;
  }

  async updateWorkflow(
    workflowId: string,
    workflowDefinition: object
  ): Promise<object> {
    const fields = [
      "name",
      "contact",
      "start",
      "steps",
      "callbackUrl",
      "timeout",
      "onError"
    ];

    const response = await this.coreClient.put(`/workflows/${workflowId}`, {
      workflow: pick(workflowDefinition, fields)
    });
    return omit(response.data, "_links");
  }

  async getWorkflows(): Promise<Array<object>> {
    const response = await this.coreClient.get("/workflows");
    return response.data?._embedded?.item;
  }

  /**
   * Gets the specific version of the workflow
   * @param workflowId - workflow ID
   * @param version - version of workflow
   */
  async getWorkflowVersion(workflowId: string, version: number) {
    const response = await this.coreClient.get(
      `/workflows/${workflowId}?version=${version}`
    );
    return omit(response.data, "_links");
  }

  /**
   * Gets the workflow of the given workflowId
   * @param workflowId - workflow ID
   */
  async getWorkflowVersions(workflowId: string): Promise<Array<any>> {
    const response = await this.coreClient.get(
      `/workflows/${workflowId}/versions`
    );
    const workflowVersions = response.data?._embedded?.item;
    const detailedWorkflowVersionsPromises = workflowVersions
      .filter(versionJson => versionJson.version != 0)
      .map(workflowVersion =>
        this.getWorkflowVersion(workflowId, workflowVersion.version)
      );
    // eslint-disable-next-line
    const detailedWorkflowVersions = await Promise.all(
      detailedWorkflowVersionsPromises
    );
    return detailedWorkflowVersions;
  }

  async startWorkflowAnonymous(
    workflowDefinition: object,
    input: object
  ): Promise<object> {
    const response = await this.coreClient.post("/workflows:start", {
      workflow: workflowDefinition,
      input
    });
    return response.data;
  }
  async startWorkflow(
    workflowId: string,
    input: object = {},
    params = {}
  ): Promise<object> {
    const response = await this.coreClient.post(
      `/workflows/${workflowId}:start`,
      { input, params }
    );
    return response.data;
  }
  async restoreWorkflow(workflowId: string, version: number): Promise<object> {
    const response = await this.coreClient.post(
      `/workflows/${workflowId}:restore`,
      null,
      {
        params: {
          version
        }
      }
    );
    return response.data;
  }

  /**
   *
   * @param workflowId - ID of workflow to be shared
   * @param groupId - groupId to be shared with or public (in case the workflow need to be shared with all)
   */
  async shareWorkflow(workflowId: string, groupId: string) {
    let params;
    if (groupId.toLowerCase() === "public") {
      params = { public: true };
    } else {
      params = { groupId };
    }
    const response = await this.coreClient.post(
      `/workflows/${workflowId}:share`,
      null,
      { params }
    );
    return response.data;
  }

  /**
   *
   * @param workflowId - ID of workflow to be unshared
   * @param groupId - groupId to be unshared with or public (in case the workflow need to be shared with all)
   */
  async unshareWorkflow(workflowId: string, groupId: string) {
    let params;
    if (groupId.toLowerCase() === "public") {
      params = { public: true };
    } else {
      params = { groupId };
    }
    const response = await this.coreClient.post(
      `/workflows/${workflowId}:unshare`,
      null,
      { params }
    );
    return response.data;
  }

  async validateWorkflow(workflowDefinition: object) {
    const response = await this.coreClient.post("/workflows:validate", {
      workflow: workflowDefinition
    });
    return response.data;
  }

  async getExecutionsSummary(
    workflowId: string,
    beginTimeStamp?: number,
    endTimeStamp?: number
  ): Promise<GetExecutionsSummaryResult> {
    const response = await this.reportingClient.get(
      `/workflows/${workflowId}/executionsSummary`,
      {
        params: omitBy(
          {
            beginTimeStamp,
            endTimeStamp
          },
          isNil
        )
      }
    );
    return response.data;
  }

  async getStepsSummary(
    workflowId: string,
    beginTimeStamp?: number,
    endTimeStamp?: number
  ): Promise<Array<GetStepsSummaryResult>> {
    const response = await this.reportingClient.get(
      `/workflows/${workflowId}/stepsSummary`,
      {
        params: omitBy(
          {
            beginTimeStamp,
            endTimeStamp
          },
          isNil
        )
      }
    );
    return response.data?._embedded?.item;
  }
}
