import React, { useRef, useEffect, useState } from "react";
import Modal from "../../../../common/components/modal";
import JSONEditor, { JSONEditorOptions } from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import styles from "./index.module.css";
import PropTypes from "prop-types";
import { ModalStore } from "../../../../common/hooks/useModals";

export const MODAL_NAME = "invokeWorkflowModal";

/**
 * component which returns the modal title
 */
function ModalTitle() {
  return (
    <div>
      <div>Execute the workflow</div>
      <ol className={styles["invoke-workflow-help"]}>
        <li>Enter workflow input JSON into the text box below.</li>
        <li>
          Click {"'"}Start execution{"'"} to instantiate a new workflow
          execution.
        </li>
      </ol>
    </div>
  );
}

/**
 * Publish workflow modal props
 */
interface InvokeWorkflowModalProps {
  store: ModalStore;
  onStartExecution: (json: string) => void;
}

/**
 * Component which renders the Invoke Workflow Modal
 * @param props - contains the function to be executed on start execution button click
 */
export default function InvokeWorkflowModal(props: InvokeWorkflowModalProps) {
  const store = props.store;
  const [editor, setEditor] = useState<JSONEditor>(null);
  const inputEditorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options: JSONEditorOptions = {
      modes: ["code"],
      mainMenuBar: false,
      statusBar: false
    };
    const tempEditor = new JSONEditor(inputEditorRef.current, options);
    setEditor(tempEditor);

    return () => {
      tempEditor.destroy();
    };
  }, [store.isVisible(MODAL_NAME)]);

  return (
    <Modal
      store={store}
      id={MODAL_NAME}
      options={{
        bsStyle: "info",
        closeOnOutsideClick: true,
        title: <ModalTitle />,
        closeButton: true
      }}
    >
      <div className="workflow-start">
        <div
          className={styles["jsonEditorContainer"]}
          ref={inputEditorRef}
          style={{
            width: "90%",
            height: "400px",
            overflow: "hidden",
            border: "0px"
          }}
        ></div>
        <br />
        <a
          className="btn btn-default workflow-start__run"
          href="#"
          onClick={() => {
            store.toggleVisibility(MODAL_NAME);
            props.onStartExecution(editor.getText());
          }}
        >
          Start execution
        </a>
      </div>
    </Modal>
  );
}

InvokeWorkflowModal.propTypes = {
  onStartExecution: PropTypes.func.isRequired,
  store: PropTypes.object
};
