import React, { useState } from "react";
import {
  TextField,
  FlexBox,
  Button,
  RadioGroup,
  Radio
} from "@cimpress/react-components";
import Modal from "../../../../common/components/modal";
import { ModalStore } from "../../../../common/hooks/useModals";

//the id for share coam modal for regidtering the modal
export const MODAL_NAME = "shareCoamModalId";

/**
 * Renders the share / unshare workflow component
 * @param store - store where the modals are registered
 * @param onShareUnshareWorkflow - function to be called when button is clicked
 */
export default function ShareCoamModal({
  store,
  onShareUnshareWorkflow
}: {
  store: ModalStore;
  onShareUnshareWorkflow: Function;
}) {
  const [coamId, setCoamId] = useState(null);
  const [option, setOption] = useState("share");

  /**
   * To be implemented whenever the modal is closed
   */
  function onCloseModal() {
    setCoamId("");
    setOption("share");
  }

  return (
    <Modal
      store={store}
      id={MODAL_NAME}
      options={{
        bsStyle: "info",
        onRequestHide: () => {
          onCloseModal();
        },
        closeOnOutsideClick: true,
        closeButton: true,
        title: "Share / Unshare workflow"
      }}
    >
      <FlexBox isVertical marginY="l" spaceBetween>
        <form>
          <RadioGroup
            onChange={(event, value) => {
              setOption(value);
            }}
            inline
            defaultSelected={option}
            key={option}
            name="shareUnshare"
          >
            <Radio label="Share" value="share" />
            <Radio label="Unshare" value="unshare" />
          </RadioGroup>
        </form>
        <FlexBox middle marginX="l">
          <label style={{ fontSize: "17px" }}>COAM Group Id</label>
          <TextField
            autoComplete="off"
            name="coamGroupId"
            label="COAM group"
            value={coamId}
            onChange={event => setCoamId(event.target.value)}
            helpText={
              <>
                Enter <b>Public</b> if you need to share/ unshare with all{" "}
              </>
            }
            style={{ marginLeft: "5%" }}
          />
        </FlexBox>
        <div style={{ marginLeft: "20%" }}>
          <Button
            type="primary"
            disabled={coamId ? false : true}
            onClick={() => {
              onShareUnshareWorkflow(coamId, option);
              onCloseModal();
              store.toggleVisibility(MODAL_NAME);
            }}
          >
            Submit
          </Button>
        </div>
      </FlexBox>
    </Modal>
  );
}
