import React, { useState, useEffect, useContext } from "react";
import { Card, Dropdown, FlexBox, Tag } from "@cimpress/react-components";
import ExecutionsSummaryChart from "./executionsSummaryChart";
import {
  getTimeStamp,
  dropDownItems,
  TimeStampEnum,
  getHomeBreadcrumb
} from "../../utility/";
import "./WorkflowSummary.css";
import { Link } from "react-router-dom";
import _ from "lodash";
import { AppContext, AppContextValue } from "../../context";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import { Props as BreadcrumbProps } from "../breadcrumb";
import WorkflowSummaryHeader from "./workflowSummaryHeader";
import useModals from "../../hooks/useModals";
import TagWorkflowModal from "./tagWorkflowModal";
import useLocalStorage from "../../hooks/useLocalStorage";
import { WorkflowTagEntry } from "../../../common/utility";
import styles from "./index.module.css";
export interface Props {
  workflow: any;
}
/**
 * WorkflowSummary component, function to create a card component which has the workflow summary
 * contains a DropDown Component and a BarGraph Component
 * @param {workflow}
 */

const WorkflowSummary = ({ workflow }: Props) => {
  const [isFetching, setIsFetching] = useState(true);
  const [tags, setTags] = useState([]);
  const [timeFilter, setTimeFilter] = useState(dropDownItems[3]);
  const [summaryData, setSummaryData] = useState(null);
  const appCtx: AppContextValue = useContext(AppContext);
  const [storageState, storage] = useLocalStorage(appCtx.workflowTagsStorage);
  const modalStore = useModals();
  const workflowId = _.get(workflow, "workflowId", null);

  /**
   * This handles the click event of the time period Drop Down
   * @param element
   */
  const onDropdownSelect = element => {
    const timeFilterSelected = element.target.name;
    const selectedItemIndex = dropDownItems.findIndex(
      item => item.label === timeFilterSelected
    );
    setTimeFilter(dropDownItems[selectedItemIndex]);
  };

  const fetchSummaryData = () => {
    const timeStampMs = getTimeStamp(TimeStampEnum[timeFilter.value]);
    appCtx.maestroClient
      .getExecutionsSummary(workflowId, timeStampMs)
      .then(executionsSummary => {
        setSummaryData(executionsSummary);
      })
      .catch(e => console.error(e))
      .finally(() => {
        setIsFetching(false);
      });
  };

  /**
   * When the workflowId changes (when we go to the next page)
   * or when a timeFilter is selected get summary data
   */
  useEffect(() => {
    setIsFetching(true);
    fetchSummaryData();
  }, [workflowId, timeFilter]);

  /**
   * Get Tags whenever workflowId changes or a change is made to storageState
   */
  useEffect(() => {
    storage
      .getKeysByValue(workflowId, "workflowId")
      .then(keys => {
        setTags(keys);
      })
      .catch(console.error);
  }, [workflowId, storageState]);

  const breadcrumbs: BreadcrumbProps = {
    items: [getHomeBreadcrumb()]
  };

  return (
    <div style={{ width: "70%", marginTop: "1.5em" }}>
      <TagWorkflowModal
        workflow={workflow}
        store={modalStore}
        storageHandler={storage}
        storageState={storageState}
      />
      <Card
        header={
          <WorkflowSummaryHeader
            workflow={workflow}
            store={modalStore}
            durationDropDown={
              <Dropdown title={timeFilter.label}>
                {dropDownItems.map((item, index) => {
                  return (
                    <button
                      onClick={onDropdownSelect}
                      id={workflowId}
                      key={index}
                      name={item.label}
                      disabled={item.label === timeFilter.label ? true : false}
                    >
                      {item.label}
                    </button>
                  );
                })}
              </Dropdown>
            }
          />
        }
        style={{ fontWeight: "bolder" }}
      >
        <FlexBox top>
          <div style={{ flex: 1, flexBasis: "16em" }}>
            <FlexBox
              isVertical
              middle
              center
              spaceBetween
              marginX="l"
              marginY="xl"
            >
              <div className={styles.cardBodyHeadingLabel}>Workflow ID</div>
              <div>
                <Link
                  to={{
                    pathname: `/workflows/${workflowId}`,
                    state: { breadcrumbItems: breadcrumbs.items }
                  }}
                >
                  {workflowId}
                </Link>
              </div>
            </FlexBox>
          </div>
          <div style={{ flex: 1 }}>
            <FlexBox isVertical middle spaceBetween marginY="l">
              <div className={styles.cardBodyHeadingLabel}> Tags</div>
              <div>
                {tags.map(x => (
                  <div style={{ marginTop: "0.5em" }} key={x}>
                    <Tag
                      value={x}
                      removable
                      onRemoveClick={() => {
                        storage
                          .popItem(x, {
                            workflowId,
                            workflowName: workflow.name
                          } as WorkflowTagEntry)
                          .catch(console.error);
                      }}
                    />
                  </div>
                ))}
              </div>
            </FlexBox>
          </div>
          <div style={{ flex: 2 }}>
            <FlexBox isVertical middle center spaceBetween marginY="l">
              <div className={styles.cardBodyHeadingLabel}>Executions</div>
              <div style={{ flex: 1 }}>
                {isFetching ? (
                  <Spinner size="large" />
                ) : (
                  <ExecutionsSummaryChart summaryData={summaryData} />
                )}
              </div>
            </FlexBox>
          </div>
        </FlexBox>
      </Card>
    </div>
  );
};

export default WorkflowSummary;
