import React from "react";

interface StepNameFilterProps {
  stepName: string;
  setStepName: Function;
  stepNames: Array<string>;
}

const SHOW_ALL_STEPS_VALUE = "__all__";

/**
 * the component for step name filter
 * @param props
 */
export default function StepNameFilter(props: StepNameFilterProps) {
  const { stepName, setStepName, stepNames } = props;
  return (
    <div>
      <select
        style={{ border: "0px" }}
        name="status"
        value={stepName == null ? SHOW_ALL_STEPS_VALUE : stepName}
        onChange={e => {
          const value = e.target.value;
          setStepName(value === SHOW_ALL_STEPS_VALUE ? null : value);
        }}
      >
        <option value={SHOW_ALL_STEPS_VALUE} key={"all"}>
          Show All
        </option>
        {stepNames.map(value => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}
