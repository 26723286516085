import {
  DiagramModel,
  NodeModel,
  LinkModel
} from "@projectstorm/react-diagrams";
import dagre from "dagre";

const size = {
  width: 60,
  height: 60
};

/**
 * returns formatted nodes for dagre graph
 * @param nodes
 */
export function mapNodes(nodes: NodeModel[]) {
  return nodes.map(node => ({
    id: node.getID(),
    metadata: { ...size, id: node.getID() }
  }));
}

/**
 * returns formatted edges for dagre graph
 * @param nodes
 */
export function mapLinks(links: LinkModel[]) {
  return links.map(link => ({
    from: link
      .getSourcePort()
      .getParent()
      .getID(),
    to: link
      .getTargetPort()
      .getParent()
      .getID()
  }));
}

/**
 * Translates DigramModel nodes and links to dagre graphib nodes and edges so that we can
 * auto-distribute the graph elements
 *
 * @param model DigramModel
 */
export function distributeGraph(model: DiagramModel) {
  const nodes = mapNodes(model.getNodes());
  const edges = mapLinks(model.getLinks());
  const graph = new dagre.graphlib.Graph();

  // top to bottom graph view
  graph.setGraph({ rankdir: "TB" });
  graph.setDefaultEdgeLabel(() => ({}));

  // add nodes to dagre graph
  nodes.forEach(node => {
    graph.setNode(node.id, node.metadata);
  });

  // add edges
  edges.forEach(edge => {
    if (edge.from && edge.to) {
      graph.setEdge(edge.from, edge.to);
    }
  });

  //auto-distribute
  dagre.layout(graph);
  return graph;
}

/**
 * Updates the positioning of the graph elements in the diagram model using dagre js library
 *
 * @param model DigramModel containing graph elements to render via react-diagrams engine
 */
export function distributeModel(model: DiagramModel) {
  const digramModelNodes = model.getNodes();
  const graph = distributeGraph(model);

  digramModelNodes.forEach(node => {
    const graphNode = graph.node(node.getID());
    node.setPosition(graphNode.x, graphNode.y);
  });
}
