import { AxiosInstance } from "axios";

export default class MiscAPI {
  protected readonly reportingClient: AxiosInstance;
  protected readonly coreClient: AxiosInstance;
  constructor(coreClient: AxiosInstance, reportingClient: AxiosInstance) {
    this.coreClient = coreClient;
    this.reportingClient = reportingClient;
  }
  async getWorkflowSchema(): Promise<object> {
    const response = await this.coreClient.get("/schema");
    return response.data;
  }
  async getRegistry(): Promise<Array<object>> {
    const response = await this.coreClient.get("/registry");
    return response.data?._embedded?.item;
  }
}
