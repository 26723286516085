import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { merge } from "lodash";
/**
 * Creates an HTTP client with supplied configuration
 * @param baseUrl baseUrl for requests
 * @param token Bearer JWT Authorization token
 * @param httpClientConfig  additional configuration parameters
 */
export default function ClientFactory(
  baseURL: string,
  token: string,
  httpClientConfig: AxiosRequestConfig = {}
): AxiosInstance {
  merge(httpClientConfig, {
    baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json"
    }
  });
  return axios.create(httpClientConfig);
}
