import React, { useState, useRef, useEffect } from "react";
import Modal from "../../../../common/components/modal";
import { ModalStore } from "../../../../common/hooks/useModals";
import "./index.css";
import JSONEditor, { JSONEditorOptions } from "jsoneditor";
import { HomeView, HomeViewFooter } from "./views/home";
import {
  CreateNewWorkflowView,
  CreateNewWorkflowViewFooter
} from "./views/createNewWorkflow";
import { DEFAULT_WORKFLOW } from "./views/createNewWorkflow";
import { StorageView, StorageViewFooter } from "./views/storage";
import PropTypes from "prop-types";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("@cimpress/react-components/lib/colors");

export const MODAL_NAME = "workflowBuilderWizardModal";

export enum Views {
  HOME_VIEW = "homeView",
  STORAGE_VIEW = "storageView",
  CREATE_NEW_WORKFLOW_VIEW = "createNewWorkflowView"
}

/**
 * Publish workflow modal props
 */
interface WorkflowBuilderWizardProps {
  store: ModalStore;
}

/**
 * JSON editor to display a preview of the workflow to use
 */
function ReactJSONEditor({ jsonEditorRef, workflow }) {
  useEffect(() => {
    const options: JSONEditorOptions = {
      modes: ["preview"],
      enableTransform: false,
      enableSort: false,
      statusBar: false
    };

    const editor = new JSONEditor(jsonEditorRef.current, options);
    editor.set(workflow);
    return () => editor.destroy();
  }, [workflow]);
  return <div />;
}

ReactJSONEditor.propTypes = {
  jsonEditorRef: PropTypes.any,
  workflow: PropTypes.any
};

/**
 * component which returns the modal title
 */
function Title() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        borderBottom: `1px solid ${colors.shale}`,
        paddingBottom: "0.3125em"
      }}
    >
      <div>Workflow Builder Wizard</div>
    </div>
  );
}

/**
 * Workflow builder modal that walks the user through options to create
 * a workflow from scratch or from a template. Additional features include:
 * - save/load workflow drafts
 * - change storage option
 */
export default function WorkflowBuilderWizard(
  props: WorkflowBuilderWizardProps
) {
  const [view, setView] = useState<string>(Views.HOME_VIEW);
  const [_selectedWorkflow, _setSelectedWorkflow] = useState<{ workflow: any }>( // eslint-disable-line @typescript-eslint/no-explicit-any
    DEFAULT_WORKFLOW
  );
  const jsonEditorRef = useRef<HTMLDivElement>(null);

  const modalContent = {
    [Views.HOME_VIEW]: {
      showReactJsonView: false,
      body: <HomeView store={props.store} setView={setView} />,
      footer: <HomeViewFooter setView={setView} />
    },
    [Views.STORAGE_VIEW]: {
      showReactJsonView: false,
      body: <StorageView />,
      footer: <StorageViewFooter setView={setView} />
    },
    [Views.CREATE_NEW_WORKFLOW_VIEW]: {
      container: "workflow-builder-wizard-modal-container-lg",
      showReactJsonView: true,
      header: <div />,
      body: (
        <CreateNewWorkflowView setSelectedWorkflow={_setSelectedWorkflow} />
      ),
      footer: (
        <CreateNewWorkflowViewFooter
          selectedWorkflow={_selectedWorkflow}
          store={props.store}
          setView={setView}
        />
      )
    }
  };

  return (
    <Modal
      store={props.store}
      id={MODAL_NAME}
      options={{
        bsStyle: "info",
        closeOnOutsideClick: true,
        closeButton: true,
        title: modalContent[view].header ?? <Title />,
        footer: modalContent[view].footer,
        className:
          modalContent[view].container ??
          "workflow-builder-wizard-modal-container",
        onRequestHide: () => {
          setView(Views.HOME_VIEW);
        }
      }}
    >
      <div
        style={{
          display: "flex"
        }}
      >
        {modalContent[view].body}
        {/** renders the json editor conditionally */}
        <div
          className={
            modalContent[view].showReactJsonView
              ? "workflow-builder-wizard-modal-jsoneditor-react-container-visible"
              : "workflow-builder-wizard-modal-jsoneditor-react-container-hidden"
          }
          ref={jsonEditorRef}
        >
          <ReactJSONEditor
            jsonEditorRef={jsonEditorRef}
            workflow={_selectedWorkflow}
          />
        </div>
      </div>
    </Modal>
  );
}
