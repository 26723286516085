import React, { useContext } from "react";
import ReactJSONEditor from "../jsoneditor";
import WorkflowStepsGraph from "../graph";
import ExecutionGraphStyles from "../graph/index.module.css";
import { Graph, GraphContext } from "../graph/context";
import { Button } from "@cimpress/react-components";
import "./index.css";
import PropTypes from "prop-types";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import {
  TempWorkflowContext,
  TempWorkflowProvider
} from "../../../../common/hooks/useTempWorkflow";

function Toolbar() {
  const graph: Graph = useContext(GraphContext);
  const {
    node: [, setSelectedNode]
  } = useContext(GraphContext);

  return (
    <div
      style={{
        background: "#0088a9"
      }}
    >
      <Button
        type="primary"
        size="sm"
        onClick={() => graph.dispatch({ type: "refresh" })}
      >
        Refresh Graph
      </Button>
      <Button
        style={{ marginLeft: "1em" }}
        type="primary"
        size="sm"
        onClick={() => setSelectedNode(null)}
      >
        Show All Steps
      </Button>
    </div>
  );
}

Toolbar.propTypes = {
  setStep: PropTypes.func
};

function WorkflowEditor() {
  const { state } = useContext(TempWorkflowContext) as TempWorkflowProvider;

  return (
    <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
      {/** toolbar */}
      <Toolbar />
      {/** editor and graph */}
      <div className="workflow-editor-container ">
        <div style={{ flexBasis: "100%" }}>
          <ReactJSONEditor />
        </div>
        <div
          className={ExecutionGraphStyles.canvasBackground}
          style={{ flexBasis: "100%" }}
        >
          {state.isValidating ? (
            <div className="graph-container-spinner">
              <Spinner />
            </div>
          ) : state.validationError ? (
            <pre className="error-view">
              {JSON.stringify(state.validationError, null, 2)}
            </pre>
          ) : (
            <WorkflowStepsGraph />
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkflowEditor;
