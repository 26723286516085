import { useReducer, useRef } from "react";
import createReducer from "../reducer";
import { handlers } from "../reducer/handlers";

interface ModalState {
  [name: string]: {
    visible: boolean;
  };
}

export interface ModalStore {
  registerModal: (modal: string) => void;
  deRegisterModal: (modal: string) => void;
  toggleVisibility: (modal: string) => void;
  isVisible: (modal: string) => boolean;
}

/**
 * Custom hook for managing multiple modals.
 */
export default function useModals() {
  const initialState: ModalState = {};
  const reducer = createReducer(initialState, handlers);
  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Need to capture latest state in a ref to prevent reading stale state due to closures
   */
  const _state = useRef(null);
  _state.current = state;

  /**
   * sets the visibily of the modal
   * @param modal name
   * @param visible true to display modal, false to hide modal
   */
  const toggleVisibility = (modal: string) => {
    if (!_state.current[modal]) {
      throw new Error(
        `Unable to add toggle visibility for ${modal} because it doesn't exist in the modal store`
      );
    }

    dispatch({
      type: "updateProp",
      path: `${modal}.visible`,
      value: !_state.current[modal].visible
    });
  };

  /**
   * register modal to the provider store
   * @param modal name
   */
  const registerModal = (modal: string) => {
    if (_state.current[modal]) {
      throw new Error(
        `Unable to add modal ${modal} because it already exist in the modal store`
      );
    }

    const value = {
      visible: false
    };
    dispatch({
      type: "updateProp",
      path: `${modal}`,
      value
    });
  };

  /**
   * deregister modal from the provider store
   * @param modal name
   */
  const deRegisterModal = (modal: string) => {
    if (!_state.current[modal]) {
      throw new Error(
        `Unable to deregister modal ${modal} because it doesn't exist in the modal store`
      );
    }

    dispatch({
      type: "deleteProp",
      path: `${modal}`
    });
  };

  const isVisible = (modal: string) => {
    return _state.current[modal]?.visible || false;
  };

  const modalStore: ModalStore = {
    registerModal,
    deRegisterModal,
    toggleVisibility,
    isVisible
  };

  return modalStore;
}
