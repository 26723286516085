import React, { useContext, useState, useEffect } from "react";
import { Accordion, FlexBox } from "@cimpress/react-components";
import Metadata from "../../common/components/metadata";
import TabCollection from "../../common/components/tabCollection";
import ReactJson from "react-json-view";
import _ from "lodash";
import styles from "./index.module.css";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumb, {
  Props as BreadcrumbProps
} from "../../common/components/breadcrumb";
import { AppContext, AppContextValue } from "../../common/context";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import {
  getHomeBreadcrumb,
  getWorkflowIdBreadcrumb,
  getExecutionIdBreadcrumb,
  getStepIdBreadcrumb,
  getEventIdFromStepBreadcrumb,
  getEventIdFromExecutionBreadcrumb
} from "../../common/utility";
import FourOhFourNotFound from "../fourOhFourNotFoundPage";
import {
  FORBIDDEN_MESSAGE,
  NOT_FOUND_MESSAGE,
  FORBIDDEN_CODE
} from "../constants";

/* eslint-disable */
const colors = require("@cimpress/react-components/lib/colors");

/**
 * page header
 * @param label Label
 */
function Header({ label }) {
  return (
    <div
      style={{
        borderBottom: `1px solid ${colors.shale}`,
        paddingBottom: "0.3125em",
        marginBottom: "1em",
        fontSize: "1.625em"
      }}
    >
      {label}
    </div>
  );
}

/**
 * vertical divider element
 */
function VerticalLine() {
  return (
    <div
      style={{
        borderLeft: `1px solid ${colors.shale}`,
        borderRight: `1px solid ${colors.alloy}`
      }}
    ></div>
  );
}

/**
 * event detail page
 */
function EventDetail() {
  let breadcrumbItems;
  let workflowFromState = null;
  // check if the parameters are passed as state from the previous page
  const location = useLocation();
  if (location.state) {
    workflowFromState = _.get(location.state, "workflow", null);
    breadcrumbItems = _.get(location.state, "breadcrumbItems");
  }

  let pathname = location.pathname;
  const { workflowId, executionId, eventId } = useParams();
  const { maestroClient, sandboxClient } = useContext(
    AppContext
  ) as AppContextValue;
  const [isFetching, setIsFetching] = useState(false);
  const [event, setEvent] = useState(null);
  const [workflow, setWorkflow] = useState(workflowFromState);
  const [isForbidden, setIsForbidden] = useState(false);
  const [isEventAvailable, setIsEventAvailable] = useState(true);
  const breadcrumbs: BreadcrumbProps = {
    items: []
  };
  const client =
    workflowId && workflowId != "anonymous" ? maestroClient : sandboxClient;
  /**
   * fetch the workflow from the reporting api if not passed as state in the location object
   */
  useEffect(() => {
    if (_.isNil(workflow) && _.isNil(workflowFromState)) {
      maestroClient
        .getWorkflow(workflowId)
        .then(workflowData => {
          setWorkflow(workflowData);
        })
        .catch(e => console.error(e));
    }
  }, []);
  /**
   * Fetch the full event
   */
  useEffect(() => {
    setIsFetching(true);
    client
      .getEventByExecutionId(executionId, eventId)
      .then(eventData => {
        setEvent(eventData);
      })
      .catch(e => {
        console.error(e);
        if (e.response.status == FORBIDDEN_CODE) {
          setIsForbidden(true);
        } else if (e.response.status == 404) {
          setIsEventAvailable(false);
        }
      })
      .finally(() => setIsFetching(false));
  }, []);

  const step = _.get(event, "step", {});
  const stepId = _.get(event, "stepId", "?");

  let breadcrumbsForEvents;

  if (pathname.includes("steps")) {
    breadcrumbsForEvents = [
      getStepIdBreadcrumb(workflowId, executionId, stepId),
      getEventIdFromStepBreadcrumb(workflowId, executionId, stepId, eventId)
    ];
  } else {
    breadcrumbsForEvents = [
      getEventIdFromExecutionBreadcrumb(workflowId, executionId, eventId)
    ];
  }

  // populate breadcrumb item collection
  if (breadcrumbItems) {
    breadcrumbs.items = _.concat(breadcrumbItems, [
      {
        path: `${
          breadcrumbItems[breadcrumbItems.length - 1].path
        }/events/${eventId}`,
        name: `${eventId}`
      }
    ]);
  } else {
    // default breadcrumb item collection
    breadcrumbs.items = _.concat(
      [
        getHomeBreadcrumb(),
        getWorkflowIdBreadcrumb(workflowId, _.get(workflow, "name", "?")),
        getExecutionIdBreadcrumb(workflowId, executionId)
      ],
      breadcrumbsForEvents
    );
  }

  const metadata = {
    Type: _.get(event, "eventType", null),
    "Step Name": _.get(step, "name", null),
    "Process On": _.get(event, "processOn", null),
    "Created At": _.get(event, "createdAt", null),
    "Modified At": _.get(event, "modifiedAt", null)
  };

  const stepInput = _.get(step, "input", {});
  const stepOutput = _.get(step, "output", {});
  const stepInputTransform = _.get(step, "inputTransform", {});
  const stepOutputTransform = _.get(step, "outputTransform", {});

  const tabCollectionHeaderData = [
    {
      header: "Input",
      value: stepInput
    },
    {
      header: "Output",
      value: stepOutput
    },
    {
      header: "Input Transform",
      value: stepInputTransform
    },
    {
      header: "Output Transform",
      value: stepOutputTransform
    }
  ];

  if (isForbidden) {
    return <FourOhFourNotFound message={FORBIDDEN_MESSAGE} />;
  } else if (!isEventAvailable) {
    return <FourOhFourNotFound message={NOT_FOUND_MESSAGE} />;
  } else {
    return (
      /** vertical layout */
      <FlexBox isVertical>
        {/** Breadcrumb */}
        <Breadcrumb {...breadcrumbs} />
        {/** header */}
        <Header label="Event" />
        {/** body */}
        <FlexBox>
          <div className={styles.metadataContainer}>
            {isFetching ? (
              <Spinner size="large" duration={3} />
            ) : (
              <Metadata
                title="Detail"
                data={metadata}
                headerStyle={{
                  fontSize: "1.375em",
                  marginTop: "0.3125em"
                }}
              />
            )}
          </div>
          <VerticalLine />
          <div className={styles.inputOutputContainer}>
            <TabCollection data={tabCollectionHeaderData} />
          </div>
        </FlexBox>
        {/** raw JSON accordion */}
        <div style={{ marginTop: "1.875em" }}>
          <Accordion
            style={{ marginBottom: "0em" }}
            title={<span>Raw JSON</span>}
            bodyStyle={{ overflow: "auto", height: "45vh" }}
          >
            <ReactJson
              src={event}
              name={false}
              displayDataTypes={false}
              collapsed={1}
            />
          </Accordion>
        </div>
      </FlexBox>
    );
  }
}

export default EventDetail;
