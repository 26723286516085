import React from "react";
interface AutoCompleteTextInputProps {
  suggestions: Array<string>;
  placeHolder?: string;
  onChange?: (input: string) => void;
}
/**
 * Render Autocomplete text input field
 * @param props
 */
export default function AutoCompleteTextInput(
  props: AutoCompleteTextInputProps
) {
  return (
    <div>
      <input
        placeholder={props.placeHolder}
        list="autocompletelist"
        onChange={event => props?.onChange(event.target.value)}
      />
      <datalist id="autocompletelist">
        {props.suggestions.map(suggestion => (
          <option value={suggestion} key={suggestion} />
        ))}
      </datalist>
    </div>
  );
}
