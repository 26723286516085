import createEngine, {
  DefaultNodeModel,
  NodeModel,
  DiagramModel
} from "@projectstorm/react-diagrams";

import { CanvasWidget, BaseEntityEvent } from "@projectstorm/react-canvas-core";

import React, { useEffect, useContext } from "react";
import _ from "lodash";
import styles from "./index.module.css";
import { Graph, GraphContext } from "./context";
import { TempWorkflowContext } from "../../../../common/hooks/useTempWorkflow";

// todo: refactor code (taken from workflow v2 ui project)
function createDiagramElements(steps) {
  const nodes = Object.entries(steps).map(
    ([stepName, step]: [string, object]) => {
      const type = _.get(step, "type", null);
      const node = new DefaultNodeModel(stepName, "rgb(0,136,169,0.75)");
      switch (type) {
        case "Success":
        case "Fail":
          node.addInPort("In");
          break;
        default:
          node.addInPort("In");
          node.addOutPort("Next");
          break;
      }
      return node;
    }
  );

  const links = Object.entries(steps).flatMap(
    ([stepName, step]: [string, object]) => {
      const getNodeByName = name =>
        nodes.find(elem => elem.getOptions().name === name);

      const node = getNodeByName(stepName);

      const getTargetNodeNames = step => {
        if (step.type === "Conditional") {
          return step.choices.map(choice => choice.next);
        } else {
          if (step.next) {
            return [step.next];
          }
        }
      };

      const createLinks = (originNode, targets) =>
        targets &&
        targets.map(targetNode =>
          originNode
            .getOutPorts()[0]
            .link(getNodeByName(targetNode).getInPorts()[0])
        );

      // Create links for all nodes
      const targetNodes = getTargetNodeNames(step);
      return createLinks(node, targetNodes);
    }
  );

  return {
    nodes,
    links
  };
}

// create the engine once
const engine = createEngine();
engine.setModel(new DiagramModel());

/**
 * interactable graph of the workflow steps
 */
function WorkflowStepsGraph() {
  const { tempWorkflow } = useContext(TempWorkflowContext).state;

  const graph: Graph = useContext(GraphContext);
  const {
    node: [, setSelectedNode]
  } = graph;

  /**
   * selection changed event listener
   */
  const nodeListener = {
    // there are some side effects that we might want to look into in the future
    // when selection changed from one node to another due to react batching updates
    //
    // when selection changes from one node to another, e.g A -> B, two events are fired
    // first event says node A is unselected
    // second event says node B is selected
    //
    // this means that we call setSelectedNode twice. Due to react batching, only the last event
    // gets processed
    selectionChanged: (
      event: BaseEntityEvent<NodeModel> & {
        isSelected: boolean;
      }
    ) => {
      if (event.entity.isSelected()) {
        setSelectedNode(event.entity);
      } else {
        setSelectedNode(null);
      }
    }
  };

  /**
   * Refreshes the graph model
   */
  useEffect(() => {
    // creates a new graph model
    graph.dispatch({ type: "reset", listener: nodeListener });

    // create the nodes and links to add to the graph model
    const { nodes, links } = createDiagramElements(
      _.get(tempWorkflow, "steps", null)
    );

    graph.dispatch({
      type: "addNodes",
      payload: nodes,
      listener: nodeListener
    });
    graph.dispatch({ type: "addLinks", payload: links });

    // updat the positions the nodes and links
    graph.dispatch({ type: "distribute" });
  }, [graph.refresh]);

  /**
   * Sets the graph model for the engine to render
   */
  useEffect(() => {
    engine?.setModel(graph.model);
  }, [graph.model]);

  /**
   * Clean up on component unmount
   */
  useEffect(() => {
    return () => graph.dispatch({ type: "reset", listener: nodeListener });
  }, []);

  /**
   * render canvas widget
   */
  return <CanvasWidget className={styles.canvas} engine={engine} />;
}
export default WorkflowStepsGraph;
