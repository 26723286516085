import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Shell from "./shell";
import auth from "./auth";
import {
  AppContext,
  AppContextValue,
  TimeZoneContext
} from "../common/context";
import { CssLoader } from "@cimpress/react-components";
import config from "../config";
import Routes from "./routes";
import TestRoutes from "./testRoutes";
import MaestroAPI from "../common/api";
import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import localforage from "localforage";
import { getPathFromPublic, LOCAL_TIMEZONE } from "../common/utility";
import FourOhFourNotFound from "../pages/fourOhFourNotFoundPage";
const batchRetryWorkerPath = getPathFromPublic("workers/batchRetryWorker.js");
const batchRetryWorker = new Worker(batchRetryWorkerPath);
// Login in again when token expires
auth.on("tokenExpired", () =>
  auth.login({ nextUri: window.location.pathname, forceLogin: true })
);
/**
 * Renders the base application component
 * @param props
 */
export default function App() {
  const [authenticating, setAuthenticating] = useState(true);
  const [authenticationError, setAuthenticationError] = useState(null);
  const [timeZone, setTimeZone] = useState(LOCAL_TIMEZONE);

  /**
   * Attempt to login on render
   */
  useEffect(() => {
    auth
      .ensureAuthentication({
        nextUri: window.location.pathname + window.location.search
      })
      .catch(e => {
        setAuthenticationError(e);
      })
      .finally(() => setAuthenticating(false));
  }, [auth.isLoggedIn()]);

  const maestroClient = new MaestroAPI(
    auth?.getAccessToken(),
    config.api.coreBaseUrl,
    config.api.reportingBaseUrl
  );
  const sandboxClient = new MaestroAPI(
    auth?.getAccessToken(),
    config.sandboxApi.coreBaseUrl,
    config.sandboxApi.reportingBaseUrl
  );

  const workflowTagsStorage = localforage.createInstance({
    driver: config.localForage.driver,
    name: config.localForage.databases.workflowTags.getName(
      auth?.getProfile()?.["https://claims.cimpress.io/canonical_id"]
    )
  });

  const anonymousWorkflowStorage = localforage.createInstance({
    driver: config.localForage.driver,
    name: "Anonymous-Workflows"
  });

  if (authenticationError) {
    return (
      <div>Unexpected error encountered. {authenticationError.message}</div>
    );
  } else if (authenticating) {
    return <Spinner fullPage={true} />;
  } else {
    return (
      <div>
        <CssLoader>
          <AppContext.Provider
            value={
              {
                auth,
                maestroClient,
                sandboxClient,
                workflowTagsStorage,
                batchRetryWorker,
                anonymousWorkflowStorage
              } as AppContextValue
            }
          >
            <TimeZoneContext.Provider value={{ timeZone, setTimeZone }}>
              <Router>
                <Shell>
                  <Switch>
                    {Routes.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          exact={route.exact}
                          path={route.path}
                        >
                          {route.children}
                        </Route>
                      );
                    })}
                    {config.stage !== "production" &&
                      TestRoutes.map((route, index) => {
                        return (
                          <Route
                            key={index}
                            exact={route.exact}
                            path={route.path}
                          >
                            {route.children}
                          </Route>
                        );
                      })}
                    <Route path="*" component={FourOhFourNotFound} />
                  </Switch>
                </Shell>
              </Router>
            </TimeZoneContext.Provider>
          </AppContext.Provider>
        </CssLoader>
      </div>
    );
  }
}
