import React, { useState } from "react";
import { NavTab, NavTabItem } from "@cimpress/react-components";
import PropTypes from "prop-types";
import ReactJson from "react-json-view";

//type for TabCollection props
interface TabCollectionProps {
  data: Array<{ header: string; value: any }>;
  bodyStyle?: object;
}

/**
 * Internal component for displaying the body of a tab in the TabCard component
 * @param src json object
 * @param name name of the json root node
 * @param style fixed width and height for card body
 */
function TabBody({ src, name, style }) {
  return (
    <div style={{ overflow: "auto", marginLeft: "1.875em", ...style }}>
      <ReactJson src={src} name={name} displayDataTypes={false} collapsed={1} />
    </div>
  );
}

/**
 * Common component for displaying the input, output, inputTransform, and outputTransform of an execution, step, or event.
 * @param data - array of json object having the tab header and value
 * @param bodyStyle TabBody dimension
 */
export default function TabCollection({
  data,
  bodyStyle = { height: "18.75em", width: "inherit" }
}: TabCollectionProps) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = data.map((entry, index) => {
    return {
      id: index,
      name: entry.header,
      block: <TabBody src={entry.value} name={null} style={bodyStyle} />
    };
  });

  return (
    <div>
      <NavTab>
        {tabs.map(tab => {
          return (
            <NavTabItem key={tab.id} active={selectedTabIndex == tab.id}>
              <button
                style={{ fontSize: "large" }}
                onClick={() => setSelectedTabIndex(tab.id)}
              >
                {tab.name}
              </button>
            </NavTabItem>
          );
        })}
      </NavTab>
      <div>{tabs[selectedTabIndex].block}</div>
    </div>
  );
}

TabBody.propTypes = {
  src: PropTypes.any,
  name: PropTypes.string,
  style: PropTypes.object
};
