/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from "immer";
import { Action } from "./actions";
import { Handlers } from "./handlers";

export interface State {
  [property: string]: any;
}

/**
 * Higher order function for simplifying the creation of reducers. The function
 * takes an initial state and a handlers object. We use immer to easily produce a new, immutable state
 * by making updates to the draft object.
 *
 * @param initialState
 * @param handlers object
 */
export default function createReducer<S extends State, A extends Action>(
  initialState: S,
  handlers: Handlers<S, A>
) {
  return function reducer(state: S = initialState, action: A): S {
    return produce(state, draft => {
      if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
        // call the handler function, passing in the draft object, the action, and the current state.
        // immer.produce will create the next state based on changes to the draft object
        // changes to the state object does nothing.
        handlers[action.type](draft, action, state);
      }
    });
  };
}
