import React from "react";

interface NoDataComponentInterface {
  loading: boolean;
}
/**
 * Component to be displayed when there is no data for table
 * @param props - props which will contain props like loading, etc
 */
export default function NoDataComponent(props: NoDataComponentInterface) {
  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "75px"
      }}
    >
      {props.loading ? <></> : <h4>No data available</h4>}
    </div>
  );
}
