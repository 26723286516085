import React, { useState, useEffect } from "react";
import { FlexBox, Button } from "@cimpress/react-components";
import Modal from "../../../../common/components/modal";
import { ModalStore } from "../../../../common/hooks/useModals";
import AutoCompleteText from "../../autocompleteTextInput";
import { Storage, StorageEvent } from "../../../hooks/useLocalStorage";
import { WorkflowTagEntry } from "../../../utility";
export const TAG_WORKFLOW_MODAL_ID = "tagWorkflowModal";

interface TagWorkflowModalProps {
  store: ModalStore;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  workflow: any;
  storageHandler: Storage;
  storageState: StorageEvent;
}

/**
 * Renders the tag workflow modal component
 * @param store - store where the modals are registered
 * @param workflow - workflow to be tagged
 */
export default function TagWorkflowModal(props: TagWorkflowModalProps) {
  const [tag, setTag] = useState(null);
  const { workflow, store } = props;
  const [tags, setTags] = useState([]);
  const { storageHandler, storageState } = props;

  /**
   * Fetch current tags for autocomplete selection
   */
  useEffect(() => {
    storageHandler
      .keys()
      .then(tags => setTags(tags))
      .catch(console.error);
  }, [storageState]);

  /**
   * Handler for when tag button is pressed
   */
  async function onTag() {
    const tagEntry: WorkflowTagEntry = {
      workflowName: workflow.name,
      workflowId: workflow.workflowId
    };
    try {
      const storedTag = await storageHandler.getItem(tag);
      if (storedTag.value !== null) {
        await storageHandler.pushItem(tag, tagEntry);
      } else {
        await storageHandler.setItem(tag, [tagEntry]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      store.toggleVisibility(TAG_WORKFLOW_MODAL_ID);
    }
  }

  return (
    <Modal
      store={store}
      id={TAG_WORKFLOW_MODAL_ID}
      options={{
        bsStyle: "info",
        closeOnOutsideClick: true,
        closeButton: true,
        title: "Tag Workflow"
      }}
    >
      <FlexBox middle marginX="l">
        <label style={{ fontSize: "17px" }}>Tag</label>
        <AutoCompleteText
          suggestions={tags}
          onChange={setTag}
          placeHolder="Type or select tag"
        />
        <div style={{ marginLeft: "20%" }}>
          <Button type="primary" disabled={tag ? false : true} onClick={onTag}>
            Tag
          </Button>
        </div>
      </FlexBox>
      <h3>View and manage tags in workflow tags menu</h3>
    </Modal>
  );
}
