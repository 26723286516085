/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  NavTab,
  NavTabItem,
  FlexBox,
  Table,
  Alert,
  Button
} from "@cimpress/react-components";
import Metadata from "../../common/components/metadata";
import TabCollection from "../../common/components/tabCollection";
import ReactJson from "react-json-view";
import _ from "lodash";
import styles from "./index.module.css";
import Breadcrumb, {
  Props as BreadcrumbProps
} from "../../common/components/breadcrumb";

import Spinner from "@cimpress/react-components/lib/shapes/Spinner";
import {
  AppContext,
  AppContextValue,
  TimeZoneContext
} from "../../common/context";
import useInterval from "../../common/hooks/useInterval";
import config from "../../config";
import {
  HeaderActionResult,
  convertDateRangeToTimeStamp,
  NULLVALUE,
  getHomeBreadcrumb,
  getWorkflowIdBreadcrumb,
  getExecutionIdBreadcrumb,
  ActivityStatuses
} from "../../common/utility";
import DateRangeFilterComponent from "../../common/components/dateRangeFilter";
import StatusFilter from "../../common/components/statusFilter";
import { StepIdCell, StatusCell } from "../../common/components/table/cells";
import useTimeFilter from "../../common/hooks/useTimeFilter";
import useStatusFilter from "../../common/hooks/useStatusFilter";
import TimeStamp from "../../common/components/timeStamp";
import { FORBIDDEN_MESSAGE, FORBIDDEN_CODE } from "../constants";
import FourOhFourNotFound from "../fourOhFourNotFoundPage";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("@cimpress/react-components/lib/colors");

/**
 * Cell render component for the eventId column
 * @param param0 contains the eventId and other details
 */
function EventIdCell({ cellProps, breadcrumbItems }) {
  const eventId = cellProps.original.eventId;
  const location = useLocation();
  return (
    <Link
      to={{
        pathname: `${location.pathname}/events/${eventId}`,
        state: { breadcrumbItems }
      }}
    >
      {eventId}
    </Link>
  );
}

interface HeaderProps {
  label: string;
  onTerminate: Function;
  onContinue: Function;
  disableTerminate: boolean;
  disableContinue: boolean;
}

/**
 * page header
 * @param props
 */
function Header(props: HeaderProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        borderBottom: `1px solid ${colors.shale}`,
        paddingBottom: "0.3125em",
        marginBottom: "1em",
        fontSize: "1.625em"
      }}
    >
      <div style={{ flex: 1 }}>{props.label}</div>
      <Button
        style={{ marginLeft: "5px" }}
        disabled={props.disableTerminate}
        onClick={() => {
          props.onTerminate();
        }}
        type="default"
      >
        Terminate
      </Button>
    </div>
  );
}

/**
 * vertical divider element
 */
function VerticalLine() {
  return (
    <div
      style={{
        borderLeft: `1px solid ${colors.shale}`,
        borderRight: `1px solid ${colors.alloy}`
      }}
    ></div>
  );
}
/**
 * execution detail page
 */
function ExecutionDetail() {
  const { executionId, workflowId } = useParams();
  const { maestroClient, sandboxClient } = useContext(
    AppContext
  ) as AppContextValue;
  const [
    timeFilterState,
    setStartDate,
    setEndDate,
    setTimeFilterMode
  ] = useTimeFilter();

  const breadcrumbs: BreadcrumbProps = {
    items: []
  };

  const client =
    workflowId && workflowId != "anonymous" ? maestroClient : sandboxClient;

  let breadcrumbItems, executionFromState;

  // check if the parameters are passed as state from the previous page
  const location = useLocation();
  if (location.state) {
    breadcrumbItems = _.get(location.state, "breadcrumbItems");
    executionFromState = _.get(location.state, "execution");
  }
  const [execution, setExecution] = useState(executionFromState);
  const { timeZone } = useContext(TimeZoneContext);

  // populate breadcrumb item collection
  if (breadcrumbItems) {
    breadcrumbs.items = _.concat(breadcrumbItems, [
      getExecutionIdBreadcrumb(workflowId, _.get(execution, "executionId", "?"))
    ]);
  } else {
    // default breadcrumb item collection
    breadcrumbs.items = [
      getHomeBreadcrumb(),
      getWorkflowIdBreadcrumb(
        workflowId,
        _.get(execution, "workflow.name", "?")
      ),
      getExecutionIdBreadcrumb(workflowId, executionId)
    ];
  }

  const [isFetchingExecution, setIsFetchingExecution] = useState(true);
  const [isFetchingSteps, setIsFetchingSteps] = useState(true);
  const [isFetchingEvents, setIsFetchingEvents] = useState(true);
  const [pausePolling, setPausePolling] = useState(false);
  const [events, setEvents] = useState([]);
  const [steps, setSteps] = useState([]);
  const [headerActionResult, setHeaderActionResult]: [
    HeaderActionResult,
    Function
  ] = useState(null);
  const [isForbidden, setIsForbidden] = useState(false);
  const {
    startDate: startDateForSteps,
    endDate: endDateForSteps
  } = timeFilterState;
  const [statusFilterState, setStatus] = useStatusFilter();
  const { status } = statusFilterState;

  /**
   * Apply date range filter and status filter
   */
  useEffect(() => {
    setIsFetchingSteps(true);
    const { beginTimeStamp, endTimeStamp } = convertDateRangeToTimeStamp(
      startDateForSteps,
      endDateForSteps,
      timeZone
    );
    client
      .getActivitiesByExecutionId(
        executionId,
        NULLVALUE,
        NULLVALUE,
        status ? status : NULLVALUE,
        beginTimeStamp,
        endTimeStamp
      )
      .then(response => {
        setSteps(response.activities);
      })
      .catch(console.error)
      .finally(() => {
        setIsFetchingSteps(false);
      });
  }, [startDateForSteps, endDateForSteps, status, timeZone]);

  const stepTableColumns = [
    {
      Header: "ID",
      accessor: "stepId",
      Cell: props => (
        <StepIdCell
          cellProps={props}
          execution={execution}
          breadcrumbItems={breadcrumbs.items}
          pathName={location.pathname}
        />
      )
    },
    { Header: "Name", accessor: "name" },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: props => <TimeStamp date={props.original.createdAt} />,
      filterable: false
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: props => <StatusCell original={props.original} />,
      Filter: () => (
        <StatusFilter
          statuses={ActivityStatuses}
          statusFilterState={statusFilterState}
          setStatus={setStatus}
        />
      )
    }
  ];

  const eventTableColumns = [
    {
      Header: "Event ID",
      accessor: "eventId",
      Cell: props => (
        <EventIdCell cellProps={props} breadcrumbItems={breadcrumbs.items} />
      )
    },
    { Header: "Step ID", accessor: "stepId" },
    { Header: "Event Type", accessor: "eventType" },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: props => <TimeStamp date={props.original.createdAt} />,
      filterable: false
    },
    {
      Header: "Process Date",
      accessor: "processOn",
      Cell: props => <TimeStamp date={props.original.processOn} />,
      filterable: false
    }
  ];

  /**
   * function to fetch execution
   */
  function fetchExecution() {
    client
      .getExecution(executionId)
      .then(executionData => {
        setExecution(executionData);
      })
      .catch(e => {
        console.error(e);
        if (e.response.status == FORBIDDEN_CODE) {
          setIsForbidden(true);
        }
      })
      .finally(() => setIsFetchingExecution(false));
  }
  useInterval(fetchExecution, pausePolling ? null : config.api.pollIntervalMs);

  /**
   * function to fetch steps
   */
  function fetchSteps() {
    client
      .getActivitiesByExecutionId(executionId)
      .then(stepsData => {
        setSteps(stepsData.activities);
      })
      .catch(e => console.error(e))
      .finally(() => setIsFetchingSteps(false));
  }
  useInterval(fetchSteps, pausePolling ? null : config.api.pollIntervalMs);

  /**
   * function to fetch events
   */
  function fetchEvents() {
    client
      .getEventsByExecutionId(executionId)
      .then(eventsData => {
        setEvents(eventsData);
      })
      .catch(e => console.error(e))
      .finally(() => setIsFetchingEvents(false));
  }
  useInterval(fetchEvents, pausePolling ? null : config.api.pollIntervalMs);

  useEffect(() => {
    if (_.isNil(execution)) {
      fetchExecution();
    }
    fetchSteps();
    fetchEvents();
  }, []);

  //if status is not running, the execution is done. then stop polling
  useEffect(() => {
    if (execution?.status !== "Running") {
      setPausePolling(true);
    } else {
      setPausePolling(false);
    }
  }, [execution?.status]);

  /**
   * Handler when terminate button is pressed
   */
  async function onClickTerminate() {
    try {
      setHeaderActionResult(
        new HeaderActionResult(true, "Terminate Execution request submitted")
      );
      await client.terminateExecution(executionId);
      setPausePolling(false);
      fetchExecution();
      fetchSteps();
      fetchEvents();
    } catch (e) {
      setHeaderActionResult(
        new HeaderActionResult(
          false,
          JSON.stringify({
            response: e.response?.data,
            status: e.response?.status
          }),
          "Terminate Step request Failed"
        )
      );
      console.error(e.response?.data);
    }
  }

  /**
   * Handler when continue button is clicked
   */
  async function onClickContinue() {
    try {
      setHeaderActionResult(
        new HeaderActionResult(true, "Continue Execution request submitted")
      );
      await client.continueExecution(executionId);
      setHeaderActionResult(null);
    } catch (e) {
      setHeaderActionResult(
        new HeaderActionResult(
          false,
          JSON.stringify({
            response: e.response?.data,
            status: e.response?.status
          }),
          "Continue Step request Failed"
        )
      );
      console.error(e.response?.data);
    }
  }

  const metadata = {
    Status: _.get(execution, "status", null),
    "Workflow Version": _.get(execution, "workflow.version", null),
    "Created At": _.get(execution, "createdAt", null),
    "Modified At": _.get(execution, "modifiedAt", null),
    Attempts: 1,
    "Created By": _.get(execution, "createdBy", null)
  };

  const input = _.get(execution, "input", {});
  const output = _.get(execution, "output", {});
  const inputTransform = _.get(execution, "inputTransform", {});
  const outputTransform = _.get(execution, "outputTransform", {});

  const tabCollectionHeaderData = [
    {
      header: "Input",
      value: input
    },
    {
      header: "Output",
      value: output
    },
    {
      header: "Input Transform",
      value: inputTransform
    },
    {
      header: "Output Transform",
      value: outputTransform
    }
  ];

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const tabs = [
    {
      id: 0,
      name: "Steps",
      block: (
        <div>
          <DateRangeFilterComponent
            disableTimeToggle
            timeFilterState={timeFilterState}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setTimeFilterMode={setTimeFilterMode}
          />
          <Table
            columns={stepTableColumns}
            data={steps}
            filterable={true}
            loading={isFetchingSteps}
            sortable={false}
          />
        </div>
      )
    },
    {
      id: 1,
      name: "Events",
      block: (
        <Table
          columns={eventTableColumns}
          data={events}
          filterable={true}
          loading={isFetchingEvents}
        />
      )
    },
    {
      id: 2,
      name: "Raw Json",
      block: (
        <div style={{ overflow: "auto", height: "48vh" }}>
          <ReactJson
            src={execution}
            name={false}
            displayDataTypes={false}
            collapsed={1}
          />
        </div>
      )
    }
  ];
  if (isForbidden) {
    return <FourOhFourNotFound message={FORBIDDEN_MESSAGE} />;
  } else {
    return (
      /** vertical layout */
      <FlexBox isVertical>
        {/** Breadcrumb */}
        <Breadcrumb {...breadcrumbs} />
        {/** header */}
        <Header
          label="Execution"
          onTerminate={onClickTerminate}
          onContinue={onClickContinue}
          disableTerminate={
            execution?.status == "Completed" ||
            execution?.status == "Terminated"
          }
          disableContinue={execution?.status == "Completed"}
        />
        {/** body */}
        <Alert
          title={headerActionResult?.title}
          type={headerActionResult?.succeeded ? "info" : "danger"}
          message={headerActionResult?.message || _.stubString()}
          dismissible={true}
          dismissed={headerActionResult === null}
          onDismiss={() => {
            setHeaderActionResult(null);
          }}
        />
        {/** body */}
        <FlexBox>
          <div className={styles.metadataContainer}>
            {!isFetchingExecution ? (
              <Metadata
                title="Detail"
                data={metadata}
                headerStyle={{
                  fontSize: "1.375em",
                  marginTop: "0.3125em"
                }}
                bodyStyle={{ height: "20em", width: "100%" }}
              />
            ) : (
              <Spinner />
            )}
          </div>
          <VerticalLine />
          <div className={styles.inputOutputContainer}>
            <TabCollection data={tabCollectionHeaderData} />
          </div>
        </FlexBox>
        {/** tables are inside tabs */}
        <div style={{ marginTop: "2em" }}>
          <NavTab>
            {tabs.map(tab => {
              return (
                <NavTabItem key={tab.id} active={selectedTabIndex == tab.id}>
                  <button
                    style={{ fontSize: "large" }}
                    onClick={() => setSelectedTabIndex(tab.id)}
                  >
                    {tab.name}
                  </button>
                </NavTabItem>
              );
            })}
          </NavTab>
          <div>{tabs[selectedTabIndex].block}</div>
        </div>
      </FlexBox>
    );
  }
}

export default ExecutionDetail;
