import React from "react";
import Metadata from "../common/components/metadata";
import TabCollection from "../common/components/tabCollection";
import tabCollectionData from "../data/inputOutputTransform.json";
import DiffViewer from "../common/components/diffViewer";
import leftJson from "../data/diffTestLeftJson.json";
import rightJson from "../data/diffTestRightJson.json";
import ViewWorkflowData from "../pages/viewWorkflowData";

const TestRoutes = [
  {
    path: "/test/common/metadata",
    exact: false,
    children: (
      <div style={{ display: "flex", paddingLeft: "1.25em" }}>
        <Metadata
          title="Detail"
          data={{
            "Step Name": "TestStep",
            "Created At": "7/8/2020 16:30:00",
            "Modified At": "7/8/2020 16:30:00",
            "Process On": "7/8/2020 16:30:00",
            "Some Number": 2
          }}
          bodyStyle={{ height: "31.25em", marginTop: "1.25em" }}
        />
      </div>
    )
  },
  {
    path: "/test/common/inputoutputtransform",
    exact: false,
    children: (
      <div style={{ width: "50%" }}>
        <TabCollection
          data={[
            {
              header: "Input",
              value: tabCollectionData.input
            },
            {
              header: "Output",
              value: tabCollectionData.output
            },
            {
              header: "Input Transform",
              value: {}
            },
            {
              header: "Output Transform",
              value: {}
            }
          ]}
          bodyStyle={{ height: "31.25em", width: "inherit" }}
        />
      </div>
    )
  },
  {
    path: "/test/common/diff",
    exact: false,
    children: (
      <div className="container">
        <DiffViewer leftJson={leftJson} rightJson={rightJson} />
      </div>
    )
  },
  {
    path: "/test/page/viewWorkflowData",
    exact: false,
    children: (
      <div className="container">
        <ViewWorkflowData />
      </div>
    )
  }
];

export default TestRoutes;
