import React from "react";
import "./CardSelectHeader.css";
import { Dropdown } from "@cimpress/react-components";

interface SelectOption {
  value: string;
  label: string;
}

/**
 * CardSelectHeader component, utility function to create a header component for cards that contains a select component
 * @param options options for select component
 * @param onSelect function to call when selection is made
 * @param title title for the card header
 * @param defaultOptionIndex index of options array to use as default value
 */
export default function CardSelectHeader(
  title = null,
  selectedOption,
  setSelectedOption,
  dropDownItems
) {
  /**
   * This handles the click event of the time period Drop Down
   * @param element
   */
  const onDropdownSelect = element => {
    const timeFilterSelected = element.target.name;
    const selectedItemIndex = dropDownItems.findIndex(
      item => item.label === timeFilterSelected
    );
    setSelectedOption(dropDownItems[selectedItemIndex]);
  };

  return (
    <div className="parent">
      <h2 className="titleCol">{title}</h2>
      <Dropdown title={selectedOption.label}>
        {dropDownItems.map((item, index) => {
          return (
            <button
              onClick={onDropdownSelect}
              key={index}
              name={item.label}
              disabled={item.label === selectedOption.label ? true : false}
            >
              {item.label}
            </button>
          );
        })}
      </Dropdown>
    </div>
  );
}
