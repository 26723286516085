import React, { useEffect, ReactNode, CSSProperties, MouseEvent } from "react";
import { Modal as BaseModal } from "@cimpress/react-components";
import { ModalStore } from "../../hooks/useModals";

interface ModalProps {
  store: ModalStore;
  id: string;
  children?: ReactNode;
  options?: {
    /**
     * Render the modal with a bootstrap size, one of "sm" or "lg".
     */
    bsSize?: "sm" | "lg";
    /**
     * Render the modal with a bootstrap contextual style, one of one of "success" "warning" "danger" or "info".
     */
    bsStyle?: "danger" | "info" | "success" | "warning";
    /**
     * Class name to append to the body of the modal.
     */
    className?: string;
    /**
     * Controls whether an "x" appears in the header to close the modal.
     */
    closeButton?: boolean;
    /**
     * Calls onRequestHide when clicked outside of the main Modal div.
     */
    closeOnOutsideClick?: boolean;
    /**
     * Modal footer. Any buttons with actions should go here. If a footer is not provided, the modal-footer element will not render.
     */
    footer?: ReactNode;
    /**
     * Function called when the modal wants to close itself.
     */
    onRequestHide?: (event: MouseEvent) => void;
    /**
     * Inline styles for the body of the modal.
     */
    style?: CSSProperties;
    /**
     * Title for the modal. If no title is provided, the space will be left blank.
     */
    title?: ReactNode;
    /**
     * Inline styles for the modal wrapper node.
     */
    wrapperStyle?: CSSProperties;
    /**
     * Callback when modal is registered
     */
    onRegisterModal?: () => void;
  };
}

/**
 * Component which renders a modal managed by the modal store
 */
export default function Modal(props: ModalProps) {
  const store: ModalStore = props.store;
  const id: string = props.id;
  const options = props.options || {};

  useEffect(() => {
    Promise.resolve().then(() => {
      store.registerModal(id);
      props.options.onRegisterModal?.();
    });
    return () => store.deRegisterModal(id);
  }, []);

  return (
    <BaseModal
      bsSize={options.bsSize}
      bsStyle={options.bsStyle}
      className={options.className}
      closeButton={options.closeButton}
      closeOnOutsideClick={options.closeOnOutsideClick}
      footer={options.footer}
      show={store.isVisible(id)}
      style={options.style}
      onRequestHide={event => {
        store.toggleVisibility(id);
        options.onRequestHide?.(event);
      }}
      title={options.title}
      wrapperStyle={options.wrapperStyle}
    >
      {props.children}
    </BaseModal>
  );
}
