import React, { useEffect, useState, useContext } from "react";
import { AppContext, AppContextValue } from "../../common/context";
import PropTypes from "prop-types";

export const TaggedWorkflowDrawerContext = React.createContext(null);

export interface TaggedWorkflowDrawerProvider {
  workflows: Array<any>;
}
/**
 * Context provider for nav drawer
 */
export function TaggedWorkflowDrawerProvider(props) {
  const { maestroClient } = useContext(AppContext) as AppContextValue;
  const [workflows, setWorkflows] = useState(null);
  useEffect(() => {
    maestroClient
      .getWorkflows()
      .then(fetchedWorkflows => setWorkflows(fetchedWorkflows))
      .catch(console.error);
  }, []);
  return (
    <TaggedWorkflowDrawerContext.Provider
      value={{ workflows } as TaggedWorkflowDrawerProvider}
    >
      {props.children}
    </TaggedWorkflowDrawerContext.Provider>
  );
}

TaggedWorkflowDrawerProvider.propTypes = {
  children: PropTypes.node.isRequired
};
