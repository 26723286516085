import React, { useContext } from "react";
import { Drawer } from "@cimpress/react-components";
import { ModalStore } from "../../common/hooks/useModals";
import TaggedWorkflowMenu from "./taggedWorkflowMenu";
import {
  TaggedWorkflowDrawerContext,
  TaggedWorkflowDrawerProvider
} from "./context";
import styles from "./index.module.css";
interface NavDrawerProps {
  store: ModalStore;
}

export const TAGGED_WORKFLOW_DRAWER_MODAL_ID = "taggedWorkflowDrawerModalId";

/**
 * Renders drawer for tagged workflows menu
 * @param props.store modal store for controlling drawer visibility
 */
export default function TaggedWorkflowMenuDrawer(props: NavDrawerProps) {
  const { workflows } = useContext(
    TaggedWorkflowDrawerContext
  ) as TaggedWorkflowDrawerProvider;
  const { store } = props;
  return (
    <Drawer
      position="right"
      autoResize
      onRequestHide={() => {
        store.toggleVisibility(TAGGED_WORKFLOW_DRAWER_MODAL_ID);
      }}
      show={store.isVisible(TAGGED_WORKFLOW_DRAWER_MODAL_ID)}
      closeOnClickOutside={true}
      className={styles.navDrawer}
    >
      <TaggedWorkflowMenu
        workflows={workflows}
        isVisible={store.isVisible(TAGGED_WORKFLOW_DRAWER_MODAL_ID)}
        closeDrawer={() => {
          store.isVisible(TAGGED_WORKFLOW_DRAWER_MODAL_ID) &&
            store.toggleVisibility(TAGGED_WORKFLOW_DRAWER_MODAL_ID);
        }}
      />
    </Drawer>
  );
}
