import React from "react";
import { Breadcrumbs, BreadcrumbItem } from "@cimpress/react-components";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

interface BreadcrumbItemProps {
  path: string;
  name: string;
}

export interface Props {
  items: BreadcrumbItemProps[];
}
/**
 * breadCrumb component
 * @param props - contains the breadcrumb
 */
function Breadcrumb(props: Props) {
  const items = props.items;

  return (
    <div className={styles.breadcrumb}>
      <Breadcrumbs>
        {items.map((item, index) => {
          const active = index === items.length - 1;
          if (item) {
            return (
              <BreadcrumbItem key={index} active={active}>
                {index === items.length - 1 ? (
                  item.name
                ) : (
                  <Link to={item.path}>{item.name}</Link>
                )}
              </BreadcrumbItem>
            );
          }
        })}
      </Breadcrumbs>
    </div>
  );
}

export default Breadcrumb;
