import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { ManageWorkflowPageProvider } from "../manageWorkflow/context";
import ManageWorkflow from "../manageWorkflow";
import { useLocation } from "react-router-dom";

export interface WorkflowDraft {
  workflow: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export default function WorkflowBuilder() {
  const location = useLocation();
  const history = useHistory();
  const WorkFlowState = _.get(location.state, "WorkFlowState", null);
  const FileName = _.get(location.state, "FileName", null);
  const [shouldRedirect] = useState(WorkFlowState);
  if (!shouldRedirect) {
    history.push("/");
  }
  return (
    <ManageWorkflowPageProvider>
      <ManageWorkflow workflow={WorkFlowState} fileName={FileName} />
    </ManageWorkflowPageProvider>
  );
}
