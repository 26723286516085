import React, { useContext } from "react";
import { TimeZoneContext } from "../../context/index";

const timeZoneModes = {
  local: { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
  utc: { timeZone: "UTC" }
};

const timeZoneDefaults = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  timeZoneName: "short"
};

interface TimeStampProps {
  date: string;
}

/**
 * Renders the date in UTC/local format
 * @param props.date contains the date as a string
 */
export default function TimeStamp(props: TimeStampProps) {
  const date = new Date(props.date);
  const { timeZone } = useContext(TimeZoneContext);
  const options = timeZoneModes[timeZone];
  Object.assign(options, timeZoneDefaults);
  const lang =
    (navigator.languages ? navigator.languages[0] : navigator.language) ||
    "en-US";
  const formattedDate = new Intl.DateTimeFormat(lang, options).format(date);
  return <>{formattedDate}</>;
}
