import React, { useContext, useEffect, useState } from "react";
import { AppContext, AppContextValue } from "../common/context";
import Header from "@cimpress-technology/react-platform-header";
import config from "../config";
import useModals from "../common/hooks/useModals";
import TaggedWorkflowMenuDrawer, {
  TAGGED_WORKFLOW_DRAWER_MODAL_ID
} from "./taggedWorkflowMenuDrawer";
import { TaggedWorkflowDrawerProvider } from "./taggedWorkflowMenuDrawer/context";
import IconHouse from "@cimpress-technology/react-streamline-icons/lib/IconHouse";
import IconDesignToolPenStation from "@cimpress-technology/react-streamline-icons/lib/IconDesignToolPenStation";
import IconPin from "@cimpress-technology/react-streamline-icons/lib/IconPin";
import IconDrawerFile from "@cimpress-technology/react-streamline-icons/lib/IconDrawerFile";
import { useLocation, Link } from "react-router-dom";
import WorkflowBuilderWizard, {
  MODAL_NAME as WORKFLOW_WIZARD_MODEL
} from "../pages/workflowBuilder/components/wizard/index";
import { Dropdown } from "@cimpress/react-components";
import { TimeZoneContext } from "../common/context/index";
import { LOCAL_TIMEZONE, UTC_TIMEZONE } from "../common/utility";
import _ from "lodash";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const colors = require("@cimpress/react-components/lib/colors");

/**
 * component that renders the time range selector
 */
function TimezoneModeSelector() {
  const { timeZone, setTimeZone } = useContext(TimeZoneContext);
  const isLocal = timeZone === LOCAL_TIMEZONE;
  const localZoneDisplay = "Local";
  const UTCZoneDisplay = "UTC";
  const title = `Timezone: ${isLocal ? localZoneDisplay : UTCZoneDisplay}`;

  return (
    <div className="timestamp-mode-selector">
      <Dropdown title={title}>
        <button disabled={isLocal} onClick={() => setTimeZone(LOCAL_TIMEZONE)}>
          {localZoneDisplay}
        </button>
        <button
          disabled={!isLocal}
          onClick={() => (isLocal ? setTimeZone(UTC_TIMEZONE) : _.noop())}
        >
          {UTCZoneDisplay}
        </button>
      </Dropdown>
    </div>
  );
}
/**
 * Renders common page header component
 */
export default function PageHeader() {
  const appCtx: AppContextValue = useContext(AppContext);
  const modalStore = useModals();
  const location = useLocation();
  const [disableWorkflowBuilderLink, setDisableWorkflowBuilderLink] = useState(
    false
  );
  useEffect(() => {
    if (location.pathname == "/workflows/build") {
      setDisableWorkflowBuilderLink(true);
    } else {
      setDisableWorkflowBuilderLink(false);
    }
  }, [location.pathname]);

  const TimeZoneSelector = {
    id: "TIMEZONE_SELECTION",
    content: (
      <a style={{ paddingTop: "7px", paddingBottom: "7px" }}>
        <TimezoneModeSelector />
      </a>
    ),
    isActive: false
  };

  const AnonymousWorkflowPageLink = {
    id: "ANONYMOUS_WORKFLOW",
    content: (
      <a
        style={{ paddingTop: "7px", paddingBottom: "7px" }}
        href="/workflows/anonymous"
      >
        <IconDrawerFile size="2x" /> Anonymous Workflow
      </a>
    ),
    isActive: false
  };

  const TaggedWorkflowsLink = {
    id: "TAGGED_WORKFLOWS",
    type: "default",
    content: (
      <a
        style={{ paddingTop: "7px", paddingBottom: "7px" }}
        onClick={() =>
          modalStore.toggleVisibility(TAGGED_WORKFLOW_DRAWER_MODAL_ID)
        }
      >
        <IconPin size="2x" /> Workflow Tags
      </a>
    ),
    isActive: false
  };

  const homePageLink = {
    id: "WORKFLOWS_HOME",
    content: (
      <a style={{ paddingTop: "7px", paddingBottom: "7px" }} href="/">
        <IconHouse size="2x" /> Home
      </a>
    ),
    isActive: false
  };

  const workflowBuilderLink = {
    id: "WORKFLOW_BUILDER",
    content: (
      <a
        style={{
          paddingTop: "7px",
          paddingBottom: "7px",
          color: `${
            disableWorkflowBuilderLink ? colors.alloy : colors.ocean.base
          }`
        }}
        onClick={event => {
          disableWorkflowBuilderLink
            ? event.preventDefault()
            : modalStore.toggleVisibility(WORKFLOW_WIZARD_MODEL);
        }}
      >
        <IconDesignToolPenStation
          color={disableWorkflowBuilderLink ? colors.alloy : colors.ocean.base}
          size="2x"
          weight="light"
        />
        Builder
      </a>
    ),
    isActive: false
  };
  /**
   * Register Nav Drawer in model store
   */
  useEffect(() => {
    Promise.resolve().then(() => {
      modalStore.registerModal(TAGGED_WORKFLOW_DRAWER_MODAL_ID);
    });
    return () => modalStore.deRegisterModal(TAGGED_WORKFLOW_DRAWER_MODAL_ID);
  }, []);

  return (
    <div>
      <Header
        accessToken={appCtx.auth.getAccessToken()}
        appTitle={config.applicationName}
        appLinks={[
          TimeZoneSelector,
          AnonymousWorkflowPageLink,
          TaggedWorkflowsLink,
          workflowBuilderLink,
          homePageLink
        ]}
        documentationUrl={
          "https://cimpress-support.atlassian.net/wiki/spaces/GWM/pages/338791832/Workflow+Management+-+Introduction"
        }
        showLeftNav={false}
        profile={appCtx.auth.getProfile()}
        onLogInClicked={() => appCtx.auth.login()}
        onLogOutClicked={() => appCtx.auth.logout("/")}
        isLoggedIn={appCtx.auth.isLoggedIn()}
      />
      <TaggedWorkflowDrawerProvider>
        <TaggedWorkflowMenuDrawer store={modalStore} />
      </TaggedWorkflowDrawerProvider>
      <WorkflowBuilderWizard store={modalStore} />
    </div>
  );
}
